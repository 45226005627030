<nav class="subnav">
    <div class="nav">
        <strong>Login</strong>
    </div>
</nav>
<div class="content-container" id="login">
    <div class="content-area">
        <div class="login-container">
            <div class="clr-row" style="margin-bottom: 1rem;">
                <div class="clr-col-lg-3 clr-col-12"></div>
                <div class="clr-col-lg-6 clr-col-12">
                    <div class="card center">
                        <form clrForm [clrLabelSize]="4" #loginForm="ngForm" (ngSubmit)="login()">
                            <div class="card-header">
                                <div class="clr-row">
                                    <div class="clr-col-lg-6 clr-col-12">
                                        <h4><strong>Signin</strong></h4>
                                    </div>
                                    <ng-container *ngIf="sentOTP">
                                        <div class="clr-col-lg-6 clr-col-12" style="text-align: right;">
                                            <div id="timer">
                                                <span class="minutes"></span>
                                                <span>:</span>
                                                <span class="seconds"></span>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="card-block">
                                <div class="card-text">
                                    <clr-alert *ngIf="loginAlert.message" [clrAlertType]="loginAlert.type" [clrAlertClosable]="false">
                                        <clr-alert-item>
                                            <span class="alert-text">
                                                {{ loginAlert.message }}
                                            </span>
                                        </clr-alert-item>
                                    </clr-alert>
                                    <clr-input-container *ngIf="!sentOTP else emailText">
                                        <label>Email</label>
                                        <input [disabled]="sentOTP" clrInput required pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" type="email" name="email" placeholder="" [(ngModel)]="useremail" #email="ngModel" />
                                        <clr-control-error *ngIf="email.touched && email.errors?.required else emailInvalid">Please enter an email address</clr-control-error>
                                        <ng-template #emailInvalid>
                                            <clr-control-error>Please enter a valid email address</clr-control-error>
                                        </ng-template>
                                    </clr-input-container>
                                    <ng-template #emailText>
                                        <div class="clr-row">
                                            <div class="clr-col-lg-4 clr-col-12"><b>Email</b></div>
                                            <div class="clr-col-lg-6 clr-col-12">{{useremail}}</div>
                                            <div class="clr-col-lg-2 clr-col-12"></div>
                                        </div>
                                    </ng-template>
                                    <ng-container *ngIf="sentOTP">
                                        <clr-input-container>
                                            <label>One Time Passcode</label>
                                            <input clrInput required type="text" name="OTP" [(ngModel)]="otp" #OTP="ngModel" pattern="^(\d{6})" />
                                            <clr-control-error *ngIf="OTP.touched && OTP.errors?.required else otpInvalid">Please enter One Time Passcode</clr-control-error>
                                            <ng-template #otpInvalid>
                                                <clr-control-error>Please enter valid One Time Passcode</clr-control-error>
                                            </ng-template>
                                        </clr-input-container>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="card-footer">
                                <ng-container *ngIf="!sentOTP else loginButton">
                                    <button type="button" [disabled]="!loginForm.valid" class="btn btn-primary" (click)="sendOTP()">{{ SubmitTxt }}</button>
                                </ng-container>
                                <ng-template #loginButton>
                                    <button type="submit" [disabled]="!loginForm.valid || otpExpired" class="btn btn-primary">{{ SubmitTxt }}</button>
                                    <button type="button" class="btn btn-outline" (click)="refreshLogin()">Cancel</button>
                                </ng-template>
                                <ng-container *ngIf="sentOTP">
                                    <a *ngIf="!otpExpired" style="font-size: small;" href="javascript:void(0);" (click)="resendOTP()">Regenerate One Time Passcode</a>
                                    <a *ngIf="otpExpired" style="font-size: small;" class="disabled" href="javascript:void(0);">Regenerate One Time Passcode</a>
                                </ng-container>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="clr-col-lg-3 clr-col-12"></div>
            </div>
        </div>
    </div>
 </div>    
