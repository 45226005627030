<div class="filters-container">
    <form clrForm clrLayout="vertical" #filterForm="ngForm" (ngSubmit)="getResults(filterForm.value)">
        <div class="clr-row" style="float: right;">
            <div class="clr-col-sm-12 clr-col-md-12 clr-col-lg-12 clr-col-xl-12">
                <clr-toggle-wrapper>
                    <input type="checkbox" name="togleVnM" clrToggle [(ngModel)]="togleVendorsAndModels">
                    <label class="clr-control-label"
                        style="font-weight: 600;">{{togleVendorsAndModels ? 'Hide' : 'Show'}} filters</label>
                </clr-toggle-wrapper>
            </div>
        </div>
        <div class="clr-row" [ngClass]="{'display-none': togleVendorsAndModels !== true}"
            style="clear: both; margin-bottom: 1rem;">
            <div class="clr-col-sm-12 clr-col-md-6 clr-col-lg-6 clr-col-xl-6 select-box-search">
                <ng-container  *ngIf="!vendorLoading; else loading">
                    <clr-input-container>
                        <label>{{programs[(program$ | async)]}} Vendors </label>
                        <input type="search" clrInput [disabled]="disableModelSelection || disableVendorSelection || searchKeyword || (dateRevision == 'revision')" placeholder="Search by Vendor Name" name="vendorSearch" [(ngModel)]="vendorSearch" (keyup)="filterVendors()">
                    </clr-input-container>
                    <clr-select-container>
                        <!-- <label>{{programs[(program$ | async)]}} Vendors </label> -->
                        <select clrSelect name="vendors" [(ngModel)]="vendor" multiple (change)="selectedVendorModels(vendor)"
                            [disabled]="disableModelSelection || disableVendorSelection || searchKeyword || (dateRevision == 'revision')">
                            <option *ngFor="let vendor of rnVendors" [value]="vendor" title="{{ vendor }}">{{vendor}}</option>
                        </select>
                    </clr-select-container>
                </ng-container>
            </div>
            <div class="clr-col-sm-12 clr-col-md-6 clr-col-lg-6 clr-col-xl-6 select-box-search" *ngIf="dateRevision != 'revision'">
                <ng-container *ngIf="!modelLoading; else loading">
                    <clr-input-container>
                        <label>{{programs[(program$ | async)]}} Models </label>
                        <input type="search" clrInput [disabled]="disableModelSelection || disableVendorSelection || searchKeyword || (dateRevision == 'revision')" placeholder="Search by Model Name/Model Series" name="modelSearch"  [(ngModel)]="modelSearch" (keyup)="filterModels()">
                    </clr-input-container>
                    <clr-select-container>
                        <!-- <label>{{programs[(program$ | async)]}} Models </label> -->
                        <select clrSelect name="models" [(ngModel)]="rnModel" multiple
                            [disabled]="disableModelSelection || disableVendorSelection || searchKeyword || (dateRevision == 'revision')">
                            <option *ngFor="let model of rnModels" [value]="model" title="{{ model }}">{{model}}</option>
                        </select>
                    </clr-select-container>
                </ng-container>
            </div>
            <div class="clr-col-sm-12 clr-col-md-6 clr-col-lg-6 clr-col-xl-6" *ngIf="dateRevision == 'revision'">
                <clr-select-container>
                    <label>{{programs[(program$ | async)]}} Models </label>
                    <select clrSelect name="model" [(ngModel)]="rnModelSingle" required (change)="onSingleModelSelection()"
                    [disabled]="disableSingleModelSelection">
                        <option value="0" selected>Select a Model</option>
                        <option *ngFor="let model of rnModels" [value]="model">{{model}}</option>
                    </select>
                </clr-select-container>
            </div>
        </div>
        <div class="clr-row" style="clear: both; margin-bottom: 1rem;">
            <div class="clr-col-sm-12 clr-col-md-6 clr-col-lg-6 clr-col-xl-6" *ngIf="dateRevision == 'dateRange'">
                <clr-input-container>
                    <label>Keyword</label>
                    <input type="text" clrInput placeholder="Search with Product ID(s), Model Name, Model Series" name="keyword" autofocus
                        [(ngModel)]="searchKeyword" (input)="disableModelsAndVendors(keyword.value)" #keyword="ngModel">
                    <clr-control-helper>
                        Search with Product Id(s), Model Name or Model Series<br>
                        To enter multiple product id's, separate with comma
                    </clr-control-helper>
                    <!-- <clr-control-error *ngIf="!productid.valid">Please enter 5 digit product id</clr-control-error> -->
                </clr-input-container>
            </div>
            <div class="clr-col-sm-12 clr-col-md-6 clr-col-lg-6 clr-col-xl-6" *ngIf="dateRevision == 'revision'">
                <clr-input-container>
                    <label>Product ID</label>
                    <input type="text" clrInput placeholder="Search with Product ID" name="productid" autofocus
                        [(ngModel)]="productId" (input)="disableModelsAndVendors(productid.value)" #productid="ngModel"
                        pattern="^(\d{5}$)+" [disabled]="disableProductId" required>
                    <clr-control-helper style="color: #C21D00;" *ngIf="!productid.value">Enter single Product Id</clr-control-helper>
                    <clr-control-error *ngIf="!productid.valid">Please enter 5 digit product id</clr-control-error>
                    <!-- <clr-control-error *ngIf="productid.untouched">Enter single Product Id</clr-control-error> -->
                </clr-input-container>
            </div>
            <div class="clr-col-sm-12 clr-col-md-6 clr-col-lg-6 clr-col-xl-6">
                <div class="clr-row" style="display: block;">
                    <div class="clr-col-sm-4 clr-col-md-4 clr-col-lg-4 clr-col-xl-4">
                        <div class="btn-group btn-sm">
                            <div class="radio btn">
                                <input type="radio" name="dateRevisions" id="dateRevisions2" [(ngModel)]="dateRevision"
                                    [value]="'revision'" (change)="onChangeDateRevisions()">
                                <label for="dateRevisions2">Revisions 
                                    <!-- <span class="badge badge-orange">BETA</span> -->
                                </label>
                            </div>
                            <div class="radio btn">
                                <input type="radio" name="dateRevisions" id="dateRevisions1" [(ngModel)]="dateRevision"
                                    [value]="'dateRange'" (change)="onChangeDateRevisions()">
                                <label for="dateRevisions1">Date Range</label>
                            </div>
                        </div>
                    </div>
                    <div class="clr-col-sm-8 clr-col-md-8 clr-col-lg-8 clr-col-xl-8"
                        *ngIf="dateRevision == 'dateRange'">
                        <clr-select-container>
                            <label>Date Range</label>
                            <select clrSelect name="dateRange" [(ngModel)]="dateSelected.month"
                                (change)="onDateChange()">
                                <option *ngFor="let range of dateRange | keyvalue: originalOrder" [value]="range.key">
                                    {{range.value}}</option>
                            </select>
                        </clr-select-container>
                    </div>
                    <div class="clr-col-sm-8 clr-col-md-8 clr-col-lg-8 clr-col-xl-8" *ngIf="dateRevision == 'revision'">
                        <div>
                            <div class="alert alert-info alert-sm" role="alert">
                                <div class="alert-items">
                                    <div class="alert-item static">
                                        <div class="alert-icon-wrapper">
                                            <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                                        </div>
                                        <span class="alert-text">
                                            Revisions support for a single component.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <clr-select-container>
                            <label>Revisions
                                <clr-tooltip>
                                    <clr-icon clrTooltipTrigger shape="info-circle" size="24" aria-label="revision tooltip"></clr-icon>
                                    <clr-tooltip-content clrPosition="top-right" clrSize="md" *clrIfOpen>
                                        <span>Product's recent revisions</span>
                                    </clr-tooltip-content>
                                </clr-tooltip>
                            </label>
                            <select clrSelect name="revisionrange" [(ngModel)]="revisonRange">
                                <option *ngFor="let range of revisions" [value]="range">
                                    {{range}}</option>
                            </select>
                        </clr-select-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="clr-row" *ngIf="showStartEndDate" style="margin-bottom: 1rem;">
            <div class="clr-col-sm-12 clr-col-md-6 clr-col-lg-6 clr-col-xl-6">
                <clr-date-container>
                    <label>Start Date</label>
                    <input type="date" name="startdate" [(ngModel)]="startDate" clrDate required [max]="today">
                </clr-date-container>
            </div>
            <div class="clr-col-sm-12 clr-col-md-6 clr-col-lg-6 clr-col-xl-6">
                <clr-date-container>
                    <label>End Date</label>
                    <input type="date" name="enddate" [(ngModel)]="endDate" clrDate required [max]="today">
                </clr-date-container>
            </div>
        </div>
        <div class="clr-row" *ngIf="invalidCustomDates">
            <div class="clr-col-sm-12 clr-col-md-12 clr-col-lg-12 clr-col-xl-12">
                <div class="alert alert-danger" role="alert">
                    <div class="alert-items">
                        <div class="alert-item static">
                            <div class="alert-icon-wrapper">
                                <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                            </div>
                            <span class="alert-text">
                                Invalid Date Range: End Date {{endDate}} precedes Start Date {{startDate}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clr-row m-t-1rem">
            <div class="clr-offset-md-6 clr-col-sm-12 clr-col-md-6 clr-col-lg-6 clr-col-xl-6">
                <div style="float: right;">
                    <button type="button" class="btn btn-outline" (click)="resetFilters()">Reset</button>
                    <button type="submit" [clrLoading]="!filterForm.valid || holdShowResultsBtn" class="btn btn-primary"
                        (keyup.enter)="getResults(filterForm.value)" id="searchBtn">Search</button>
                </div>
            </div>
        </div>
    </form>
    <div style="font-style: italic; font-weight: 500; color: hsl(198, 69%, 69%);">
        Note: Baseline set to 6.7U3
    </div>
</div>
<ng-template #loading>
    <span class="spinner spinner-sm">
        Loading...
    </span>
</ng-template>