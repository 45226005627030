import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { EnvironmentsService } from './app/services/environment.service';


const envService = new EnvironmentsService();
if (envService.config.production) {
  enableProdMode();
}
const script = document.createElement('script');
script.innerHTML = `(function (id) {
  function append(scriptid, url, async) {
    var d = document, sn = 'script', f = d.getElementsByTagName(sn)[0];
    if (!f) f = d.head;
    var s = d.createElement(sn);
    s.async = true;
    s.id = scriptid;
    s.src = url;
    f.parentNode.insertBefore(s, f);
  }

  function getRootDomain() {
    var parts = window.location.hostname.split('.');
    if (parts.length === 2) rootDomain = parts[0];
    else if (parts.length > 2) {
      // see if the next to last value is a common tld
      var part = parts[parts.length - 2];
      if (part === 'com' || part === 'co') {
        rootDomain = parts[parts.length - 3]; // go back one more
      }
      else {
        rootDomain = part;
      }
    }
    return rootDomain;
  }

  window.evidon = {};
  window.evidon.id = id;
  var cdn = '//c.evidon.com/', rootDomain = getRootDomain(), noticecdn = cdn + 'sitenotice/';
  append('evidon-notice', noticecdn + 'evidon-sitenotice-tag.js', false);
  append('evidon-location', cdn + 'geo/country.js', true);
  append('evidon-themes', noticecdn + id + '/snthemes.js', true);
  console.log(rootDomain);
  if (rootDomain) append('evidon-settings', noticecdn + id + '/' + rootDomain + '/settings.js', true);

  window.evidon.priorConsentCallback = function () {
    if (/Selenium|LoadPass|PhantomJS/.test(navigator.userAgent)) {
      // E2E Testing with Selenium: Disable tracking
      window.ga = function() {};
      window.s = undefined;
    } else {
      // Google Analytics Bootstrap
      (function (i, s, o, g, r, a, m) {
          i['GoogleAnalyticsObject'] = r;
          i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
          },
          i[r].l = 1 * new Date();
          a = s.createElement(o),m = s.getElementsByTagName(o)[0]; a.async = 1;
          a.src = g;
          m.parentNode.insertBefore(a, m)
      })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
      ga('create', '` + envService.config.trackingId + `', 'ports.vmware.com');
      ga('send', 'pageview');
    }
  }
})(4478);`
document.head.appendChild(script);
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));