import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = {};
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = {
                            message: error.error.message
                        };
                    } else {
                        // server-side error
                        errorMessage = {
                            errCode: error.status,
                            message: error.message
                        };
                    }
                    return throwError(errorMessage);
                })
            );
    }
}