import { Directive, OnInit, Renderer2, ElementRef, Input, AfterViewInit, OnChanges } from '@angular/core';
import * as is from 'is-js';
import { EnvironmentsService } from '../services/environment.service';
const VSANIO = 'vsanio'
const SSD = 'ssd'
const HDD = 'hdd'

@Directive({
  selector: '[showLinks]'
})
export class ShowLinksDirective implements OnInit, OnChanges {
  constructor(private el: ElementRef, private renderer: Renderer2, private envService: EnvironmentsService) { }
  @Input('showLinks') linkId: string;
  @Input('linkText') linkText: string;
  @Input('componentType') componentType: string;
  @Input('programSelected') programSel: string;
  ngOnInit() {
    let markupStr = "";
    if (typeof this.linkId != 'number' && this.linkId != null && this.linkId.indexOf('/') > 0 && this.linkId.substring(0, this.linkId.indexOf('/')) == 'VCG_BASE_LINK') {
      markupStr = "<a style='font-weight: 600' target='_blank' href=" + this.envService.config?.VCG_BASE_LINK + this.linkId.substring(this.linkId.indexOf('/')) + ">" + this.linkText + "</a>";
      this.el.nativeElement.innerHTML = markupStr;
    } else if (typeof this.linkId != 'number' && this.linkId && this.linkId.indexOf('http') > 0) {
      markupStr = "<a style='font-weight: 600' target='_blank' href=" + this.linkId.substring(this.linkId.indexOf('http')) + ">" + this.linkText + "</a>";
      this.el.nativeElement.innerHTML = markupStr;
    } else if (is.numeric(this.linkId) && this.componentType) {
      let compType: string;
      if (this.componentType == 'controller') {
        compType = VSANIO;
      } else if (this.componentType == 'cachingTier') {
        compType = SSD;
      } else if (this.componentType == 'capacityTier') {
        compType = HDD;
      }
      if(compType) {
        markupStr = "<a style='font-weight: 600' target='_blank' href=" + this.envService.config?.revisionCntrlUrl + "?program=" + compType + "&productId=" + this.linkId + ">" + this.linkText + "</a>";
        this.el.nativeElement.innerHTML = markupStr;
      }
    } else if (typeof this.linkText != 'number' && this.linkText && this.linkText.indexOf("<a") != -1) {
      if(this.linkText.indexOf(':') < 0) {
        let hrefUrlConstruct = (this.linkText.split('//')).join("://");
        markupStr = "<a style='font-weight: 600' target='_blank' href=" + hrefUrlConstruct.substring(hrefUrlConstruct.indexOf('http'), hrefUrlConstruct.lastIndexOf('"')) + ">" + hrefUrlConstruct.substring(hrefUrlConstruct.indexOf('>') + 1, hrefUrlConstruct.lastIndexOf('<')) + "</a>";
        this.el.nativeElement.innerHTML = markupStr;
      }
    }
  }

  ngOnChanges(){
    let markupStr = "";
    if (typeof this.linkId != 'number' && this.linkId != null && this.linkId.indexOf('/') > 0 && this.linkId.substring(0, this.linkId.indexOf('/')) == 'VCG_BASE_LINK') {
      markupStr = "<a style='font-weight: 600' target='_blank' href=" + this.envService.config?.VCG_BASE_LINK + this.linkId.substring(this.linkId.indexOf('/')) + ">" + this.linkText + "</a>";
      this.el.nativeElement.innerHTML = markupStr;
    } 
  }
}
