import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvironmentsService } from 'src/app/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class ManageSubscriptionsService {

  constructor(private http: HttpClient, private envService: EnvironmentsService) { }

  getPartners(payload: string): Observable<string> {
    return this.http.get<string>(this.envService.config.subscriptionEndpoint + `unsubscription?requestPayload=${encodeURIComponent(payload)}`);
  }
}
