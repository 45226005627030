import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'rn-release-comp',
  templateUrl: './rn-release-comp.component.html',
  styleUrls: ['./rn-release-comp.component.scss']
})
export class RnReleaseCompComponent implements OnInit, OnChanges {

  @Input() openRevComparisonModal: boolean;
  @Input() releasesForComp: any;
  @Input() productDetails: { productName: any; partnerName: any; vcgLink: any; };
  @Output() closeRevModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() releaseCompLabels: any;
  constructor() { }

  ngOnInit() {
  }
  ngOnChanges() {
    if(this.releasesForComp && this.releasesForComp['status'] == 'D'){
      if(this.releasesForComp['releases'] && this.releasesForComp['releases'].length > 0){
        this.releasesForComp['releases'].forEach(release => {
          for(let key in release['rnComponents']){
            for(let jkey in release['rnComponents'][key]){
              let splittedValue = release['rnComponents'][key][jkey].split('||');
              if(splittedValue[1] && splittedValue[1] != '' && splittedValue[1] != undefined && splittedValue[1] != null){
                release['rnComponents'][key][jkey] = splittedValue[0];
              }
            }
          }
        })
      }
    }
  }
  checkForModifyExceptionCases(valueToCompare: any): boolean {
    let exceptions = [null, "", 'NA', 'N/A', '0', undefined, "null", "undefined"];
    if (typeof valueToCompare == 'string' && valueToCompare.length == 0) {
      return true;
    }
    return exceptions.includes(valueToCompare);
  }
  checkForDataEmptynies(value) {
    if (this.checkForModifyExceptionCases(value)) {
      return 'N/A'
    } else {
      return value
    }
  }
  closeModal() {
    this.closeRevModal.emit(false);
  }
  /* preserves order of obj props when applied keyvalue pipe */
  originalOrder = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => {
    const a = akv.value.index;
    const b = bkv.value.index;
    return a > b ? 1 : (b > a ? -1 : 0);
  }
}
