<ng-container *ngFor="let auditedProducts of groupedAuditedProducts; let i = index;">
    <div *ngIf="(auditedProducts['auditRecord'] | json) != '{}';">
        <clr-accordion>
            <clr-accordion-panel [(clrAccordionPanelOpen)]="expanded[i]">
                <clr-accordion-title>
                    {{auditedProducts['dateRange'].fromDate | date : 'd MMMM y'}} -
                    {{auditedProducts['dateRange'].toDate | date : 'd MMMM y'}}
                    <!-- <span style="padding-left: 0.3rem; font-style: italic;">{{productChanged(auditedProducts, p[i])}}</span> -->
                </clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                    <div *ngIf="loadingResults; else spinner">
                    <ng-container
                        *ngFor="let groupedProduts of auditedProducts['auditRecord'] | keyvalue | paginate: {id: i, itemsPerPage: 10, currentPage: p[i], totalItems: auditedProducts['totalElements']}; let gp = index">
                        <div class="prod-release-accordian">
                            <div class="product-model">
                                <span class="p-l-1rem" >
                                {{groupedProduts['value'][0]['partnerName']}}: </span>
                                <span [showLinks]="groupedProduts['value'][0]['data']['vcgLink']"
                                [linkText]="groupedProduts['value'][0]['model']"> {{groupedProduts['value'][0]['model']}}</span>
                                <div class="revision-comp-chart">
                                    <span style="margin-right: 10px;">
                                        <a (click)="subscribeToProduct(groupedProduts['key'], groupedProduts['value'][0]['model'], groupedProduts['value'][0]['partnerName'])">Subscribe</a>
                                    </span>
                                    <span>
                                    <a
                                        (click)="openRevCompModal(groupedProduts['key'], groupedProduts['value'][0]['model'], groupedProduts['value'][0]['partnerName'], groupedProduts['value'][0]['data']['vcgLink'], auditedProducts['dateRange'].fromDate, auditedProducts['dateRange'].toDate, groupedProduts['value'][0]['data']['type'])">Release
                                        Comparison Chart</a>
                                        <clr-tooltip>
                                            <clr-icon clrTooltipTrigger shape="info-circle" size="24" aria-label="revision tooltip"></clr-icon>
                                            <clr-tooltip-content clrPosition="top-left" clrSize="md" *clrIfOpen>
                                                <span>Product's Release Comparison chart for the month</span>
                                            </clr-tooltip-content>
                                        </clr-tooltip>
                                    </span>
                                </div>
                            </div>
                            <clr-accordion *ngIf="groupedProduts['value'][0]['prodChanges'] != 'No Changes'">
                                <clr-accordion-panel>
                                    <clr-accordion-title>
                                        Product Revision <span style="padding-left: 0.3rem; font-style: italic;">{{groupedProduts['value'][0]['prodChanges'] ? "- "+ groupedProduts['value'][0]['prodChanges'] : '' }}</span>
                                    </clr-accordion-title>
                                    <clr-accordion-content *clrIfExpanded>
                                        <table class="table table-large">
                                            <thead>
                                                <tr>
                                                    <th class="left">Timeline</th>
                                                    <ng-container
                                                        *ngFor="let product of groupedProduts.value | dateSort">
                                                        <th class="left">
                                                            {{product['createdDateTs'] | date : 'y-MM-dd h:mm:ss a' }}
                                                        </th>
                                                    </ng-container>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container
                                                    *ngFor="let label of productLabelsConfig | keyvalue: originalOrder">
                                                    <tr
                                                        *ngIf="label['key'] != 'footnotes' && label['key'] != 'rnComponents' && label['key'] != 'rnGeneration'">
                                                        <td class="left label-bold">
                                                            {{label['value']}}
                                                        </td>
                                                        <td class="left"
                                                            *ngFor="let product of groupedProduts.value | dateSort"
                                                            appStatusParser
                                                            [class.created]="product && product['status'] == 'C'"
                                                            [class.delete]="product && product['status'] == 'D'"
                                                            [class.added]="product && product['status'] == 'A'">
                                                            <span [showLinks]="product['data'][label['key']]"
                                                                [linkText]="'VCG_BASE_LINK'">{{product['data'][label['key']]}}</span>
                                                        </td>
                                                    </tr>
                                                    <ng-container *ngIf="label['key'] == 'footnotes'">
                                                        <tr>
                                                            <td class="left label-bold">
                                                                {{label['value']}}</td>
                                                            <td class="left"
                                                                *ngFor="let product of groupedProduts.value | dateSort"
                                                                [ngClass]="{
                                                                    'created': product && product['status'] == 'C',
                                                                    'modified': product['data'] && product['data']['footnotesStatus'] == 'M',
                                                                    'added': (product && product['status'] == 'A') || product['data'] && product['data']['footnotesStatus'] == 'A',
                                                                    'delete': (product && product['status'] == 'D') || product['data'] && product['data']['footnotesStatus'] == 'D'
                                                                }">
                                                                <ng-container *ngIf="product['data'][label['key']]; else prodfootNoteNA">
                                                                <ng-container
                                                                    *ngFor="let footnote of product['data'][label['key']];">
                                                                    <div style="white-space: pre-line;"> 
                                                                        {{checkForDataEmptynies(footnote.footnote)  | handleLongStrings: 70}}
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-template #prodfootNoteNA>
                                                                N/A
                                                            </ng-template>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                    <ng-container *ngIf="label['key'] == 'rnComponents'">
                                                        <tr>
                                                            <td class="left label-bold">
                                                                {{label['value']}}</td>
                                                            <td class="left"
                                                                *ngFor="let product of groupedProduts.value | dateSort"
                                                                [class.created]="product && product['status'] == 'C'"
                                                                [class.delete]="product && product['status'] == 'D'"
                                                                [class.added]="product && product['status'] == 'A'">
                                                                <table class="table" style="margin-top: unset;">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="left">Component</th>
                                                                            <th class="left">Model</th>
                                                                            <th class="left">Quantity</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <ng-container
                                                                            *ngFor="let rnComp of product['data'][label['key']] | keyvalue: originalOrder ;">
                                                                            <ng-container *ngIf="componentLabels[rnComp['key']]">
                                                                                <tr *ngIf="rnComp['value'] != null && (rnComp['key'] != 'nics'); else multirow"
                                                                                [ngClass]="{
                                                                                    'created': product && product['status'] == 'C',
                                                                                    'added': product && product['status'] == 'A',
                                                                                    'modified': rnComp['value'] && rnComp['value']['status'] == 'M',
                                                                                    'delete': product && product['status'] == 'D'
                                                                                }"
                                                                                >
                                                                                    <td class="left label-bold">
                                                                                        {{componentLabels[rnComp['key']]}}
                                                                                    </td>
                                                                                    <ng-container *ngIf="checkIfObj(rnComp['value']); else keypair">
                                                                                        <td class="left" *ngIf="rnComp['value']['model'] || rnComp['value']['model'] == null">
                                                                                            <span
                                                                                                [showLinks]="rnComp['value']['componentId']"
                                                                                                [linkText]="rnComp['value']['model']"
                                                                                                [componentType]="getCompTypeBasedOnCompId(rnComp['key'], rnComp['value']['componentId'], product['data']['type'])">{{checkForDataEmptynies(rnComp['value']['model'])}}</span>
                                                                                        </td>
                                                                                    </ng-container>
                                                                                    
                                                                                    <ng-template #keypair>
                                                                                        <td class="left" appStatusParser>
                                                                                            {{checkForDataEmptynies(rnComp['value'])}}
                                                                                        </td>
                                                                                    </ng-template>
                                                                                    <td class="left">
                                                                                        <ng-container *ngIf="vsanType === 'vsanEsa'; else vsan1">
                                                                                            <ng-container *ngIf="rnComp['key'] === 'cpu'">
                                                                                                <div><strong>Cores: </strong>{{rnComp['value']['totalCPUCoreCount']}}</div>
                                                                                                <div><strong>Sockets: </strong>{{rnComp['value']['cpuSockets']}}</div>
                                                                                            </ng-container>
                                                                                            <ng-container *ngIf="rnComp['key'] === 'nic'">
                                                                                                <strong>Network:</strong> {{rnComp['value']['quantity']}} x {{rnComp['value']['network']}} Gbps
                                                                                            </ng-container>
                                                                                            <ng-container *ngIf="rnComp['key'] === 'memory'">
                                                                                                <strong>Memory:</strong> {{rnComp['value']['totalMemorySize']}} GB
                                                                                            </ng-container>
                                                                                            <ng-container *ngIf="rnComp['key'] === 'system'">
                                                                                                {{rnComp['value']['quantity']}}
                                                                                            </ng-container>
                                                                                            <ng-container *ngIf="rnComp['key'] === 'storageTier'">
                                                                                                <div><strong>No. of Storage Device(s): </strong>{{rnComp['value']['quantity']}}</div>
                                                                                                <div><strong>Storage Device Capacity: </strong>{{rnComp['value']['storageDeviceCapacity']}} TB</div>
                                                                                                <div><strong>Raw Storage Capacity: </strong>{{rnComp['value']['rawStorageCapacity']}} TB</div>
                                                                                            </ng-container>
                                                                                            <ng-container *ngIf="rnComp['key'] === 'bootDevice'">
                                                                                                {{rnComp['value']['quantity'] ? rnComp['value']['quantity'] : 'N/A'}}
                                                                                            </ng-container>
                                                                                        </ng-container>
                                                                                        <ng-template #vsan1>
                                                                                            {{checkForDataEmptynies(rnComp['value']['quantity'])}}
                                                                                        </ng-template>
                                                                                    </td>
                                                                                </tr
                                                                                >
                                                                            </ng-container>
                                                                            <ng-template #multirow>
                                                                                <ng-container
                                                                                    *ngFor="let comp of groupNICData(rnComp['value']) | keyvalue">
                                                                                    <ng-container *ngIf="comp['value'] != null && comp['key'] != 'status'">
                                                                                        <tr
                                                                                        [ngClass]="{
                                                                                            'created': product && product['status'] == 'C',
                                                                                            'added': (rnComp['value']['status'] == 'A' || product['status'] == 'A'),
                                                                                            'modified': rnComp['value']['status'] == 'M',
                                                                                            'delete': product && product['status'] == 'D'
                                                                                        }">
                                                                                        <td class="left label-bold">
                                                                                            {{componentLabels[rnComp['key']]}}
                                                                                        </td>
                                                                                        <td class="left">
                                                                                            <ng-container *ngFor="let grouped of comp['value']; let i = index;">
                                                                                                <span *ngIf="grouped['deviceCategory'] == 'rdmanic'; else ionic">Model (vSAN RDMA): </span>
                                                                                                <ng-template #ionic>
                                                                                                    <span>Model: </span>
                                                                                                </ng-template>
                                                                                                <span 
                                                                                                [linkText]="grouped['model']"
                                                                                                [showLinks]="getNICLinkText(grouped['deviceCategory'], grouped['componentId'])">{{checkForDataEmptynies(grouped['model'])}} </span>
                                                                                                <span *ngIf="i < comp['value'].length - 1"><br/> (OR) <br/></span>
                                                                                            </ng-container>
                                                                                        </td>
                                                                                        <td class="left">
                                                                                            {{checkForDataEmptynies(comp['value'][0]['quantity'])}}
                                                                                        </td>
                                                                                        </tr>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                            </ng-template>
                                                                            
                                                                        </ng-container>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                    <ng-container *ngIf="label['key'] == 'rnGeneration'">
                                                        <tr>
                                                            <td class="left label-bold">
                                                                {{label['value']}}</td>
                                                            <td class="left"
                                                            [ngClass]="{
                                                                'created': product && product['status'] == 'C',
                                                                'modified': product['data'] && product['data']['rnGenerationStatus'] == 'M',
                                                                'added': (product && product['status'] == 'A') || product['data'] && product['data']['rnGenerationStatus'] == 'A',
                                                                'delete': (product && product['status'] == 'D') || product['data'] && product['data']['rnGenerationStatus'] == 'D'
                                                            }"
                                                                *ngFor="let product of groupedProduts.value | dateSort">
                                                                <ng-container
                                                                    *ngFor="let rnGen of product['data'][label['key']];">
                                                                    <div>
                                                                        {{rnGen.rnGeneration}}<br>
                                                                    </div>
                                                                </ng-container>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                        <table class="table table-vertical">
                                            <tbody>
                                                <tr>
                                                    <th>Supported Releases</th>
                                                    <ng-container *ngFor="let product of groupedProduts.value | dateSort;">
                                                        <td> {{getReleasesSupported(product)}}</td>
                                                    </ng-container>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </clr-accordion-content>
                                </clr-accordion-panel>
                            </clr-accordion>
                            <clr-accordion *ngIf="groupedProduts['value'][0]['certChanges'] != 'No Changes'">
                                <clr-accordion-panel>
                                    <clr-accordion-title>
                                        Product Release Revision <span style="padding-left: 0.3rem; font-style: italic;">{{groupedProduts['value'][0]['certChanges'] ? "- "+ groupedProduts['value'][0]['certChanges'] : '' }}</span>
                                    </clr-accordion-title>
                                    <clr-accordion-content>
                                        <div
                                            *ngIf="(supportedProductsPeriodObj[i][groupedProduts.key] | json) != '{}'; else noReleaseDetails">
                                            <div style="text-align: end; padding: 0.2em;">
                                                <a href="javascript:void(0);" (click)="productReleaseExpanded[gp] = !productReleaseExpanded[gp]">{{ productReleaseExpanded[gp] ? 'Collapse': 'Expand'}} All</a>
                                            </div>
                                            <ng-container
                                                *ngFor="let release of supportedProductsPeriodObj[i][groupedProduts.key] | keyvalue: originalOrder;let j = index">
                                                <clr-accordion>
                                                    <clr-accordion-panel [clrAccordionPanelOpen]="productReleaseExpanded[gp]">
                                                        <clr-accordion-title appStatusParser
                                                        [ngClass]="{
                                                            'created': (release['value'] && release['value'][0]['status'] == 'C'),
                                                            'delete': (release['value'] && release['value'][0]['status']  == 'D'),
                                                            'added': (release['value'] && release['value'][0]['status']  == 'A'),
                                                            'modified': (release['value'] && release['value'][0]['status']  == 'M')
                                                        }"
                                                        >
                                                            {{release['value'][0]['releaseVersion']}}
                                                        </clr-accordion-title>
                                                        <clr-accordion-content>
                                                            <table class="table table-large">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="left">
                                                                            Timeline
                                                                        </th>
                                                                        <ng-container
                                                                            *ngFor="let certId of release['value'] | dateSort ;">
                                                                            <th class="left">
                                                                                {{certId['createdDateTs'] | date : 'y-MM-dd h:mm:ss a' }}
                                                                            </th>
                                                                        </ng-container>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <ng-container
                                                                        *ngFor="let label of supportReleaseLabelsConfig | keyvalue: originalOrder">
                                                                        <tr
                                                                            *ngIf="label['key'] != 'footnotes' && label['key'] != 'kbInfo' && label['key'] != 'featureDetails'">
                                                                            <td class="left label-bold">
                                                                                {{label['value']}}
                                                                            </td>
                                                                            <ng-container
                                                                                *ngFor="let certId of release['value']  | dateSort;">
                                                                                <td class="left " appStatusParser
                                                                                    [class.created] = "certId && certId['status'] == 'C'"
                                                                                    [class.delete]="certId && certId['status'] == 'D'"
                                                                                    [class.added]="certId && certId['status'] == 'A'">
                                                                                    {{checkForDataEmptynies(certId[label['key']])}}
                                                                                </td>
                                                                            </ng-container>
                                                                        </tr>
                                                                        <ng-container
                                                                            *ngIf="label['key'] == 'footnotes'">
                                                                            <tr>
                                                                                <td class="left label-bold">
                                                                                    {{label['value']}}</td>
                                                                                <td class="left"
                                                                                    *ngFor="let certId of release['value']  | dateSort; let f = index;"
                                                                                    [ngClass]="{
                                                                                    'created': certId && certId['status'] == 'C',
                                                                                    'modified': release['value'][f] && release['value'][f]['footnotesStatus'] == 'M',
                                                                                    'added': (certId && certId['status'] == 'A') || release['value'][f] && release['value'][f]['footnotesStatus'] == 'A',
                                                                                    'delete': (certId && certId['status'] == 'D') || release['value'][f] && release['value'][f]['footnotesStatus'] == 'D'
                                                                                }">
                                                                                <ng-container *ngIf="certId[label['key']]; else footNoteNA">
                                                                                        <ng-container
                                                                                            *ngFor="let footnote of certId[label['key']]">
                                                                                            {{checkForDataEmptynies(footnote.footnote)  | handleLongStrings: 70}}
                                                                                        </ng-container>
                                                                                    </ng-container>
                                                                                    <ng-template #footNoteNA>
                                                                                        N/A
                                                                                    </ng-template>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="label['key'] == 'kbInfo'">
                                                                            <tr>
                                                                                <td class="left label-bold">
                                                                                    {{label['value']}}</td>
                                                                                <td class="left"
                                                                                    *ngFor="let certId of release['value']  | dateSort; let kb = index;"
                                                                                    [ngClass]="{
                                                                                        'created': certId && certId['status'] == 'C',
                                                                                    'modified': certId && certId['kbInfoStatus']  && certId['kbInfoStatus'] == 'M',
                                                                                    'added': (certId && certId['status'] == 'A') || certId && certId['kbInfoStatus']  && certId['kbInfoStatus'] == 'A',
                                                                                    'delete': (certId && certId['status'] == 'D') || (certId && certId['kbInfoStatus']  && certId['kbInfoStatus'] == 'D')
                                                                                }">
                                                                                <ng-container *ngIf="certId[label['key']]; else knInfoNA">
                                                                                    <ng-container
                                                                                        *ngFor="let kbInfo of certId[label['key']]">
                                                                                        {{ checkForDataEmptynies(kbInfo.kbURL)}}
                                                                                        <br>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                                <ng-template #knInfoNA>
                                                                                    N/A
                                                                                </ng-template>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="label['key'] == 'featureDetails'">
                                                                            <tr>
                                                                                <td class="left label-bold">
                                                                                    {{label['value']}}</td>
                                                                                <td class="left leftFDetails"
                                                                                    *ngFor="let certId of release['value']  | dateSort; let fe = index; "
                                                                                    appStatusParser [ngClass]="{
                                                                                    'created': certId && certId['status'] == 'C',
                                                                                'modified': certId && certId['featureDetailsStatus'] && certId['featureDetailsStatus'] == 'M',
                                                                                'added': (certId && certId['status'] == 'A') || certId && certId['featureDetailsStatus'] && certId['featureDetailsStatus'] == 'A',
                                                                                'delete': (certId && certId['status'] == 'D') || certId && certId['featureDetailsStatus'] && certId['featureDetailsStatus'] == 'D'
                                                                            }">
                                                                            <ng-container *ngIf="certId[label['key']]; else featureNA">
                                                                                    <ng-container
                                                                                        *ngFor="let featureDetails of certId[label['key']]">
                                                                                        <div class="featureDetail">
                                                                                            <ng-container
                                                                                                *ngFor="let featureTuple of featureDetails | keyvalue">
                                                                                                <span
                                                                                                    style="font-weight: 500; padding-left: 0.3rem;">{{featureTuple.key | titlecase }}:
                                                                                                </span>
                                                                                                <span
                                                                                                    style="padding-left: 0.1rem;">{{checkForDataEmptynies(featureTuple.value)}}</span>
                                                                                            </ng-container>
                                                                                        </div>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                                <ng-template #featureNA>
                                                                                    N/A
                                                                                </ng-template>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </tbody>
                                                            </table>
                                                        </clr-accordion-content>
                                                    </clr-accordion-panel>
                                                </clr-accordion>
                                            </ng-container>
                                        </div>
                                    </clr-accordion-content>
                                </clr-accordion-panel>
                            </clr-accordion>
                        </div>
                    </ng-container>
                </div>
                    <pagination-controls class="product-pagination"  [id]="i"
                     (pageChange)="p[i] = $event; getPage($event, i, auditedProducts['dateRange'].fromDate, auditedProducts['dateRange'].toDate)">
                    </pagination-controls>
                </clr-accordion-content>
            </clr-accordion-panel>
        </clr-accordion>
    </div>
</ng-container>

<rn-release-comp [openRevComparisonModal]="openRevComparisonModal" [releaseCompLabels]="releaseCompLabels"
    [productDetails]="productDetails" [releasesForComp]="releasesForComp"
    (closeRevModal)="clsRevModal($event)"></rn-release-comp>
<subscribe [productDetails]="productDetails" [openSubscriptionModal]="openSubscriptionModal" [openSubscribedModal]="openSubscribedModal" [openAlreadySubscribedModal]="openAlreadySubscribedModal" [openSubscribedErrorModal]="openSubscribedErrorModal" (closeSubscriptionModal)="closeSubModal($event)"></subscribe>

<ng-template #showNoResults>
    <div class="no-results">
        <strong>Product(s) revisions are not available for selected criteria.</strong>
    </div>
</ng-template>

<ng-template #noReleaseDetails>
    <div class="no-results">
        <strong>Baseline release(s) are not found for the product.</strong>
    </div>
</ng-template>

<ng-template #spinner>
    <div class="spinnerBtn">
        <span class="spinner spinner-md">
        </span>
    </div>
</ng-template>