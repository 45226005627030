<div class="branding">
  <a class="nav-link" routerLink="/home">
    <img _ngcontent-wrw-c184="" src="assets/img/VMware-by-Broadcom-Logo_white.svg" class="vmw-broad-logo" alt="Vmware by Broadcom">
    <div class="logo-brand">
      <span class="title">vSAN VCG Notification Service </span>
    </div>
  </a>
</div>
<div class="header-nav" *ngIf="!appService.hidePrograms">
  <clr-dropdown [clrCloseMenuOnItemClick]="true">
    <button clrDropdownTrigger aria-label="notification type">
      Notification Type
      <clr-icon shape="caret down"></clr-icon>
    </button>
    <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
      <div clrDropdownItem *ngFor="let program of programs | keyvalue: originalOrder" (click)="programSelected(program['key'])">
        {{program['value']}}
        <span *ngIf="program.key === 'vsanrnOSA'" class="label label-info">OSA</span>
        <span *ngIf="program.key === 'vsanrnESA'" class="label label-info">ESA</span>
      </div>
    </clr-dropdown-menu>
  </clr-dropdown>
</div>
<div class="header-nav">
  <a routerLink="/dashboard" routerLinkActive="active" class="nav-link nav-text">My Subscriptions</a>
</div>
<div class="header-actions">
  <div class="header-nav">
    <a class="nav-link cursor-pointer" id="vns-feedback" title="Feedback">
        Feedback
    </a>
  </div>
  <div class="">
    <a routerLink="/faq" routerLinkActive="active" class="nav-link nav-icon" aria-label="FAQ">
      FAQ
    </a>
  </div>
  <clr-dropdown [clrCloseMenuOnItemClick]="false" style="padding-top: 0.1rem;">
    <button clrDropdownTrigger aria-label="User">
        <clr-icon shape="user" class="is-solid" size="24"></clr-icon>
        <clr-icon shape="caret down"></clr-icon>
    </button>
    <ng-container *ngIf="(currentUser !== null && currentUser.email) else login">
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <div aria-label="Dropdown header Action 1" clrDropdownItem>{{ currentUser.email }}</div>
        <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
        <div clrDropdownItem>
          <a href="javascript:void(0);" (click)="logout()">
            <clr-icon shape="logout" class="is-solid"></clr-icon> Signout
          </a>
        </div>
    </clr-dropdown-menu>
    </ng-container>
    <ng-template #login>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <div clrDropdownItem>
          <a routerLink="/login">
            <clr-icon shape="login" class="is-solid"></clr-icon> Sign-in
          </a>
        </div>
    </clr-dropdown-menu>
    </ng-template>
  </clr-dropdown>
</div>