import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, observable } from 'rxjs';
import { map, debounce } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';

import { User } from './../data-models/user'
import { EnvironmentsService } from './environment.service';

const helper = new JwtHelperService();

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient, private router: Router, private envService: EnvironmentsService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, otp: number) {
        let loginPayload = {
            userEmail: email,
            otpNumber: otp
        }
        return this.http.post<any>(this.envService.config.otpEndpoint + `product/login`, loginPayload)
        .pipe(map(response => {
            let user = new User();
    
            // login successful if there's a jwt token in the response
            // and if that token is valid
            if (response && response.token) {
                let decodedToken = helper.decodeToken(response.token);
                // Build up a user
                user.token  = response.token;
                user.email  = decodedToken.eml;
        
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.setFeedBackUser(user.email);
                this.currentUserSubject.next(user);
            }
            return user;
        }));
    }

    logout() {
        // remove user from local storage to log user out
        return this.http.delete<any>(this.envService.config.otpAuthEndpoint + `product/logout`).pipe(map(response => {
            if(response.statusCode == 200) {
                localStorage.removeItem('currentUser');
                this.currentUserSubject.next(null);
                this.setFeedBackUser(null);
                return true;
            }
        }));
    }

    logoutFromLocal() {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.setFeedBackUser(null);
    }

    isLoggedIn() {
        let user = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null;
        if((user !== null && helper.isTokenExpired(user.token)) || user === null) {
            this.setFeedBackUser(null);
            this.logout().subscribe((loggedout) => {
                if(loggedout) {
                    setTimeout(() => {
                        this.router.navigate(['/login']);
                    }, 200)
                }
            }, error => {
                this.logoutFromLocal();
                setTimeout(() => {
                    this.router.navigate(['/login']);
                }, 200)
            });
        } else {
            this.setFeedBackUser(this.currentUserValue.email);
            return true;
        }
    }

    isActiveUser() {
        return localStorage.getItem('currentUser') && this.currentUser;
    }

    userEmail() {
        return this.currentUser ? this.currentUserValue.email : '';
    }

    setFeedBackUser(userEmail:string): void {
        const user = userEmail ? userEmail : null;
        localStorage.setItem('esp-feedback-user', user);
        const script = document.createElement('script');
        script.innerHTML = `lumos.identify('${user}')`;
        document.head.appendChild(script);
    }
}