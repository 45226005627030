import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ResultsService } from '../../services/results.service';
import { FilterPayload } from 'src/app/data-models/filter-payload';
import * as moment from 'moment';
import { FilterService } from '../../services/filter.service';
import { NgForm } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { Programs } from 'src/app/programs/programs';
import { KeyValue } from '@angular/common';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { switchMap, take, takeUntil } from 'rxjs/operators';

const DATERANGE = {
  "7": "One Week",
  "30": "One Month",
  "90": "Three Month",
  "180": "Six Month",
  "365": "One Year",
  "Custom": "Custom"
};

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy {

  getResultsSubject: Subject<boolean> = new Subject<boolean>();
  getResultsSubject$ = this.getResultsSubject.asObservable();
  disableModelSelection: boolean;
  disableVendorSelection: boolean;
  rnModels: string[];
  rnModelsStatic: string[];
  today = new Date().toJSON().split('T')[0];
  programs: any;
  allPartnersNModels: any;
  disableProductId: boolean;
  disableSingleModelSelection: boolean;
  productReleaseExpanded: Array<boolean>;
  constructor(private resultsService: ResultsService, private filterService: FilterService, private appService: AppService, private errorHandler: ErrorHandlerService) { }

  @ViewChild('filterForm') public filterForm: NgForm;
  dateRange: any = {};
  dateSelected: {month: string} = {month: "30"};
  showStartEndDate: boolean;
  rnModelSingle: string;
  rnModel: string[];
  vendor: string[];
  rnVendors: Array<string>;
  invalidCustomDates: boolean;
  startDate: string;
  endDate: string;
  productId: string;
  searchKeyword: string;
  vendorSearch: string;
  modelSearch: string;
  program$: Observable<string>;
  holdShowResultsBtn: boolean = true;
  togleVendorsAndModels: boolean = true;
  dateRevision: string = "dateRange";
  revisions: Array<number> = [1, 2, 3, 4, 5];
  revisonRange: number = 5;
  singleproductId: string;
  vendorLoading: boolean = false;
  modelLoading: boolean = false;
  destroy$ = new Subject();
  ngOnInit() {
    this.programs = Programs.programs;
    this.program$ = this.appService.getProgramSelected();
    this.vendorLoading = true;
    this.modelLoading = true;
    this.program$.pipe(takeUntil(this.destroy$)).subscribe(programSelected => {
      this.holdShowResultsBtn = true;
      this.filterService.getPartnersAndModelsMap(programSelected, this.appService.vsanType.getValue() == 'vsanEsa' ? true : false).pipe(takeUntil(this.destroy$)).subscribe(partnersNModels => {
        this.allPartnersNModels = partnersNModels;
        this.rnVendors = Object.keys(this.allPartnersNModels);
        this.rnVendors.sort();
        this.rnVendors.unshift('All');
        this.vendor = ['All'];
        this.holdShowResultsBtn = false;
        this.getResultsSubject.next(this.holdShowResultsBtn);
        if(this.dateRevision && this.dateRevision == 'revision'){
          this.selectedVendorModels(['All'], true);
        } else{
          this.selectedVendorModels(['All'], false);
        }
        this.vendorLoading = false;
        this.modelLoading = false;
      }, error => {
        if(error.message){
          error.message = "Failed to fetch Vendor and Models";
        }
        this.errorHandler.sendMessage(error);
      });
    })
    this.dateRange = DATERANGE;
    this.setDefaults();
    this.appService.getProductsSelected().subscribe(products => {
      this.productId = products;
      if(this.productId){
        this.disableModelsAndVendors(this.productId);
      }
    });
    this.appService.getProductsFromDate().subscribe(fromDate => {
      if(fromDate){
        this.dateSelected.month = 'Custom';
        this.onDateChange();
        this.startDate = new Date(fromDate).toLocaleDateString("en-US");
      }
    });
    this.appService.getProductsToDate().subscribe(toDate => {
      if(toDate){
        this.endDate = new Date(toDate).toLocaleDateString("en-US");;
      }
    });
    this.appService.getProductsRevisionCount().subscribe(count => {
      if(count){
        this.dateRevision = 'revision';
        this.revisonRange = Number(count);
        this.onChangeDateRevisions();
      }
    });
    this.getResultsSubject$.subscribe(holdShowResultsBtn => {
      if(!holdShowResultsBtn){
        let element: HTMLElement = document.getElementById("searchBtn") as HTMLElement;
        this.getResults(this.filterForm.value);
      }
    });
  }

  selectedVendorModels(model: string[], singleModelSelection = false){
    this.rnModels = this.rnModelsStatic = [];
    this.modelSearch = '';
    if(model.includes('All') && this.allPartnersNModels ){
      for(let key in this.allPartnersNModels){
        this.rnModels = this.rnModels.concat(this.allPartnersNModels[key]) 
      }
      this.rnModels.sort();
      if(!singleModelSelection){
        this.rnModels.unshift('All');
        // this.vendor = ['All'];
    // this.dateSelected.month = "30";
    this.rnModel = ['All'];
      }
    } else {
      model.forEach(vendor => {
        if(vendor != 'All'){
          this.rnModels = this.rnModels.concat(this.allPartnersNModels[vendor]);
        }
      });
      this.rnModels.sort();
      if(!singleModelSelection){
        this.rnModels.unshift('All');
        // this.vendor = ['All'];
    // this.dateSelected.month = "30";
    this.rnModel = ['All'];
      }
    }
    this.rnModelsStatic = this.rnModels;
  }

  resetVendors() {
    this.rnVendors = Object.keys(this.allPartnersNModels);
    this.rnVendors.sort();
    this.rnVendors.unshift('All');
    this.vendor = ['All'];
    this.selectedVendorModels(['All'], false);
  }
  onDateChange() {
    if (this.dateSelected.month === 'Custom') {
      this.showStartEndDate = true;
    } else {
      this.showStartEndDate = false;
      this.invalidCustomDates = false;
    }
  }
  setDefaults() {
    this.vendor = ['All'];
    this.vendorSearch = '';
    this.dateSelected.month = "30";
    this.rnModel = ['All'];
    this.modelSearch = '';
    this.dateRevision = "dateRange";
    this.productId = '';
    this.searchKeyword = '';
    this.rnModelSingle = "0";
    if(this.allPartnersNModels){
      this.resetVendors();
      this.selectedVendorModels(this.rnModel, false);
    }
  }

  onChangeDateRevisions(){
    if(this.dateRevision == 'revision'){
      this.showStartEndDate = false;
      this.invalidCustomDates = false;
      this.rnModel = null;
      this.vendor = null;
      this.disableModelSelection = true;
      this.disableVendorSelection = true;
      this.onRevisionsSelection();
      this.selectedVendorModels(['All'], true);
    } else {
      this.disableModelSelection = false;
      this.disableVendorSelection = false;
      this.rnModel = ['All'];
      this.vendor = ['All'];
      this.selectedVendorModels(['All'], false);
      if(this.dateSelected.month === 'Custom'){
        this.showStartEndDate = true;
      }
    }
  }

  onRevisionsSelection(){
    this.disableProductId = false;
    this.disableSingleModelSelection = false;
    this.rnModelSingle = '0';
    // this.productId = '';
  }

  getResults(formValue) {
    let payload = new FilterPayload();
    let toDate: string;
    let fromDate: string;
    let revisionrange: string;
    fromDate = this.calculateToAndFromDate(formValue.dateRange, "YYYY-MM-DD");
    toDate = moment(new Date()).utc().format("YYYY-MM-DD");
    if(this.dateRevision == 'dateRange'){
      revisionrange = "";
      if (this.dateSelected.month === "Custom") {
        toDate = moment(formValue.enddate).format("YYYY-MM-DD");
        fromDate = moment(formValue.startdate).format("YYYY-MM-DD");
        if (moment(toDate).isBefore(fromDate)) {
          this.invalidCustomDates = true;
          return;
        } else {
          this.invalidCustomDates = false;
        }
      }
    }else {
      revisionrange = formValue.revisionrange ? formValue.revisionrange: "";
      toDate = "";
      fromDate = "";
    }
    let models = []; let vendors = [];
    if(this.dateRevision == 'revision') {
      models = this.rnModelSingle && this.rnModelSingle !== '0' ? [this.rnModelSingle] : [];
      vendors = [];
    } else {
      models = formValue ? (formValue.models ? formValue.models : []) : [];
      vendors = formValue ? (formValue.vendors ? formValue.vendors : []) : [];
    }
    payload = {
      model: models,
      partner: vendors,
      productId: this.dateRevision == 'revision' ? (formValue.productid ? this.dSerializeProductIds(formValue.productid) : []) : [],
      days: 0,
      revisionCount: revisionrange,
      fromDate: fromDate ? fromDate : "",
      toDate: toDate ? toDate : "",
      number: 0,
      size: 10
    };
    if(formValue.keyword !== undefined && formValue.keyword.trim() && this.dateRevision == 'dateRange') {
      if(this.isKeywordSearchProductId(formValue.keyword)) {
        payload['productId'] = formValue.keyword ? this.dSerializeProductIds(formValue.keyword) : [];
      } else {
        payload['model'] = this.getModelsMatchingKeyword(formValue.keyword);
      }
    }
    this.resultsService.setResultsSubject(payload);
  }
  dSerializeProductIds(productIds: string){
    let deserializedProducts: Array<string> = [];
    if(productIds.indexOf(',') != -1){
      deserializedProducts = productIds.split(',');
      if(deserializedProducts[deserializedProducts.length -1].length < 5){
        deserializedProducts.pop();
      }
    } else {
      deserializedProducts.push(productIds);
    }
    return deserializedProducts;
  }
  isKeywordSearchProductId(keyword: any) {
    if(keyword === undefined || keyword.trim() == '') {
      return false;
    }
    let deserializedKeyword = [];
    if(keyword.indexOf(',') != -1){
      deserializedKeyword = keyword.split(',');
      for (let index = 0; index < deserializedKeyword.length; index++) {
        if(isNaN(deserializedKeyword[index])) {
          return false;
        }
      }
    } else {
      return !(isNaN(keyword) || keyword.toString().length < 5);
    }
    return true;
  }

  getModelsMatchingKeyword(keyword: string) {
    if(keyword === undefined || keyword.trim() == '') {
      return [];
    }
    let matchingModels = [];
    for(let key in this.allPartnersNModels){
      for (let index = 0; index < this.allPartnersNModels[key].length; index++) {
        if(this.allPartnersNModels[key][index].toLowerCase().includes(keyword.toLowerCase())) {
          matchingModels.push(this.allPartnersNModels[key][index]);
        }
      }
    }
    return matchingModels.length ? matchingModels : [keyword]; //if there are matching models, send them, otherwise send the searched keyword
  }

  calculateToAndFromDate(days: number, format: string) {
    let fromDate;
    fromDate = moment(moment(new Date()).utc().format(format)).subtract(days, 'd').utc().format(format);
    return fromDate;
  }
  disableModelsAndVendors(value: string) {
    if (value) {
      this.rnModel = null;
      this.vendor = null;
      this.disableModelSelection = true;
      this.disableVendorSelection = true;
      this.disableSingleModelSelection = true;
    } else {
      this.disableModelSelection = false;
      this.disableVendorSelection = false;
      this.disableSingleModelSelection = false;
    }
  }
  resetFilters() {
    this.disableModelSelection = false;
    this.disableVendorSelection = false;
    this.showStartEndDate = false;
    this.invalidCustomDates = false;
    this.setDefaults();
    this.onRevisionsSelection();
    this.resultsService.setRemoveResultsComponent(true);
  }
    /* preserves order of obj props when applied keyvalue pipe */
    originalOrder = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => {
      const a = akv.value.index;
      const b = bkv.value.index;
      return a > b ? 1 : (b > a ? -1 : 0);
    }

    onSingleModelSelection(){
      if(this.rnModelSingle && this.rnModelSingle != "0"){
        this.disableProductId = true;
      }else {
        this.disableProductId = false;
      }
    }

    filterVendors() {
      if(this.modelSearch) {
        this.modelSearch = '';
      }
      if(this.vendorSearch) {
        let allVendors = Object.keys(this.allPartnersNModels);
        allVendors.sort();
        allVendors.unshift('All');
        let searchResults = allVendors.filter(vendor => vendor.toLowerCase().includes(this.vendorSearch.toLowerCase()))
        this.rnVendors = searchResults;
        if(searchResults.length) {
          this.vendor = [searchResults[0]]
          this.selectedVendorModels(this.vendor, false);
        } else {
          this.rnVendors = [];
          this.vendor = [];
          this.rnModels = [];
          this.rnModel = [];
          //this.selectedVendorModels(this.vendor, false);
        }
      } else {
        this.resetVendors()
      }
    }

    filterModels() {
      if(this.vendor.length == 0) {
        this.rnModels = [];
        this.rnModel = [];
        return;
      }
      if(this.modelSearch) {
        let allModels = this.rnModelsStatic;
        let searchResults = allModels.filter(model => model.toLowerCase().includes(this.modelSearch.toLowerCase()))
        if(searchResults.length) {
          this.rnModels = searchResults;
          this.rnModel = [searchResults[0]]
        } else {
          this.rnModels = [];
          this.rnModel = [];
        }
      } else {
        this.selectedVendorModels(this.vendor, false);
      }
    }

    ngOnDestroy(): void {
      this.destroy$.next();
    }
}
