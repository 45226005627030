export class HDDConfig{
    static prouctLevelLabels: any = {
        "modelName": "Model",
        "deviceType": "Device Type",
        "vendorId": "Vendor Id",
        "hddPartNumber": "Part Number",
        "formFactor": "Form Factor",
        "interfaceSpeed": "Interface Speed (Gbps)",
        "rpm": "RPM",
        "capacity": "Capacity (GB)",
        "firmwareVersion": "Minimum Firmware Version",
        "hddSeries": "Series",
        'hddProductId': "Product Id",
        "footnotes": "Note(s)",
        'driveFormat': "Drive Format",

    }
     static certLevelLabels: any = {
        // "osUse": " OS Use",
        "featureDetails": "Features",
        "firmwareVersion": "Minimum Firmware version",
        "footnotes": "Note(s)",
        "kbInfo": "KB Info",
        "releaseVersion": "Release Version"
     }
     static releaseCompLabels: any = {
        "firmwareVersion": "Firmware Version",
        // "features": "Features"
    }
}