import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from './services/app.service';
import { Observable } from 'rxjs';
import { Programs } from './programs/programs';
import { ErrorHandlerService } from './services/error-handler.service';
import { AuthenticationService } from './services/authentication.service'
import { User } from './data-models/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


  program$: Observable<string>;
  programs: any;
  program: string;
  errorMessage: any;
  showErrorPopup: boolean;
  currentUser: User;
  constructor(private router: Router, private appService: AppService, private errorHandler: ErrorHandlerService, private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if(!x) {
        this.authenticationService.setFeedBackUser(null);
      } else {
        this.authenticationService.setFeedBackUser(x.email);
      }
    });
  }
  ngOnInit() {
    this.programs = Programs.programs;
    this.program$ = this.appService.getProgramSelected();

    this.errorHandler.getMessage().subscribe(
      (error) => {
        console.log("in receiver");
        if (error && error.errorText) {
          this.errorMessage = error.errorText;
          this.showErrorPopup = true;
        }
      }
    )
  }

  reloadApp() {
    location.reload();
  }

}
