import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-release-comp',
  templateUrl: './release-comp.component.html',
  styleUrls: ['./release-comp.component.scss']
})
export class ReleaseCompComponent implements OnInit, OnChanges {

  @Input() openRevComparisonModal: boolean;
  @Input() releasesForComp: any;
  @Input() productDetails: { productName: any; partnerName: any; vcgLink: any; };
  @Output() closeRevModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() releaseCompLabels: any;
  constructor() { }

  ngOnInit() {
  }
  ngOnChanges() {
  }
  checkForModifyExceptionCases(valueToCompare: any): boolean {
    let exceptions = [null, "", 'NA', 'N/A', '0', undefined, "null", "undefined"];
    if (typeof valueToCompare == 'string' && valueToCompare.length == 0) {
      return true;
    }
    return exceptions.includes(valueToCompare);
  }
  checkForDataEmptynies(value) {
    if (this.checkForModifyExceptionCases(value)) {
      return 'N/A'
    } else {
      return value
    }
  }
  closeModal() {
    this.closeRevModal.emit(false);
  }
  /* preserves order of obj props when applied keyvalue pipe */
  originalOrder = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => {
    const a = akv.value.index;
    const b = bkv.value.index;
    return a > b ? 1 : (b > a ? -1 : 0);
  }
}
