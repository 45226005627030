export const environment = {
    production: false,
    apiEndpoint: "https://vns-subscriptions-dev.esp-staging.eng.vmware.com/vsan-hcl-version-control/v1/audit/",
    otpEndpoint: "https://vns-subscriptions-dev.esp-staging.eng.vmware.com/vsan-hcl-version-control/v1/audit/",
    apiAuthEndpoint: "https://vns-subscriptions-dev.esp-staging.eng.vmware.com/vsan-hcl-version-control/auth/v1/audit/",
    otpAuthEndpoint: "https://vns-subscriptions-dev.esp-staging.eng.vmware.com/vsan-hcl-version-control/auth/v1/audit/",
    VCG_BASE_LINK: "http://pewebstage-sjc31.eng.vmware.com/comp_guide2",
    revisionCntrlUrl: "https://vns-subscriptions-dev.esp-staging.eng.vmware.com/audit-detail",
    subscriptionEndpoint: "https://vns-subscriptions-dev.esp-staging.eng.vmware.com/subscriptionapi/v1/otp/vsan/notification/product/",
    subscriptionAuthEndpoint: "https://vns-subscriptions-dev.esp-staging.eng.vmware.com/subscriptionapi/v1/vsan/notification/",
    subscriptionAuthv2Endpoint: "https://vns-subscriptions-dev.esp-staging.eng.vmware.com/subscriptionapi/v2/vsan/notification/",
    trackingId: '',
    clientId: 'esp-decc-stg-779-ussvc',
    environmentType: 'dev'
  };

  // "http://vcgtest-sjc31.eng.vmware.com/comp_guide2",
  