<form clrForm clrLayout="vertical" #emailForm="ngForm" (ngSubmit)="sendEmailOTP(emailForm.value)" id="emailForm">
    <clr-modal [(clrModalOpen)]="openSubscriptionModal" [clrModalClosable]="true" *ngIf="productDetails" (clrModalOpenChange)="closeModal()">
        <h3 class="modal-title" style="font-size: 0.8rem;">Subscription</h3>
        <div class="modal-body">
            <div>
                <clr-alert *ngIf="emailFormAlert.message" [clrAlertType]="emailFormAlert.type" [clrAlertClosable]="false">
                    <clr-alert-item>
                        <span class="alert-text">
                            {{ emailFormAlert.message }}
                        </span>
                    </clr-alert-item>
                </clr-alert>
                <clr-input-container>
                    <label for="useremail" class="clr-control-label">Email Address</label>
                    <input clrInput required pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" type="email" [(ngModel)]="userEmail" name="useremail" placeholder="Enter your email address" #useremail="ngModel" />
                    <clr-control-error *ngIf="useremail.touched && useremail.errors?.required else emailInvalid">Please enter an email address</clr-control-error>
                    <ng-template #emailInvalid>
                        <clr-control-error>Please enter a valid email address</clr-control-error>
                    </ng-template>
                </clr-input-container>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline"  (click)="closeModal()">Cancel</button>
            <button type="submit" class="btn btn-primary" [disabled]="!emailForm.valid">Subscribe</button>
        </div>
    </clr-modal>
</form>

<form clrForm clrLayout="vertical" #otpForm="ngForm" (ngSubmit)="subscribeToProduct(otpForm.value)" id="otpForm">
    <clr-modal [clrModalOpen]="openOTPModal" [clrModalClosable]="true" (clrModalOpenChange)="clsTPModal()">
        <h3 class="modal-title" style="font-size: 0.8rem;">Subscription</h3>
        <div class="modal-body">
            <div>
                <clr-alert *ngIf="otpFormAlert.message" [clrAlertType]="otpFormAlert.type" [clrAlertClosable]="false">
                    <clr-alert-item>
                        <span class="alert-text">
                            {{ otpFormAlert.message }}
                        </span>
                    </clr-alert-item>
                </clr-alert>
                <clr-input-container>
                    <label for="OTP" class="clr-control-label">One Time Passcode</label>
                    <input clrInput required type="text" [(ngModel)]="otp" name="OTP" #OTP="ngModel"
                    pattern="^(\d{6})" />
                    <clr-control-error *ngIf="OTP.touched && OTP.errors?.required else otpInvalid">Please enter One Time Passcode</clr-control-error>
                    <ng-template #otpInvalid>
                        <clr-control-error>Please enter valid One Time Passcode</clr-control-error>
                    </ng-template>
                </clr-input-container>
            </div>
        </div>
        <div class="modal-footer" style="justify-content: normal;">
            <div class="clr-row" style="width: 100%;">
                <div class="clr-col-2 clr-align-self-center">
                    <div id="timer">
                        <span class="minutes"></span>
                        <span>:</span>
                        <span class="seconds"></span>
                    </div>
                </div>
                <div class="clr-col-10 clr-align-self-center" style="text-align: right;">
                    <!-- <button type="button" class="btn btn-outline"  (click)="clsTPModal()">Cancel</button> -->
                    <span style="margin-right: 5px; margin-top: 5px;">
                        <a *ngIf="!otpExpired" href="javascript:void(0);" (click)="resendOTP()">Regenerate One Time Passcode</a>
                        <a *ngIf="otpExpired" class="disabled" href="javascript:void(0);">Regenerate One Time Passcode</a>
                    </span>
                    <button type="submit" class="btn btn-primary" [disabled]="!otpForm.valid || otpExpired">Submit</button>
                </div>
            </div>
        </div>
    </clr-modal>
</form>

<clr-modal [(clrModalOpen)]="openSubscribedModal" [clrModalClosable]="true" >
    <h3 class="modal-title" style="font-size: 0.8rem;">Subscription</h3>
    <div class="modal-body" style="text-align: center;">
        <div><clr-icon shape="success-standard" size="36" class="is-success is-solid"></clr-icon></div>
        <div style="font-size: 21px;">You have successfully subscribed to the product(s).</div>
        <div style="font-size: 12px;">You will be receving notification for any updates on the subscribed product(s).</div>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="openAlreadySubscribedModal" [clrModalClosable]="true" >
    <h3 class="modal-title" style="font-size: 0.8rem;">Subscription</h3>
    <div class="modal-body" style="text-align: center;">
        <div style="font-size: 21px;">You have already subscribed to the product(s).</div>
        <div style="font-size: 12px;">You will be receving notification for any updates on the subscribed product(s).</div>
    </div>
</clr-modal>

<clr-modal ([clrModalOpen])="openSubscribedErrorModal" [clrModalClosable]="true" >
    <h3 class="modal-title" style="font-size: 0.8rem;">Subscription</h3>
    <div class="modal-body" style="text-align: center;">
    <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <clr-alert-item>
            <span class="alert-text">
                Something went wrong. Please try again.
            </span>
        </clr-alert-item>
    </clr-alert>
    </div>
</clr-modal>