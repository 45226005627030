import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownToggleDirective {

  constructor(private _elementRef: ElementRef) { }
  @Output() public appDropdown = new EventEmitter();
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement){
    console.log("onClick", targetElement.id)
    if(targetElement.id !== 'dropdown'){
      console.log('dropdown')
      return ;
    }
    const isCLickableInside = this._elementRef.nativeElement.contains(targetElement);
    if(!isCLickableInside){
      this.appDropdown.emit(null);
    }
  }
}