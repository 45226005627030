export class VSANRNConfig{
    static prouctLevelLabels: any = {
        "model": "Model",
        "rnGeneration": "Generation",
        "profile": "Profile",
        "type": "Type",
        // "vcgLink": "VCG Link",
        "footnotes": "Note(s)",
        "rnComponents": "vSAN ReadyNode Components"
    }
     static certLevelLabels: any = {
        "kbInfo": "KB Info",
        "footnotes": "Note(s)",
        "featureDetails": "Features",
        "releaseVersion": "Release Version"
     }
    static componentLabels: any = {
        "system": "System",
        "sku": "SKU",
        "esxiPreInstalled": "ESXi Pre-Installed?",
        "cpu": "CPU",
        "memory": "Memory",
        "cachingTier": "Caching Tier",
        "capacityTier": "Capacity Tier",
        "controller": "Controller",
        "nic": "NIC",
        "nics": "NIC",
        "bootDevice": "Boot Device",
        "chassis": "Chassis",
        "networking": "Networking",
        "controllerCache": "Controller Cache",
        "storage": "Storage"
    }

    static componentLabelsvSANeSA: any = {
        "system": "System",
        "cpu": "CPU",
        "memory": "Memory",
        "storageTier": "Storage",
        "nic": "NIC",
        "nics": "NIC",
        "bootDevice": "Boot Device"
    }

    static releaseCompLabels: any = {
        // "cachingTier": {
        //     "model": "Model",
        //     "partnerName": "Partner Name",
        //     "deviceType": "Device Type",
        //     "capacity": "Capacity",
        //     "performanceClass": "Performance Class",
        //     "tbwEnduranceClass": "tbwEnduranceClass"
        // },
        // "capacityTier": {
        //     "model": "Model",
        //     "partnerName": "Partner Name",
        //     "deviceType": "Device Type",
        //     "capacity": "Capacity"
        // },
        // "controller": {
        //     "model": "Model",
        // }
        "cachingTier": "Caching Tier",
        "capacityTier": "Capacity Tier",
        "controller": "Controller"
    }
}