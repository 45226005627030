import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private appService: AppService) { }

  ngOnInit(): void {
  }

  showFilters(type: string) {
    this.setDefaultProgram(type);
    this.router.navigateByUrl('/audit-detail');
  }

  setDefaultProgram(type: string) {
    if (type === 'vsanEsa') {
      this.appService.setProgramSelected('vsanrn'); // vsanrn ESA
      this.appService.vsanType.next(type);
    } else {
      this.appService.setProgramSelected('vsanrn'); // vsanrn OSA
      this.appService.vsanType.next(type);
    }
  }
}
