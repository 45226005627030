export class FilterPayload{
    model: string[];
    partner: string[];
    productId?: string | string[];
    days?: number;
    revisionCount?: string;
    fromDate?: string;
    toDate?: string;
    number?: number;
    size?: number;
    program?: string;
    isVSAN2?: boolean;
}