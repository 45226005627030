import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Programs } from 'src/app/programs/programs';
import { User } from 'src/app/data-models/user';
import { takeUntil, take } from 'rxjs/operators';

@Component({
  selector: "app-audit",
  templateUrl: "./audit.component.html",
  styleUrls: ["./audit.component.scss"],
})
export class AuditComponent implements OnInit, OnDestroy {
  programSelected: String;
  selectedProgramName: String;
  programs: any;
  currentUser: User;
  @ViewChild("auditCompRef") auditCompRef: ElementRef;
  vsanTypeSelected: string;
  goToType: string;
  destroy$ = new Subject();
  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.appService.hidePrograms = false;
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
  }
  ngOnDestroy(): void {
    this.appService.hidePrograms = true;
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    this.appService.vsanType.pipe(takeUntil(this.destroy$)).subscribe((typeSelected) => {
      this.vsanTypeSelected = Programs.vSANTypes[typeSelected];

      this.getSelectedProgramType(typeSelected);
    });
    this.programs = Programs.programs;
    combineLatest(
      [this.appService.getProgramSelected(),
      this.appService.getProductsSelected(),
      this.appService.getProductsRevisionCount(),
      this.appService.getProductsFromDate(),
      this.appService.getProductsToDate(),
      ]).pipe(
        take(1),
      ).subscribe((resp) => {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            program: resp[0],
            productId: resp[1],
            revisionCount: resp[2],
            fromDate: resp[3],
            toDate: resp[4],
            vsanType: this.appService.vsanType.getValue()
          },
          queryParamsHandling: 'merge'
        });
      });
  }

  goTop() {
    this.auditCompRef.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  getSelectedProgramType(vsanType: string) {
    this.appService.getProgramSelected().pipe(takeUntil(this.destroy$)).subscribe(program => {
      let selectedProgram = program;
      this.programSelected = program;
      if (program === 'vsanrn') {
        if (vsanType === 'vsan') {
          selectedProgram = 'vsanrnOSA';
        } else if (vsanType === 'vsanEsa') {
          selectedProgram = 'vsanrnESA';
        }
      }
      this.selectedProgramName = Programs.programs[selectedProgram];
    })
  }
}
