<clr-modal [(clrModalOpen)]="openRevComparisonModal" [clrModalSize]="'xl'" [clrModalClosable]="false">
    <h3 class="modal-title" style="font-size: 0.8rem;">
        <div class="clr-row">
            <div class="clr-col-sm-12 clr-col-md-7 clr-col-lg-7">
                <div style="text-align: left; font-weight: 900;">
                    Release Comparison Chart - <span style="font-size: 13px; font-style: italic; font-weight: 500; color: hsl(198, 69%, 69%);">(Baseline set to 6.7U3)</span>
                </div>
            </div>
            <div class="clr-col-sm-12 clr-col-md-5 clr-col-lg-5">
                <div class="audit-status">
                    <div class=""><span class="badge badge-light-blue"></span>Baseline</div>
                    <div class=""><span class="badge badge-success"></span>Newly Added</div>
                    <div class=""><span class="badge badge-warning"></span>Modified</div>
                    <div class=""><span class="badge badge-danger"></span>Removed</div>
                </div>
            </div>
        </div>
        <div class="clr-row">
            <div class=" clr-col-12">
                <div class="product-model" *ngIf="productDetails">
                    <span> {{productDetails.partnerName}}: </span>
                    <span class="p-l-1rem" [showLinks]="productDetails.vcgLink" [linkText]="productDetails.productName">
                        {{productDetails.productName}}</span>
                </div>
            </div>
        </div>
    </h3>
    <div class="modal-body" *ngIf="releasesForComp && releasesForComp.length > 0; else showNoResults">
        <ng-container *ngFor="let releaseVer of releasesForComp; let i = index">
            <ng-container>
                <div style="margin-bottom: 1rem;">
                    <div class="clr-row" style="margin-right: unset; margin-left: unset;">
                        <div class="clr-col-12 date-header">
                            {{releaseVer.createdDate | date : 'y-MM-dd h:mm:ss a' }}
                        </div>
                    </div>
                    <ng-container *ngIf="releaseVer['releases'] && releaseVer['releases'].length > 0; else noRevForTheDate">
                        <div id="relComp">
                            <table class="table table-large" style="margin-top: 0.5rem;">
                                <thead>
                                    <tr>
                                        <th class="">Releases </th>
                                        <ng-container *ngFor="let release of releaseVer['releases']">
                                            <ng-container *ngFor="let cert of release['certs']; let h = index">
                                                <th *ngIf="h == 0" class="" [attr.colspan]="release['certs'].length"
                                                    style="min-width: 16rem !important; border-right: 2px solid #a8a8a8;">{{release['releaseVersion']}} <br><i>{{release['base']? "(" + release['base'] + ")": ''}}</i></th>
                                            </ng-container>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let label of releaseCompLabels | keyvalue: originalOrder">
                                        <tr *ngIf="label['key'] != 'driverDetails' && label['key'] != 'tier'">
                                            <td class="left label-bold">{{label['value']}}</td>
                                            <ng-container *ngFor="let release of releaseVer['releases']">
                                                <td class="left" *ngFor="let cert of release['certs']; let td = index" [ngClass]="{
                                                    'created': (cert['baseStatus'] == 'C') && releasesForComp[i]['status'] != 'D',
                                                    'added': (cert['status'] == 'A') && releasesForComp[i]['status'] != 'D',
                                                    'delete': (cert['status'] == 'D') || releasesForComp[i]['status'] == 'D',
                                                    'border-td': (td == (release['certs'].length - 1))
                                                }">
                                                    <div class="display-flex">
                                                        <ng-container *ngIf="releasesForComp[i]['status'] != 'D'; else deleted">
                                                            <div style="text-align: left; padding: 0.3rem; min-width: 7rem !important;
                                                            width: 10rem;" appStatusParser>
                                                            {{checkForDataEmptynies(cert[label['key']])}}
                                                        </div>
                                                        </ng-container>
                                                        <ng-template  #deleted>
                                                            <div style="text-align: left; padding: 0.3rem; min-width: 7rem !important;
                                                            width: 10rem;">
                                                            {{checkForDataEmptynies(cert[label['key']])}}
                                                        </div>
                                                        </ng-template>
                                                    </div>
                                                </td>
                                            </ng-container>
                                        </tr>
                                        <tr *ngIf="label['key'] == 'driverDetails'">
                                            <td class="left label-bold">{{label['value']}}</td>
                                            <ng-container *ngFor="let release of releaseVer['releases']">
                                                <td class="left" *ngFor="let cert of release['certs'];let td = index" [ngClass]="{
                                                    'created': (cert['baseStatus'] == 'C') && releasesForComp[i]['status'] != 'D',
                                                    'added': (cert['status'] == 'A') && releasesForComp[i]['status'] != 'D',
                                                    'delete': (cert['status'] == 'D') || releasesForComp[i]['status'] == 'D',
                                                    'modified-fw': (cert['driverDetailsStatus'] == 'M') && releasesForComp[i]['status'] != 'D',
                                                    'border-td': (td == (release['certs'].length - 1))
                                                }">
                                                    <div class="display-flex">
                                                        <div style="text-align: left; padding: 0.3rem;  min-width: 7rem !important;
                                                            width: 10rem;" >
                                                            <ng-container *ngIf="releasesForComp[i]['status'] != 'D'; else deleted">
                                                                <div appStatusParser style="font-weight: unset;">{{checkForDataEmptynies(cert[label['key']]['driverName'])}} </div>
                                                                <div appStatusParser>{{checkForDataEmptynies(cert[label['key']]['driverVersion'])}}</div>    
                                                            </ng-container>
                                                            <ng-template #deleted>
                                                                <div style="font-weight: unset;">{{checkForDataEmptynies(cert[label['key']]['driverName'])}} </div>
                                                                <div>{{checkForDataEmptynies(cert[label['key']]['driverVersion'])}}</div>    
                                                            </ng-template>
                                                            </div>
                                                    </div>
                                                </td>
                                            </ng-container>
                                        </tr>
                                        <tr *ngIf="label['key'] == 'tier'">
                                            <td class="left label-bold">{{label['value']}}</td>
                                            <ng-container *ngFor="let release of releaseVer['releases']">
                                                <td class="left" *ngFor="let cert of release['certs'];let td = index" [ngClass]="{
                                                    'created': (cert['baseStatus'] == 'C') && releasesForComp[i]['status'] != 'D',
                                                    'added': (cert['status'] == 'A') && releasesForComp[i]['status'] != 'D',
                                                    'delete': (cert['status'] == 'D') || releasesForComp[i]['status'] == 'D',
                                                    'modified-fw': (cert['tierStatus'] == 'M') && releasesForComp[i]['status'] != 'D',
                                                    'border-td': (td == (release['certs'].length - 1))
                                                }" >
                                                    <ng-container *ngFor="let tier of cert['tier']; let t = index">
                                                        <div style="text-align: left; padding: 0.3rem;min-width: 7rem !important;
                                                                width: 10rem;" >
                                                                <ng-container *ngIf="releasesForComp[i]['status'] != 'D'; else deleted">
                                                                    <div appStatusParser>{{checkForDataEmptynies(tier['tier'])}}</div> 
                                                                </ng-container>
                                                           <ng-template #deleted>
                                                            <div>{{checkForDataEmptynies(tier['tier'])}}</div>
                                                           </ng-template> 
                                                        </div>
                                                    </ng-container>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
    </div>
</clr-modal>

<ng-template #noRevForTheDate>
    <div class="no-results">
        <strong>No Releases available for the Date to show Comparison</strong>
    </div>
</ng-template>

<ng-template #showNoResults>
    <div class="no-results">
        <strong>No Releases available for the month to show Comparison</strong>
    </div>
</ng-template>