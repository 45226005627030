<clr-modal [(clrModalOpen)]="openRevComparisonModal" [clrModalSize]="'xl'" [clrModalClosable]="false">
    <h3 class="modal-title" style="font-size: 0.8rem;">
        <div class="clr-row">
            <div class="clr-col-sm-12 clr-col-md-7 clr-col-lg-7">
                <div style="text-align: left; font-weight: 900;">
                    Release Comparison Chart - <span style="font-size: 13px; font-style: italic; font-weight: 500; color: hsl(198, 69%, 69%);">(Baseline set to 6.7U3)</span>
                </div>
            </div>

            <div class="clr-col-sm-12 clr-col-md-5 clr-col-lg-5">
                <div class="audit-status">
                    <div class=""><span class="badge badge-light-blue"></span>Baseline</div>
                    <div class=""><span class="badge badge-success"></span>Newly Added</div>
                    <div class=""><span class="badge badge-warning"></span>Modified</div>
                    <div class=""><span class="badge badge-danger"></span>Removed</div>
                </div>
            </div>
        </div>
        <div class="clr-row">
            <div class=" clr-col-12">
                <div class="product-model" *ngIf="productDetails">
                    <span> {{productDetails.partnerName}}: </span>
                    <span class="p-l-1rem" [showLinks]="productDetails.vcgLink" [linkText]="productDetails.productName">
                        {{productDetails.productName}}</span>
                </div>
            </div>
        </div>
    </h3>
    <div class="modal-body" *ngIf="releasesForComp && releasesForComp['releases'] && releasesForComp['releases'].length > 0; else showNoResults">
        <div id="relComp">
            <table class="table table-large" style="margin-top: 0.5rem;">
                <thead>
                    <tr>
                        <th class="">Releases </th>
                        <ng-container *ngFor="let release of releasesForComp['releases']">
                            <th style="min-width: 16rem !important; border-right: 2px solid #a8a8a8;">
                                {{release['releaseVersion']}} <br><i>{{release['base']? "(" + release['base'] + ")": ''}}</i>
                            </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let label of releaseCompLabels | keyvalue: originalOrder">
                        <tr>
                            <td class="left label-bold" style="min-width: 6rem !important;">
                                {{label['value']}}
                            </td>
                            <td *ngFor="let release of releasesForComp['releases']" class="left border-td" id="components"
                            [ngClass]="{
                                'created': (release['baseStatus'] == 'C') && (releasesForComp['status'] != 'D'),
                                'delete': (releasesForComp['status'] == 'D')
                            }"
                            >
                                <div class="comps"><span style="font-weight: 500;">Model:
                                    </span>
                                    <span [showLinks]=""
                                    [linkText]="release['rnComponents'][label['key']]['model']">

                                    <span
                                        appStatusParser>{{release['rnComponents'][label['key']]['model']}}</span></span></div>
                                <div class="comps" *ngIf="release['rnComponents'][label['key']]['partnerName']"><span style="font-weight: 500;">
                                        Partner Name:
                                    </span><span
                                        appStatusParser>{{release['rnComponents'][label['key']]['partnerName']}}</span>
                                </div>
                                <div class="comps" *ngIf="release['rnComponents'][label['key']]['deviceType']"><span style="font-weight: 500;">
                                        Device Type:
                                    </span><span
                                        appStatusParser>{{release['rnComponents'][label['key']]['deviceType']}}</span>
                                </div>
                                <div class="comps" *ngIf="release['rnComponents'][label['key']]['capacity']"><span style="font-weight: 500;">
                                        Capacity (GB):
                                    </span><span
                                        appStatusParser>{{release['rnComponents'][label['key']]['capacity']}}</span>
                                </div>
                                <div class="comps" *ngIf="release['rnComponents'][label['key']]['performanceClass'] && release['rnComponents'][label['key']]['performanceClass'] != 'N/A' && release['rnComponents'][label['key']]['performanceClass'] != ''">
                                    <span style="font-weight: 500;"> Performance Class:
                                    </span><span
                                        appStatusParser>{{release['rnComponents'][label['key']]['performanceClass']}}</span>
                                </div>
                                <div class="comps" *ngIf="release['rnComponents'][label['key']]['tbwEnduranceClass'] && release['rnComponents'][label['key']]['tbwEnduranceClass'] != 'N/A' && release['rnComponents'][label['key']]['tbwEnduranceClass'] != ''">
                                    <span style="font-weight: 500;">TBW Endurance Class:
                                    </span><span
                                        appStatusParser>{{release['rnComponents'][label['key']]['tbwEnduranceClass']}}</span>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
    </div>
</clr-modal>

<ng-template #showNoResults>
    <div class="no-results">
        <strong>No Release available for the month to show Comparison</strong>
    </div>
</ng-template>