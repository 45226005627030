<div class="main-container">
  <header class="header header-6" app-header></header>
  <div class="content-container">
    <div class="content-area">
      <router-outlet></router-outlet>
    </div>
  </div>
    <div class="footer-main">
      <app-footer></app-footer>
  </div>
</div>

<clr-modal [(clrModalOpen)]="showErrorPopup" [clrModalClosable]="true">
  <div class="modal-body">
    <p>{{errorMessage | titlecase}}. Please Try Again</p>
  </div>
</clr-modal>
