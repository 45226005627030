import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { VSANIOConfig } from 'src/app/programs/vsanio.config';
import { KeyValue, DatePipe } from '@angular/common';
import { SSDConfig } from 'src/app/programs/ssd.config';
import { HDDConfig } from 'src/app/programs/hdd.config';
// import { NetworkConfig } from 'src/app/programs/network.config';
import { FilterPayload } from 'src/app/data-models/filter-payload';
import { ResultsService } from '../../services/results.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { SubscriptionService } from '../../services/subscription.service';
import { SubscriptionPayload } from '../../../../data-models/subscription-payload'
import { AuthenticationService } from 'src/app/services/authentication.service'

const PROGRAM_SSD = 'ssd';
const PROGRAM_VSANIO = 'vsanio';
const PROGRAM_NETWORK = 'network';

@Component({
  selector: 'app-vsanio-hdd-results',
  templateUrl: './vsanio-hdd-results.component.html',
  styleUrls: ['./vsanio-hdd-results.component.scss'],
  providers: [DatePipe]
})
export class VsanioHddResultsComponent implements OnInit, OnChanges {

  @Input() groupedAuditedProducts: any[];
  @Input() supportedProductsPeriodObj: any;
  @Input() programSelected: string;
  @Input() payload: FilterPayload;
  @Input() loadingResults: boolean;
  @Output() page: EventEmitter<{ index: number, page: number, fromDate: string, toDate: string }> = new EventEmitter<{ index: number, page: number, fromDate: string, toDate: string }>();
  pageNumReq: number = 1;
  productLabelsConfig: any;
  supportReleaseLabelsConfig: any;
  openRevComparisonModal: boolean;
  openSubscriptionModal: boolean;
  productIdSelForComp: any;
  releasesForComp: any;
  productDetails: { productName: any; partnerName: any; vcgLink: any; productId: any};
  releaseCompLabels: any;
  expanded: Array<boolean> = [false, false, false, false, false, false, false, false, false, false, false, false];
  relExpanded: Array<boolean> = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
  productReleaseExpanded: Array<boolean> = [false, false, false, false, false, false, false, false, false, false];
  p: number[] = [];

  openSubscribedModal: boolean;
  openAlreadySubscribedModal: boolean;
  openSubscribedErrorModal: boolean = true;
  subscribing: boolean = false;

  constructor(private datePipe: DatePipe, private resultsService: ResultsService, private errorHandler: ErrorHandlerService,private subscriptionService: SubscriptionService, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.loadingResults = true;
  }

  ngOnChanges() {
    if (this.programSelected == PROGRAM_SSD) {
      this.productLabelsConfig = SSDConfig.prouctLevelLabels;
      this.supportReleaseLabelsConfig = SSDConfig.certLevelLabels;
      this.releaseCompLabels = SSDConfig.releaseCompLabels;
    } else if (this.programSelected == PROGRAM_VSANIO) {
      this.productLabelsConfig = VSANIOConfig.prouctLevelLabels;
      this.supportReleaseLabelsConfig = VSANIOConfig.certLevelLabels;
      this.releaseCompLabels = VSANIOConfig.releaseCompLabels;
    } else if(this.programSelected == PROGRAM_NETWORK) {
      this.productLabelsConfig = VSANIOConfig.prouctLevelLabels;
      this.supportReleaseLabelsConfig = VSANIOConfig.certLevelLabels;
      this.releaseCompLabels = VSANIOConfig.releaseCompLabels;
    }else {
      this.productLabelsConfig = HDDConfig.prouctLevelLabels;
      this.supportReleaseLabelsConfig = HDDConfig.certLevelLabels;
      this.releaseCompLabels = HDDConfig.releaseCompLabels;
    }
    if (this.groupedAuditedProducts && this.groupedAuditedProducts.length > 0) {
      this.groupedAuditedProducts.forEach(groupProducts => {
        // console.log(groupProducts, "groupProducts");
        for (let key in groupProducts.auditRecord) {
          if (groupProducts.auditRecord[key] && groupProducts.auditRecord[key].length > 0) {
            groupProducts.auditRecord[key].sort((a, b) => new Date(b.createdDateTs).getTime() - new Date(a.createdDateTs).getTime());
          }
        }
      })
    }
    this.productReleaseExpanded= [false, false, false, false, false, false, false, false, false, false];
  }

  /* preserves order of obj props when applied keyvalue pipe */
  originalOrder = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => {
    const a = akv.value.index;
    const b = bkv.value.index;
    return a > b ? 1 : (b > a ? -1 : 0);
  }

  getPage(page, i, fromDate, toDate) {
    console.log(page, "page")
    this.pageNumReq = page;
    this.page.emit({
      index: i,
      page: page,
      fromDate: this.datePipe.transform(fromDate, 'y-MM-dd'),
      toDate: this.datePipe.transform(toDate, 'y-MM-dd')
    });
  }

  checkForModifyExceptionCases(valueToCompare: any): boolean {
    let exceptions = [null, "", 'NA', 'N/A', '0', undefined, "null", "undefined"];
    if (typeof valueToCompare == 'string' && valueToCompare.length == 0) {
      return true;
    }
    return exceptions.includes(valueToCompare);
  }
  checkForDataEmptynies(value) {
    if (this.checkForModifyExceptionCases(value)) {
      return 'N/A'
    } else {
      return value
    }
  }
  productChanged(auditedProducts, pageNumReq = 1) {
    let productsChanged: number = 0;
    if (auditedProducts.auditRecord && Object.keys(auditedProducts.auditRecord).length > 0) {
      for (let key in auditedProducts.auditRecord) {
        if (auditedProducts.auditRecord[key][0]['status'] && auditedProducts.auditRecord[key][0]['status'] != 'C') {
          productsChanged += 1;
        }
      }
      // return `${productsChanged} Product(s) changed`
      return `${productsChanged} Product(s) changed out of ${(pageNumReq * this.payload.size) - this.payload.size + 1} - ${(pageNumReq * this.payload.size)} product(s) in Page ${pageNumReq}`
    } else {
      return 'Product(s) revisions are not available for the period'
    }
  }
  certsChanged(cert) {
    if (cert && Object.keys(cert).length > 0) {
      for (let key in cert) {
        if (cert[key] && cert[key][0] && cert[key][0]['status'] && cert[key][0]['status'] != 'C') {
          return 'M';
        }
      }
    }
  }
  openRevCompModal(productId, productName, partnerName, vcgLink, frmDate, toDate) {
    let productDetails = {
      productName: productName,
      partnerName: partnerName,
      vcgLink: vcgLink,
      productId: productId
    }
    let fromDate = this.datePipe.transform(frmDate, 'y-MM-dd');
    let tDate = this.datePipe.transform(toDate, 'y-MM-dd')
    this.resultsService.getReleasesForComp(fromDate, tDate, productId).subscribe(releasesData => {
      this.productDetails = productDetails;
      this.releasesForComp = releasesData;
      if(this.releasesForComp && this.releasesForComp.length > 0){
        this.releasesForComp.forEach(releaseRev => {
          if(releaseRev['releases'] && releaseRev['releases'].length > 0){
            releaseRev['releases'][releaseRev['releases'].length - 1]["base"] = "Baseline for comparison";
            releaseRev['releases'][releaseRev['releases'].length - 1]['certs'].forEach(release => {
              if(!release['status']){
                release['baseStatus'] = "C";
              }
            });
          }
        });
      }
      this.driverDetailDiff(this.releasesForComp);
      this.tierDetailsDiff(this.releasesForComp);
      this.openRevComparisonModal = true;
    }, (error) => {
      if (error.message) {
        error.message = `Failed to fetch Release Comparison Data`;
      }
      this.errorHandler.sendMessage(error);
    })
  }
  tierDetailsDiff(releasesForComp: any) {
    releasesForComp.forEach(period => {
      period.releases.forEach(release => {
        release.certs.forEach(cert => {
          if (Array.isArray(cert['tier']) && cert['tier'].length > 0) {
            cert['tier'].forEach(tier => {
              if (tier && tier.tier && tier.tier.includes("||M")) {
                cert['tierStatus'] = "M"
              }
            })
          }
        })
      })
    })
  }
  driverDetailDiff(releasesForComp: any) {
    releasesForComp.forEach(period => {
      period.releases.forEach(release => {
        release.certs.forEach(cert => {
          if (cert['driverDetails'] && ((cert['driverDetails']['driverName'] && cert['driverDetails']['driverName'].includes("||M")) ||
            (cert['driverDetails']['driverVersion'] && cert['driverDetails']['driverVersion'].includes("||M")))) {
            cert['driverDetailsStatus'] = "M"
          }
        })
      })
    })
  }
  clsRevModal(event){
    this.openRevComparisonModal = event;
  }

  subscribeToProduct(productId, productName, partnerName) {
    if(this.authenticationService.isActiveUser()) {
      this.subscribeToProductWithId(productId);
    } else {
      this.openSubModal(productId, productName, partnerName);
    }
  }

  subscribeToProductWithId(productId) {
    this.subscribing = true;
    this.openAlreadySubscribedModal = false;
    this.openSubscribedModal = false;
    this.openSubscribedErrorModal = false;
    let subscriptionData = new SubscriptionPayload();
    subscriptionData.productIds = [productId];
    this.subscriptionService.secureSubscribeToProduct(subscriptionData).subscribe(response => {
      if(response[0].status == "success") {
        if(response[0].message.includes('already')) {
					this.openAlreadySubscribedModal = true;
				} else {
					this.openSubscribedModal = true;
				} 
      } else {
        this.openSubscribedErrorModal = true;
      }
      this.subscribing = false;
    }, error => {
      console.log(error)
      this.openSubscribedErrorModal = true;
      this.subscribing = false;
    });
  }
  openSubModal(productId, productName, partnerName) {
    let productDetails = {
      productName: productName,
      partnerName: partnerName,
      vcgLink: null,
      productId: productId
    }
    this.productDetails = productDetails;
    this.openSubscriptionModal = true;
  }

  closeSubModal(event) {
    this.openSubscriptionModal = false;
  }
}
