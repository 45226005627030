import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { FilterPayload } from 'src/app/data-models/filter-payload';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'src/app/services/app.service';
import { Programs } from 'src/app/programs/programs';
import { EnvironmentsService } from 'src/app/services/environment.service';
import { UniquePrograms } from 'src/app/enums/unique-programs';

@Injectable({
  providedIn: 'root'
})
export class ResultsService {

  programs: any;
  programSelected: string;
  private resultsSubject = new Subject<FilterPayload>();
  removeResultsComponent: Subject<boolean>  = new Subject<boolean>();
  constructor(private http: HttpClient, private appService: AppService, private envService: EnvironmentsService) {
    this.programs = Programs.programs;
    this.appService.getProgramSelected().subscribe(program => {
      this.programSelected = program;
    })
  }

  setResultsSubject(value: FilterPayload) {
    this.resultsSubject.next(value);
  }

  getResultsSubject(): Observable<FilterPayload> {
    return this.resultsSubject.asObservable();
  }

  //not using anymore
  getResults(payload: FilterPayload): Observable<any> {
    return this.http.get<any>(this.envService.config.apiEndpoint + `product/search?program=${this.programSelected}&productId=${payload.productId}&partner=${payload.partner}&model=${payload.model}&revisionCount=${payload.revisionCount}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&number=${payload.number}&size=${payload.size}`);
  }
  getResultsPerPage(payload: FilterPayload): Observable<any>{
    let url = `${this.envService.config.apiEndpoint}product/search/page?program=${this.programSelected}&productId=${payload.productId}&partner=${payload.partner}&model=${payload.model}&revisionCount=${payload.revisionCount}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&number=${payload.number}&size=${payload.size}`;
    if(this.appService.vsanType.getValue()  == 'vsanEsa' && Object.values(UniquePrograms).includes(this.programSelected.toLowerCase())) {
      url = `${this.envService.config.apiEndpoint}product/search/page?program=${this.programSelected}&productId=${payload.productId}&partner=${payload.partner}&model=${payload.model}&revisionCount=${payload.revisionCount}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&number=${payload.number}&size=${payload.size}&isVSAN2=${true}`;
    }
    return this.http.get<any>(url);
  }
  getReultsWithPostAction(payload: FilterPayload, isVSAN2?: boolean): Observable<any>{
    payload.program = this.programSelected;
    if(isVSAN2 && Object.values(UniquePrograms).includes(this.programSelected.toLowerCase())) {
      payload.isVSAN2 = isVSAN2;
    } else {
      delete payload.isVSAN2;
    }
    return this.http.post<any>(`${this.envService.config.apiEndpoint}product/search`, payload);
  }
  getReleasesForComp(fromDate, toDate, productId){
      return this.http.get<any>(this.envService.config.apiEndpoint + `product/release/program/${this.programSelected}?productId=${productId}&fromDate=${fromDate}&toDate=${toDate}`);
  }
  getRemoveResultsComponent(): Observable<boolean>{
    return this.removeResultsComponent.asObservable();
  }
  setRemoveResultsComponent(value: boolean){
    this.removeResultsComponent.next(value);
  }
  // getSSDHDDProductIds(): Observable<any>{
  //   return this.http.get(environment.apiEndpoint + `product/productids`);
  // }
}