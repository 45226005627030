export const environment = {
  production: true,
  apiEndpoint: "https://vns-subscriptions.esp.vmware.com/vsan-hcl-version-control/v1/audit/",
  otpEndpoint: "https://vns-subscriptions.esp.vmware.com/vsan-hcl-version-control/v1/audit/",
  apiAuthEndpoint: "https://vns-subscriptions.esp.vmware.com/vsan-hcl-version-control/auth/v1/audit/",
  otpAuthEndpoint: "https://vns-subscriptions.esp.vmware.com/vsan-hcl-version-control/auth/v1/audit/",
  VCG_BASE_LINK: "http://vmware.com/resources/compatibility",
  revisionCntrlUrl: "https://vns-subscriptions.esp.vmware.com/audit-detail",
  subscriptionEndpoint: "https://vns-subscriptions.esp.vmware.com/subscriptionapi/v1/otp/vsan/notification/product/",
  subscriptionAuthEndpoint: "https://vns-subscriptions.esp.vmware.com/subscriptionapi/v1/vsan/notification/",
  subscriptionAuthv2Endpoint: "https://vns-subscriptions.esp.vmware.com/subscriptionapi/v2/vsan/notification/",
  trackingId: '',
  clientId: 'esp-prod-334-xjcno',
  environmentType: 'prod'
};
