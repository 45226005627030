import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';

import { ManageSubscriptionsRoutingModule } from './manage-subscriptions-routing.module';
import { UnsubscriptionComponent } from './components/unsubscription/unsubscription.component';


@NgModule({
  declarations: [UnsubscriptionComponent],
  imports: [
    CommonModule,
    ManageSubscriptionsRoutingModule,
    ClarityModule
  ]
})
export class ManageSubscriptionsModule { }
