import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  appLabels: any;
  copyRightText: string;

  constructor(private router: Router) {
    this.copyRightText = `${this.getCopyrightText()}. All Rights Reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries.`;
   }

  ngOnInit(): void {
  }

  getCopyrightText() {
    return `Copyright © 2005-${new Date().getFullYear()} Broadcom`
  }

}
