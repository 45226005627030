import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuditRoutingModule } from './audit-routing.module';
import { AuditComponent } from './audit.component';
import { FiltersComponent } from './components/filters/filters.component';
import { FormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { FilterResultsComponent } from './components/filter-results/filter-results.component';
import { DateSortPipe } from 'src/app/pipes/date-sort.pipe';
import { ResultsComponent } from './components/results/results.component';
import { StatusParserDirective } from 'src/app/directives/status-parser.directive';
import { NgxPaginationModule } from 'ngx-pagination';
import { VsanrnResultsComponent } from './components/vsanrn-results/vsanrn-results.component';
import { VsanioHddResultsComponent } from './components/vsanio-hdd-results/vsanio-hdd-results.component';
import { ShowLinksDirective } from 'src/app/directives/show-links.directive';
import { HandleLongStringsPipe } from 'src/app/pipes/handle-long-strings.pipe';
import { ReleaseCompComponent } from './components/release-comp/release-comp.component';
import { RnReleaseCompComponent } from './components/rn-release-comp/rn-release-comp.component';
import { SubscribeComponent } from './components/subscribe/subscribe.component';

@NgModule({
  declarations: [
    AuditComponent, 
    FiltersComponent, 
    FilterResultsComponent, 
    DateSortPipe, 
    ResultsComponent, 
    StatusParserDirective, VsanrnResultsComponent, VsanioHddResultsComponent,
    ShowLinksDirective,
    HandleLongStringsPipe,
    ReleaseCompComponent,
    RnReleaseCompComponent,
    SubscribeComponent,

  ],
  imports: [
    CommonModule,
    AuditRoutingModule,
    FormsModule,
    ClarityModule,
    NgxPaginationModule

  ]
})
export class AuditModule { }
