<div class="container">
    <div class="clr-row background-img">
        <div class="clr-col">
            <h3 class="m-2">vSAN VCG Notification Services</h3>
            <div>
                <p>vSAN OSA and vSAN ESA will help you to define the features of this products.</p>
            </div>
        </div>
        <div class="clr-col">
            <img src="./assets/img/Video_image.svg" alt="vsan illustrative image" class="video-img-position">
        </div>
    </div>
    <div>
        <div class="text-align-center">
            <h3>Select vSAN Services</h3>
            <div>
                <p>There are two kinds of services, please select what you need</p>
            </div>
        </div>
        <div class="cards-holders">
            <div class="clr-row clr-justify-content-center">
                <div class="clr-col-3">
                    <a class="card clickable display-flex clr-justify-content-start clr-align-items-center" (click)="showFilters('vsan')">
                        <div class="card-img">
                            <img src="./assets/img/vsan-product-ico-rgb_16px.svg" alt="vsan img" class="img-width"/>
                        </div>
                        <div class="card-block">
                            <p class="card-text">
                                vSAN OSA
                            </p>
                        </div>
                    </a>
                </div>
                <div class="clr-col-3">
                    <a class="card clickable display-flex clr-justify-content-start clr-align-items-center" (click)="showFilters('vsanEsa')">
                        <div class="card-img">
                            <img src="./assets/img/vsan-product-ico-rgb_16px.svg" alt="vsan esa img" class="img-width" />
                        </div>
                        <div class="card-block">
                            <div class="card-text">
                                <div>
                                    vSAN ESA
                                </div>
                                <div>

                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>