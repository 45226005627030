<nav class="subnav">
    <div class="nav">
        <div>
            <strong>{{this.selectedProgramName}}</strong>
            <span *ngIf="programSelected === 'vsanrn'" class="label label-info -padding">{{vsanTypeSelected}}</span>
        </div>
    </div>
</nav>
<div  id="auditComp" #auditCompRef>
    <div class="container">
        <app-filters></app-filters>
        <app-filter-results></app-filter-results>
    </div>
    <a class="btnTop btn btn-primary" title="Back to top" (click)="goTop()">
        <clr-icon shape="angle"></clr-icon>
    </a>
</div>