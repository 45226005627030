export class VSANIOConfig{
    static keyToLabels: any =[
        {
            keyPattern : '$.did',
            label: 'DID'
        },
        {
            keyPattern : '$.vid',
            label: 'VID'
        },
        {
            keyPattern : '$.ssid',
            label: 'SSID'
        },
        {
            keyPattern : '$.vsanTypes',
            label: 'vSAN Types'
        },
        {
            keyPattern: '$.deviceModel',
            label: 'Model'
        },
        {
            keyPattern: '$.partnerDetails.partnerName',
            label: 'Brand Name'
        },
        {
            keyPattern: '$.deviceType',
            label: 'Device Type'
        },
        {
            keyPattern: '$.svid',
            label: 'SVID'
        }
    ];
    static keyToReleaseLabel: any = [
        {
            keyPattern: '$.supportedReleases[*].ReleaseVersion',
            label: 'Release Version'
        }
    ];
    static keyToReleaseLabels: any = [
        // {
        //     keyPattern: '$.supportedReleases[*].featureDetails[*].featureValue',
        //     label : 'Feature Value'
        // },
        {
            keyPattern : '$.supportedReleases[*].osUse',
            label: 'OS use'
        },
        {
            keyPattern : '$.supportedReleases[*].firmwareVersion',
            label: 'Firmware Version'
        },
        {
            keyPattern : '$.supportedReleases[*].deviceDriverType',
            label: 'Driver Type'
        }
    ];
    static keyToFeatureLabels: any = [
        {
            keyPattern : '$.supportedReleases[*].featureDetails',
            label  : 'Feature Details'
        },
        {
            keyPattern : '$.supportedReleases[*].featureDetails[*].feature',
            label  : 'Feature'
        },{
            keyPattern : '$.supportedReleases[*].featureDetails[*].featureValue',
            label  : 'Feature Value'
        },
    ];


    static prouctLevelLabels: any = {
        "deviceModel": "Model",
        "did": "DID",
        "deviceType": "Device Type",
        "svid": "SVID",
        "ssid": "SSID",
        "vid": "VID",
        // "vcgLink": "VCG Link",
        "footnotes": "Note(s)"
    }
     static certLevelLabels: any = {
        "kbInfo": "KB Info",
        "footnotes": "Note(s)",
        "deviceDriverType": "Driver Type",
        "driverDetails": "Driver Details",
        "featureDetails": "Features",
        "firmwareVersion": "Firmware Version",
        "releaseVersion": "Release Version"
     }
     static releaseCompLabels: any = {
         "driverDetails": "Driver Details",
         "firmwareVersion": "Firmware Version",
         "driverType": "Driver Type",
         "vsanType": "vSAN Type",
        //  "queueDepth": "Queue Depth"
     }
}