import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/internal/operators';
import { HttpClient } from '@angular/common/http';
import { vSANIODataModel } from '../data-models/vsanio-data-model';
import { SSDDataModel } from '../data-models/ssd-data-model';

@Injectable({
  providedIn: 'root'
})
export class AuditTestDataService {

  public destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private http: HttpClient) { }
//  // https://vsantools-test-app.vmware.com:8443/audit-service/v1/audit/product/search?program=vsanio&productId=&partner=&model=&days=7&fromDate=&toDate
  getvSANIOAuditData(): Observable<vSANIODataModel[]>{
    return this.http.get<vSANIODataModel[]>('./assets/test-data/ssd_audit_data.json').pipe(
      delay( 1000 ),
      takeUntil(this.destroy$)
    );
  }
}
