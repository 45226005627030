import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResultsService } from '../../services/results.service';
import { AuditTestDataService } from 'src/app/services/audit-test-data.service';
import { vSANIODataModel, DiffPath } from 'src/app/data-models/vsanio-data-model';
import * as jp from 'jsonpath';
import * as moment from 'moment';
import * as is from 'is-js';
import { VSANIOConfig } from 'src/app/programs/vsanio.config';
import { SSDConfig } from 'src/app/programs/ssd.config';
var flatten = require('flat');

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit, OnDestroy {
  // keyReleaseLabels: any = {};
  // keyReleaseLabel: any = {};
  // supportReleaseDiffData: any = [{}];
  // resultsData: any = [{}];
  // showResults: boolean = true;
  // flattenedDiffData: any[] = [];
  // flattenedSupportReleaseDiffData: any[] = [];
  // diffPaths: DiffPath[][] = [];
  // keyLabels: any = {};
  // diffPathCodes: any = {};
  // keyFeatureLabels: any;
  // featureDetailsDiffData: any = [{}];
  constructor(private resultsService: ResultsService, private auditTestDataService: AuditTestDataService) { }

  ngOnInit() {
    // this.resultsService.getResultsSubject().subscribe(payload => {
    //   console.log(payload, "payload from results");
    //   this.showResults = false;
    //   this.flattenedDiffData = [];
    //   this.diffPaths = [];
    //   // this.resultsService.getResults(payload).subscribe(auditData => {
    //   this.auditTestDataService.getvSANIOAuditData().subscribe(auditData => {
    //     this.showResults = true;
    //     console.log(auditData, "auditData");
    //     /* Adds deleted object to response */
    //     this.addDeletedObjectsToResponse(auditData);

    //     this.pushToFlattenedDiffData(auditData);

    //     /* For Release details */
    //     this.pushToFlattenedSupportedReleaseDiffData(auditData);
    //     this.flattenFeatureDetails(auditData);
    //     this.prependSlashtoKeys(this.flattenedDiffData, '/');

    //     /* generating labels from patterns for product*/
    //     this.keyLabels = this.getLabelsFromConfig(auditData, VSANIOConfig.keyToLabels);
    //     this.keyReleaseLabel = this.getLabelsFromConfig(auditData, VSANIOConfig.keyToReleaseLabel);
    //     this.keyReleaseLabels = this.getLabelsFromConfig(auditData, VSANIOConfig.keyToReleaseLabels);
    //     this.keyFeatureLabels = this.getLabelsFromConfig(auditData, VSANIOConfig.keyToFeatureLabels);
    //     // console.log(this.keyReleaseLabel);
    //     // console.log(this.flattenedDiffData);
    //     //for SSD
    //     // this.keyLabels = this.getLabelsFromConfig(auditData, SSDConfig.keyToLabels);
    //     // this.keyReleaseLabels = this.getLabelsFromConfig(auditData, SSDConfig.keyToReleaseLabels);

    //     /* Assigning code for diff paths*/
    //     this.diffPathCodes = {};
    //     for (let i = 0; i < auditData.length; i++) {
    //       const element = auditData[i];
    //       for (let index = 0; index < element.diffPath.length; index++) {
    //         const ele = element.diffPath[index];
    //         this.diffPathCodes[element.data.componentId] = this.diffPathCodes[element.data.componentId] ? this.diffPathCodes[element.data.componentId] : {};
    //         this.diffPathCodes[element.data.componentId][ele.path] = ele.code;
    //       }
    //     }
    //     console.log(this.flattenedDiffData, "flattenedDiffData")
    //   });
    // })
  }
  // addDeletedObjectsToResponse(auditData: vSANIODataModel[]) {
  //   let deletedObject = [];
  //   auditData.forEach(product => {
  //     deletedObject = product.diffPath.filter(path => {
  //       if (path.code === 'D') {
  //         return path;
  //       }
  //     });
  //     deletedObject.forEach(path => {
  //       console.log(path.path, "path");
  //       console.log(this.createObj(product, {}, path.path, path.value));
  //     })
  //   });
  // }
  // createObj(product, obj, path, value = null) {
  //   let givenPath = typeof path === 'string' ? path.split('/') : path;
  //   path = typeof path === 'string' ? path.split('/') : path;
  //   let current = obj;
  //   while (givenPath.length > 1) {
  //     const [head, ...tail] = givenPath;
  //     givenPath = tail;
  //     if (head === "") {
  //       continue;
  //     }
  //     if (is.numeric(head)) {
  //       current[head] = [];
  //     }
  //     else if (current[head] === undefined) {
  //       current[head] = {};
  //     }
  //     current = current[head];
  //   }
  //   current[givenPath[0]] = value;
  //   console.log(obj, "obj");
  //   //path, obj is available, how to insert

  //   for (let key in product.data) {
  //     console.log(key)
  //   }
  //   return obj;
  // }

  // flattenFeatureDetails(auditData: vSANIODataModel[]) {
  //   this.featureDetailsDiffData = [{}];
  //   auditData.forEach((product, i) => {
  //     this.featureDetailsDiffData[i] = this.featureDetailsDiffData[i] ? this.featureDetailsDiffData[i] : {};
  //     this.featureDetailsDiffData[i][product.data.componentId] = this.featureDetailsDiffData[i][product.data.componentId] ? this.featureDetailsDiffData[i][product.data.componentId] : [];
  //     for (let index = 0; index < product.data.supportedReleases.length; index++) {
  //       this.featureDetailsDiffData[i][product.data.componentId][product.data.supportedReleases[index].certId] = this.featureDetailsDiffData[i][product.data.componentId][product.data.supportedReleases[index].certId] ? this.featureDetailsDiffData[i][product.data.componentId][product.data.supportedReleases[index].certId] : [];
  //       for (let j = 0; j < product.data.supportedReleases[index].featureDetails.length; j++) {
  //         this.featureDetailsDiffData[i][product.data.componentId][product.data.supportedReleases[index].certId].push(
  //           this.prependSlashtoKeys(flatten(product.data.supportedReleases[index].featureDetails[j], { delimiter: '/' }), `/supportedReleases/${index}/featureDetails/${j}/`)
  //         )
  //       }
  //     }
  //     console.log(this.featureDetailsDiffData, "this.featureDetailsDiffData");
  //   });
  // }
  // pushToFlattenedSupportedReleaseDiffData(auditData: vSANIODataModel[]) {
  //   this.supportReleaseDiffData = [{}];
  //   auditData.forEach((product, i) => {
  //     this.supportReleaseDiffData[i] = this.supportReleaseDiffData[i] ? this.supportReleaseDiffData[i] : {};
  //     this.supportReleaseDiffData[i][product.data.componentId] = this.supportReleaseDiffData[i][product.data.componentId] ? this.supportReleaseDiffData[i][product.data.componentId] : [];
  //     this.supportReleaseDiffData[i][product.data.componentId] = [];
  //     for (let index = 0; index < product.data.supportedReleases.length; index++) {
  //       this.supportReleaseDiffData[i][product.data.componentId].push(
  //         this.prependSlashtoKeys(flatten(product.data.supportedReleases[index], { delimiter: '/' }), `/supportedReleases/${index}/`)
  //       );
  //     }
  //     console.log(this.supportReleaseDiffData, "this.supportReleaseDiffData");
  //   })
  // }

  // //date range calculation and fetching products based on given date range
  // // groupingProductsOnGivenRange(range: string, auditData: vSANIODataModel[], startDate?, endDate?) {
  // //   let rangeInNumber = Number(range);
  // //   console.log(rangeInNumber, "rangeInNumber")
  // //   switch (rangeInNumber) {
  // //     case 7: {
  // //       let currentDate = moment(new Date()).utc().format("DD MMMM YYYY");
  // //       let pastDate = moment(currentDate).subtract(rangeInNumber, 'd').utc().format("DD MMMM YYYY");
  // //       this.pushToFlattenedDiffData(auditData);
  // //       this.pushToFlattenedSupportedReleaseDiffData(auditData);
  // //       this.prependSlashtoKeys(this.flattenedDiffData, '/');
  // //       this.resultsData = [{
  // //         dateRange: `${pastDate} - ${currentDate}`,
  // //         flattenedDiffData: this.flattenedDiffData
  // //       }];
  // //       console.log(this.resultsData, "resultsData");
  // //       // console.log(pastDate.format(), "pastDate");
  // //     }
  // //       break;
  // //     case 30:
  // //       {
  // //         let currentDate = moment(new Date()).utc().format("DD MMMM YYYY");
  // //       let pastDate = moment(currentDate).subtract(rangeInNumber, 'd').utc().format("DD MMMM YYYY");
  // //       this.pushToFlattenedDiffData(auditData);
  // //       this.pushToFlattenedSupportedReleaseDiffData(auditData);
  // //       this.prependSlashtoKeys(this.flattenedDiffData, '/');
  // //       this.resultsData = [{
  // //         dateRange: `${pastDate} - ${currentDate}`,
  // //         flattenedDiffData: this.flattenedDiffData
  // //       }];
  // //         console.log(this.resultsData, "resultsData");
  // //       }
  // //       break;
  // //     case 90:
  // //       {
  // //         let currentDate;
  // //         let pastDate;
  // //         let productsOfEachMonth = [];
  // //         currentDate = moment(new Date()).utc().format();
  // //         for(let i = 90; i  > 0; i = i-30){
  // //           pastDate = moment(currentDate).subtract(30, 'd').utc().format();
  // //           productsOfEachMonth = [];
  // //           auditData.forEach(product => {
  // //             if(moment(product.createdDate).utc().format() <= currentDate && moment(product.createdDate).utc().format() >= pastDate){
  // //               productsOfEachMonth.push(product);
  // //             }
  // //           });
  // //           console.log(productsOfEachMonth, "productsOfEachMonth")
  // //           this.pushToFlattenedDiffData(productsOfEachMonth);
  // //           this.pushToFlattenedSupportedReleaseDiffData(productsOfEachMonth);
  // //           this.prependSlashtoKeys(this.flattenedDiffData, '/');
  // //           this.resultsData.push({
  // //             dateRange: `${pastDate} - ${currentDate}`,
  // //         flattenedDiffData: this.flattenedDiffData
  // //           })
  // //           currentDate = pastDate;
  // //         }
  // //       }
  // //       console.log(this.resultsData, "resultsData");
  // //       break;
  // //     case 180:
  // //       {
  // //         let currentDate = moment(new Date()).utc().format();
  // //         let pastDate = moment(currentDate).subtract(rangeInNumber, 'd');

  // //         console.log(currentDate, "currentDate");
  // //         console.log(pastDate.format(), "pastDate");
  // //       }
  // //       break;
  // //     case 365:
  // //       {
  // //         let currentDate = moment(new Date()).utc().format();
  // //         let pastDate = moment(currentDate).subtract(rangeInNumber, 'd');

  // //         console.log(currentDate, "currentDate");
  // //         console.log(pastDate.format(), "pastDate");
  // //       }
  // //       break;
  // //     default:
  // //       {
  // //         let fromDate = moment(startDate).utc().format();
  // //         let toDate = moment(endDate).utc().format();

  // //         console.log(fromDate, "from Date");
  // //         console.log(toDate, "Todate");
  // //       }
  // //       break;
  // //   }
  // // }
  
  // getLabelsFromConfig(auditData, config) {
  //   let keyLabels: any = {};
  //   for (let i = 0; i < auditData.length; i++) {
  //     const ele = auditData[i];
  //     for (let index = 0; index < config.length; index++) {
  //       const element = config[index];
  //       let labelsArr = jp.paths(ele.data, element.keyPattern);
  //       for (let j = 0; j < labelsArr.length; j++) {
  //         const ele1 = labelsArr[j];
  //         keyLabels[ele1.join("/").replace("$", "")] = element.label;
  //       }
  //     }
  //     return keyLabels;
  //   }
  // }
  // getColorCode(data, index, key) {
  //   if (data != undefined && data[index] != undefined && data[index][key] != undefined) {
  //     console.log(data[index][key]);
  //     return data[index][key];
  //   }
  //   return "";
  // }
  // pushToFlattenedDiffData(auditData: vSANIODataModel[]) {
  //   auditData.forEach(ele => {
  //     this.flattenedDiffData.push(flatten(ele.data, { delimiter: '/' }
  //     ));
  //   });
  // }
  // prependSlashtoKeys(auditData: any, stringToAppend) {
  //   if (Array.isArray(auditData)) {
  //     auditData.map(ele => {
  //       for (let prop in ele) {
  //         ele[stringToAppend + prop] = ele[prop];
  //         delete ele[prop];
  //       }
  //     });
  //   } else {
  //     for (const key in auditData) {
  //       if (auditData.hasOwnProperty(key)) {
  //         auditData[stringToAppend + key] = auditData[key];
  //         delete auditData[key]
  //       }
  //     }
  //     return auditData;
  //   }
  // }
  // getReleaseName(dataTuples, i) {
  //   return dataTuples[`/supportedReleases/${i}/ReleaseVersion`];
  // }
  ngOnDestroy() {
    this.auditTestDataService.destroy$.next(true);
    this.auditTestDataService.destroy$.unsubscribe();
  }
}
