import { Directive, ElementRef, Renderer2, OnInit, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appStatusParser]'
})
export class StatusParserDirective implements OnInit, AfterViewInit {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }
  ngOnInit() {
  }
  ngAfterViewInit() {
    let value;
    value = this.elementRef.nativeElement ? this.elementRef.nativeElement.innerText ? this.elementRef.nativeElement.innerText.split("||") : '' : '';
    if (value && value.length && value.length > 1) {
      if (value[value.length - 1].trim() == "M") {
        this.renderer.addClass(
          this.elementRef.nativeElement,
          'modified');
      } else if (value[value.length - 1].trim() == "A") {
        this.renderer.addClass(
          this.elementRef.nativeElement,
          'added');
      } else if (value[value.length - 1].trim() == "D") {
        this.renderer.addClass(
          this.elementRef.nativeElement,
          'delete');
      }
      let computedValue = this.checkForDataEmptynies(value[0].trim());
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerText', computedValue);
    }
  }

  checkForModifyExceptionCases(valueToCompare: any): boolean {
    let exceptions = [null, "", 'NA', 'N/A', '0', undefined, "null", "undefined"];
    if (typeof valueToCompare == 'string' && valueToCompare.length == 0) {
      return true;
    }
    return exceptions.includes(valueToCompare);
  }
  checkForDataEmptynies(value) {
    if (this.checkForModifyExceptionCases(value)) {
      return 'N/A'
    } else {
      return value
    }
  }
}
