import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
//import { NgForm, Validators } from '@angular/forms';
import { NgForm, Validators } from '@angular/forms';
import { KeyValue } from '@angular/common';
import { SubscriptionService } from '../../services/subscription.service';
import { SubscriptionPayload } from '../../../../data-models/subscription-payload'
import { Observable, timer, NEVER, BehaviorSubject, fromEvent, of } from 'rxjs';
import { map, tap, takeWhile, share, startWith, switchMap, filter } from 'rxjs/operators';
import { AlertType, AlertMessage } from '../../../../helpers/alert.type';

@Component({
	selector: 'subscribe',
	templateUrl: './subscribe.component.html',
	styleUrls: ['./subscribe.component.scss']
})

export class SubscribeComponent implements OnInit, OnChanges {

	@Input() openSubscriptionModal: boolean;
	@Input() name: string;
	@Input() productDetails: { productName: any; partnerName: any; productId: any, vcgLink: any };
	@Output() closeSubscriptionModal: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() closeOTPModal: EventEmitter<boolean> = new EventEmitter<boolean>();
	@ViewChild('emailForm') public emailForm: NgForm;
	@ViewChild('otpForm') public otpForm: NgForm;
	userEmail: string;
	openOTPModal: boolean = false;
	@Input() openSubscribedModal: boolean = false;
	@Input() openAlreadySubscribedModal: boolean = false;
	@Input() openSubscribedErrorModal: boolean = false;
	otpEmail: string;
	otp: number;
	emailFormAlert: AlertMessage = {
		message: '',
		type: AlertType.INFO
	};
	otpFormAlert: AlertMessage = {
		message: '',
		type: AlertType.INFO
	}
	defaultTimeInMinutes: Number = 10;
	otpExpired: boolean = false;
	defaultErrorMessage = "Identified some issue with network. Please try again after sometime.";
	toggle$ = new BehaviorSubject(true);

	constructor(private subscriptionService: SubscriptionService) {

	}

	ngOnInit() {
		
	}

	ngOnChanges() {
		this.otpExpired = false;
	}

	closeModal() {
		if(this.userEmail) {
			this.otpEmail = this.userEmail;
		}
		this.emailForm.resetForm();
		this.userEmail = '';
		this.openSubscriptionModal = false;
		this.resetAlertMessage('email');
		this.closeSubscriptionModal.emit(false);
	}

	opnOTPModal() {
		this.closeModal()
		this.openOTPModal = true;
	}

	clsTPModal() {
		this.otpForm.resetForm();
		this.otp = null;
		this.openOTPModal = false;
		this.resetAlertMessage('otp');
	}

	sendEmailOTP(emailFormValue) {
		let email = emailFormValue.useremail;
		this.resetAlertMessage('email');
		this.subscriptionService.sendSubscriptionOTP(email).subscribe(response => {
			if(response.status == 'success') {
				this.otpFormAlert = {
					message: response.message,
					type: AlertType.SUCCESS
				};
				this.opnOTPModal();
				setTimeout(()=>{    
					this.countDown(this.defaultTimeInMinutes);
			   	}, 200);
			} else {
				this.emailFormAlert = {
					message: response.message,
					type: AlertType.DANGER
				};
			}
		}, error => {
			this.emailFormAlert = {
				message: this.defaultErrorMessage,
				type: AlertType.DANGER
			};
		});
	}

	resendOTP() {
		let email = this.otpEmail;
		this.resetAlertMessage('otp');
		this.subscriptionService.sendSubscriptionOTP(email).subscribe(response => {
			if(response.status == 'success') {
				setTimeout(()=>{    
					this.countDown(this.defaultTimeInMinutes);
			   	}, 200);
				this.otpFormAlert = {
					message: response.message,
					type: AlertType.SUCCESS
				};
			} else {
				this.otpFormAlert = {
					message: response.message,
					type: AlertType.DANGER
				};
			}
		}, error => {
			this.otpFormAlert = {
				message: this.defaultErrorMessage,
				type: AlertType.DANGER
			};
		});
	}

	subscribeToProduct(otpFormValue) {
		let subscriptionData = new SubscriptionPayload();
		subscriptionData.userEmail = this.otpEmail;
		subscriptionData.otpNumber = otpFormValue.OTP;
		subscriptionData.productIds = [this.productDetails.productId];
		this.subscriptionService.subscribeToProduct(subscriptionData).subscribe(response => {
			if(response.status == "success") {
				this.clsTPModal();
				if(response.message.includes('already')) {
					this.openAlreadySubscribedModal = true;
				} else {
					this.openSubscribedModal = true;
				}
			} else {
				this.otpFormAlert = {
					message: response.message,
					type: AlertType.DANGER 
				}
			}
		}, error => {
			this.otpFormAlert = {
				message: this.defaultErrorMessage,
				type: AlertType.DANGER
			};
		});
	}

	resetAlertMessage(form?: string) {
		if (form == 'email') {
			this.emailFormAlert = {
				message: '',
				type: AlertType.INFO
			};
		} else if(form == 'otp') {
			this.otpFormAlert = {
				message: '',
				type: AlertType.INFO
			};
		} else {
			this.emailFormAlert = {
				message: '',
				type: AlertType.INFO
			};
			this.otpFormAlert = {
				message: '',
				type: AlertType.INFO
			};
		}
	}

	countDown(minute) {
		
		if(this.toggle$.value) {
			this.toggle$.next(!this.toggle$.value);
		}

		this.toggle$ = new BehaviorSubject(true);
		const toggle$ = this.toggle$;

		const K = 1000;
		const INTERVAL = K;
		const TIME = minute * K * 60;

		let current: number;
		let time = TIME;

		const toMinutesDisplay = (ms: number) => Math.floor(ms / K / 60);
		const toSecondsDisplay = (ms: number) => Math.floor(ms / K) % 60;

		const toSecondsDisplayString = (ms: number) => {
			const seconds = toSecondsDisplay(ms);
			return seconds < 10 ? `0${seconds}` : seconds.toString();
		};

		const toMinutesDisplayString = (ms: number) => {
			const minutes = toMinutesDisplay(ms);
			return minutes < 10 ? `0${minutes}` : minutes.toString();
		};

		const currentSeconds = () => time / INTERVAL;
		const toMs = (t: number) => t * INTERVAL
		const toRemainingSeconds = (t: number) => currentSeconds() - t;

		const remainingSeconds$ = toggle$.pipe(
			switchMap((running: boolean) => (running ? timer(0, INTERVAL) : NEVER)),
			map(toRemainingSeconds),
			takeWhile(t => t >= 0),
		);

		const ms$ = remainingSeconds$.pipe(
			map(toMs),
			tap(t => current = t)
		);

		const minutes$ = ms$.pipe(
			map(toMinutesDisplayString),
			map(s => s.toString()),
			startWith(toMinutesDisplayString(time).toString())
		);

		const seconds$ = ms$.pipe(
			map(toSecondsDisplayString),
			startWith(toSecondsDisplayString(time).toString())
		);

		// update DOM
		const minutesElement = document.querySelector('#timer .minutes');
		const secondsElement = document.querySelector('#timer .seconds');
		const toggleElement = document.querySelector('#timer');

		this.updateDom(minutes$, minutesElement);
		this.updateDom(seconds$, secondsElement);

		remainingSeconds$.subscribe({
			complete: () => {
				this.otpFormAlert = {
					message: 'One Time Passcode has expired. Please try to subscribe again.',
					type: AlertType.DANGER
				};
				this.otpExpired = true;
				//this.updateDom(of('00:00'), toggleElement)
			}
		});

	}

	updateDom(source$: Observable<string>, element: Element) {
		source$.subscribe((value) => element.innerHTML = value);
	}

}
