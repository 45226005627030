import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageSubscriptionsService } from '../../manage-subscriptions.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'unsubscription',
  templateUrl: './unsubscription.component.html',
  styleUrls: ['./unsubscription.component.scss']
})
export class UnsubscriptionComponent implements OnInit {

  responseForUnscb: any;
  unsubModal: boolean;
  constructor(private route: ActivatedRoute, private mngSub: ManageSubscriptionsService, private errorHandler: ErrorHandlerService, private router: Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParam => {
      console.log(queryParam, "queryParam");
      if (queryParam && queryParam.requestPayload) {
        this.mngSub.getPartners(queryParam.requestPayload).subscribe(response => {
          this.unsubModal = true;
          this.responseForUnscb = response;
          console.log(this.responseForUnscb, "this.responseForUnscb");
        }, error => {
          this.errorHandler.sendMessage(error);
        })
      }
    });
  }
  redirectToAuditPage(){
    this.unsubModal = false;
    this.router.navigate(['/audit-detail'])
  }
}
