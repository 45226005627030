<clr-modal [(clrModalOpen)]="unsubModal" [clrModalClosable]="false">
    <h3 class="modal-title">
        <clr-alert [clrAlertType]="'success'" [clrAlertClosable]="false">
            <clr-alert-item>
                <span class="alert-text">
                    {{responseForUnscb.message}}
                </span>
            </clr-alert-item>
        </clr-alert>
    </h3>
    <!-- <div class="modal-body">
        <p>{{responseForUnscb}}</p>
    </div> -->
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-primary" (click)="redirectToAuditPage()">
            close
        </button>
    </div>
</clr-modal>