<ng-container *ngFor="let auditedProducts of groupedAuditedProducts; let i = index;">
    <div *ngIf="(auditedProducts['auditRecord'] | json) != '{}';">
        <clr-accordion [clrAccordionMultiPanel]="false">
            <clr-accordion-panel [(clrAccordionPanelOpen)]="expanded[i]" id="panel_{{i}}">
                <clr-accordion-title>
                    {{auditedProducts['dateRange'].fromDate | date : 'd MMMM y'}} -
                    {{auditedProducts['dateRange'].toDate | date : 'd MMMM y'}}
                    <!-- <span style="padding-left: 0.3rem; font-style: italic;">{{productChanged(auditedProducts, p[i])}}</span> -->
                </clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                    <div *ngIf="loadingResults; else spinner">
                    <ng-container
                        *ngFor="let groupedProduts of auditedProducts['auditRecord'] | keyvalue | paginate: { id: i, itemsPerPage: 10, currentPage: p[i], totalItems: auditedProducts['totalElements'] }; let gp = index">
                        <div class="prod-release-accordian">
                            <div class="product-model">
                                <span class="p-l-1rem">
                                    {{groupedProduts['value'][0]['partnerName']}}: </span>
                                <span [showLinks]="groupedProduts['value'][0]['data']['vcgLink']"
                                [linkText]="groupedProduts['value'][0]['model']"> {{groupedProduts['value'][0]['model']}}</span>
                                <div class="revision-comp-chart">
                                    <span style="margin-right: 10px;">
                                        <a (click)="subscribeToProduct(groupedProduts['key'], groupedProduts['value'][0]['model'], groupedProduts['value'][0]['partnerName'])">Subscribe</a>
                                    </span>
                                    <span>
                                    <a
                                        (click)="openRevCompModal(groupedProduts['key'], groupedProduts['value'][0]['model'], groupedProduts['value'][0]['partnerName'], groupedProduts['value'][0]['data']['vcgLink'], auditedProducts['dateRange'].fromDate, auditedProducts['dateRange'].toDate)">Release
                                        Comparison Chart</a>
                                        <clr-tooltip>
                                            <clr-icon clrTooltipTrigger shape="info-circle" size="24" aria-label="revision tooltip"></clr-icon>
                                            <clr-tooltip-content clrPosition="top-left" clrSize="md" *clrIfOpen>
                                                <span>Product's Release Comparison chart for the month</span>
                                            </clr-tooltip-content>
                                        </clr-tooltip>
                                    </span>
                                </div>
                            </div>
                            <clr-accordion *ngIf="groupedProduts['value'][0]['prodChanges'] != 'No Changes'">
                                <clr-accordion-panel>
                                    <clr-accordion-title>
                                        Product Revision <span style="padding-left: 0.3rem; font-style: italic;">{{groupedProduts['value'][0]['prodChanges'] ? "- "+ groupedProduts['value'][0]['prodChanges'] : '' }}</span>
                                    </clr-accordion-title>
                                    <clr-accordion-content>
                                        <table class="table table-large"
                                            >
                                            <thead>
                                                <tr>
                                                    <th class="left">Timeline</th>
                                                    <ng-container
                                                        *ngFor="let product of groupedProduts.value | dateSort">
                                                        <th class="left">
                                                            {{product['createdDateTs'] | date : 'y-MM-dd h:mm:ss a' }}
                                                        </th>
                                                    </ng-container>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container
                                                    *ngFor="let label of productLabelsConfig | keyvalue: originalOrder">
                                                    <tr *ngIf="label['key'] != 'footnotes'">
                                                        <td class="left label-bold">
                                                            {{label['value']}}
                                                        </td>
                                                        <td class="left"
                                                            *ngFor="let product of groupedProduts.value | dateSort"
                                                            appStatusParser
                                                            [class.created]="product && product['status'] == 'C'"
                                                            [class.delete]="product && product['status'] == 'D'"
                                                            [class.added]="product && product['status'] == 'A'">
                                                            <span [showLinks]="product['data'][label['key']]"
                                                                [linkText]="'VCG_BASE_LINK'">{{checkForDataEmptynies(product['data'][label['key']])}}</span>
                                                        </td>
                                                    </tr>
                                                    <ng-container *ngIf="label['key'] == 'footnotes'">
                                                        <tr>
                                                            <td class="left label-bold">
                                                                {{label['value']}}</td>
                                                            <td class="left"
                                                                *ngFor="let product of groupedProduts.value | dateSort"
                                                                [ngClass]="{
                                                                    'created': (product && product['status'] == 'C'),
                                                                    'modified': product['data'] && product['data']['footnotesStatus'] == 'M',
                                                                    'added': (product && product['status'] == 'A') || product['data'] && product['data']['footnotesStatus'] == 'A',
                                                                    'delete': (product && product['status'] == 'D') || product['data'] && product['data']['footnotesStatus'] == 'D'
                                                                }">
                                                                <ng-container *ngIf="product['data'][label['key']]; else prodfootNoteNA">
                                                                <ng-container
                                                                    *ngFor="let footnote of product['data'][label['key']];">
                                                                    <div style="white-space: pre-line;">
                                                                        {{checkForDataEmptynies(footnote.footnote)  | handleLongStrings: 70}}
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-template #prodfootNoteNA>
                                                                N/A
                                                            </ng-template>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </clr-accordion-content>
                                </clr-accordion-panel>
                            </clr-accordion>
                            <clr-accordion *ngIf="groupedProduts['value'][0]['certChanges'] != 'No Changes'">
                                <clr-accordion-panel>
                                    <clr-accordion-title>
                                        Product Release Revision <span style="padding-left: 0.3rem; font-style: italic;">{{groupedProduts['value'][0]['certChanges'] ? "- "+ groupedProduts['value'][0]['certChanges'] : '' }}</span>
                                    </clr-accordion-title>
                                    <clr-accordion-content>
                                        <div
                                            *ngIf="(supportedProductsPeriodObj[i][groupedProduts.key] | json) != '{}'; else noReleaseDetails">
                                            <div style="text-align: end; padding: 0.2em;">
                                                <a href="javascript:void(0);" (click)="productReleaseExpanded[gp] = !productReleaseExpanded[gp]">{{ productReleaseExpanded[gp] ? 'Collapse': 'Expand'}} All</a>
                                            </div>
                                            <ng-container
                                                *ngFor="let release of supportedProductsPeriodObj[i][groupedProduts.key] | keyvalue : originalOrder;let j = index">
                                                <clr-accordion>
                                                    <clr-accordion-panel [clrAccordionPanelOpen]="productReleaseExpanded[gp]">
                                                        <clr-accordion-title appStatusParser
                                                        [ngClass]="{
                                                            'created': (release['value'] && release['value'][0]['status'] == 'C'),
                                                            'delete': (release['value'] && release['value'][0]['status']  == 'D'),
                                                            'added': (release['value'] && release['value'][0]['status']  == 'A'),
                                                            'modified': (release['value'] && release['value'][0]['status']  == 'M')
                                                        }"
                                                        >
                                                            {{release['value'][0]['releaseVersion']}}
                                                        </clr-accordion-title>
                                                        <clr-accordion-content>
                                                            <table class="table table-large"
                                                                >
                                                                <thead>
                                                                    <tr>
                                                                        <th class="left">
                                                                            Timeline
                                                                        </th>
                                                                        <ng-container
                                                                            *ngFor="let certId of release['value'] | dateSort ;">
                                                                            <th class="left">
                                                                                {{certId['createdDateTs'] | date : 'y-MM-dd h:mm:ss a' }}
                                                                            </th>
                                                                        </ng-container>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <ng-container
                                                                        *ngFor="let label of supportReleaseLabelsConfig | keyvalue: originalOrder">
                                                                        <tr
                                                                            *ngIf="label['key'] != 'footnotes' && label['key'] != 'kbInfo' && label['key'] != 'featureDetails' && label['key'] != 'driverDetails' && label['key'] != 'tierDetails'">
                                                                            <td class="left label-bold">
                                                                                {{label['value']}}
                                                                            </td>
                                                                            <ng-container
                                                                                *ngFor="let certId of release['value']  | dateSort;">
                                                                                <td class="left " appStatusParser
                                                                                    [class.created] = "certId && certId['status'] == 'C'"
                                                                                    [class.delete]="certId && certId['status'] == 'D'"
                                                                                    [class.added]="certId && certId['status'] == 'A'">
                                                                                    {{checkForDataEmptynies(certId[label['key']])}}
                                                                                </td>
                                                                            </ng-container>
                                                                        </tr>
                                                                        <ng-container
                                                                            *ngIf="label['key'] == 'footnotes'">
                                                                            <tr>
                                                                                <td class="left label-bold">
                                                                                    {{label['value']}}</td>
                                                                                <td class="left"
                                                                                    *ngFor="let certId of release['value']  | dateSort; let f = index;"
                                                                                    [ngClass]="{
                                                                                    'created': certId && certId['status'] == 'C',
                                                                                    'modified': release['value'][f] && release['value'][f]['footnotesStatus'] == 'M',
                                                                                    'added': (certId && certId['status'] == 'A') || (release['value'][f] && release['value'][f]['footnotesStatus'] == 'A'),
                                                                                    'delete': (certId && certId['status'] == 'D') || (release['value'][f] && release['value'][f]['footnotesStatus'] == 'D')
                                                                                }">
                                                                                    <ng-container *ngIf="certId[label['key']]; else footNoteNA">
                                                                                        <ng-container
                                                                                            *ngFor="let footnote of certId[label['key']]">
                                                                                            <div
                                                                                                style="white-space: pre-line;">
                                                                                                {{checkForDataEmptynies(footnote.footnote) | handleLongStrings: 70}}
                                                                                            </div>
                                                                                        </ng-container>
                                                                                    </ng-container>
                                                                                    <ng-template #footNoteNA>
                                                                                        N/A
                                                                                    </ng-template>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="label['key'] == 'kbInfo'">
                                                                            <tr>
                                                                                <td class="left label-bold">
                                                                                    {{label['value']}}</td>
                                                                                <td class="left"
                                                                                    *ngFor="let certId of release['value']  | dateSort; let kb = index;"
                                                                                    [ngClass]="{
                                                                                    'created': certId && certId['status'] == 'C',
                                                                                    'modified': certId && certId['kbInfoStatus']  && certId['kbInfoStatus'] == 'M',
                                                                                    'added': (certId && certId['status'] == 'A') || (certId && certId['kbInfoStatus']  && certId['kbInfoStatus'] == 'A'),
                                                                                    'delete': (certId && certId['status'] == 'D')|| (certId && certId['kbInfoStatus']  && certId['kbInfoStatus'] == 'D')
                                                                                }">
                                                                                <ng-container *ngIf="certId[label['key']]; else knInfoNA">
                                                                                    <ng-container
                                                                                        *ngFor="let kbInfo of certId[label['key']]">
                                                                                        {{checkForDataEmptynies(kbInfo.kbURL)}}
                                                                                        <br>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                                <ng-template #knInfoNA>
                                                                                    N/A
                                                                                </ng-template>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="programSelected != 'hdd'">
                                                                            <ng-container
                                                                                *ngIf="label['key'] == 'driverDetails'">
                                                                                <tr>
                                                                                    <td class="left label-bold">
                                                                                        {{label['value']}}</td>
                                                                                    <td class="left"
                                                                                        *ngFor="let certId of release['value']  | dateSort; let dd = index"
                                                                                        [ngClass]="{
                                                                                        'created': certId && certId['status'] == 'C',
                                                                                    'modified': certId && certId['driverDetailsStatus'] && certId['driverDetailsStatus'] == 'M',
                                                                                    'added': (certId && certId['status'] == 'A') || (certId && certId['driverDetailsStatus'] && certId['driverDetailsStatus'] == 'A'),
                                                                                    'delete': (certId && certId['status'] == 'D') || (certId && certId['driverDetailsStatus'] && certId['driverDetailsStatus'] == 'D')
                                                                                }">
                                                                                        <ng-container>
                                                                                            <div>
                                                                                                <span
                                                                                                    style="font-weight: 500;">Driver
                                                                                                    Name: </span>
                                                                                                {{ checkForDataEmptynies(certId[label['key']]['driverName'])}}<br>
                                                                                                <span
                                                                                                    style="font-weight: 500;">Driver
                                                                                                    Version:
                                                                                                </span>{{checkForDataEmptynies(certId[label['key']]['driverVersion'])}}<br>
                                                                                                <!-- <div style="white-space: pre-line;"
                                                                                                    [showLinks]="certId[label['key']]['driverDownloadLink']"
                                                                                                    [linkText]="'Driver Download Link'">
                                                                                                    {{certId[label['key']]['driverDownloadLink']  | handleLongStrings: 70 }}
                                                                                                </div> -->
                                                                                            </div>
                                                                                        </ng-container>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="label['key'] == 'featureDetails'">
                                                                            <tr>
                                                                                <td class="left label-bold">
                                                                                    {{label['value']}}</td>
                                                                                <td class="left leftFDetails"
                                                                                    *ngFor="let certId of release['value']  | dateSort; let fe = index; "
                                                                                    appStatusParser [ngClass]="{
                                                                                    'created': certId && certId['status'] == 'C',
                                                                                'modified': certId && certId['featureDetailsStatus'] && certId['featureDetailsStatus'] == 'M',
                                                                                'added': (certId && certId['status'] == 'A') || (certId && certId['featureDetailsStatus'] && certId['featureDetailsStatus'] == 'A'),
                                                                                'delete': (certId && certId['status'] == 'D') || (certId && certId['featureDetailsStatus'] && certId['featureDetailsStatus'] == 'D')
                                                                            }">
                                                                            <ng-container *ngIf="certId[label['key']]; else featureNA">
                                                                                    <ng-container
                                                                                        *ngFor="let featureDetails of certId[label['key']]">
                                                                                        <div class="featureDetail">
                                                                                            <ng-container
                                                                                                *ngFor="let featureTuple of featureDetails | keyvalue">
                                                                                                <span
                                                                                                    style="font-weight: 500; padding-left: 0.3rem;">{{featureTuple.key | titlecase }}:</span>
                                                                                                <span
                                                                                                    style="padding-left: 0.1rem;">{{checkForDataEmptynies(featureTuple.value)}}</span>
                                                                                            </ng-container>
                                                                                        </div>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                                <ng-template #featureNA>
                                                                                    N/A
                                                                                </ng-template>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="label['key'] == 'tierDetails'">
                                                                            <tr>
                                                                                <td class="left label-bold">
                                                                                    {{label['value']}}</td>
                                                                                <td class="left leftFDetails"
                                                                                    *ngFor="let certId of release['value']  | dateSort; let te = index; "
                                                                                    appStatusParser [ngClass]="{
                                                                                    'created': certId && certId['status'] == 'C',
                                                                                'modified': certId && certId['tierDetailsStatus'] && certId['tierDetailsStatus'] == 'M',
                                                                                'added': (certId && certId['status'] == 'A') || (certId && certId['tierDetailsStatus'] && certId['tierDetailsStatus'] == 'A'),
                                                                                'delete': (certId && certId['status'] == 'D') || (certId && certId['tierDetailsStatus'] && certId['tierDetailsStatus'] == 'D')
                                                                            }">
                                                                            <ng-container *ngIf="certId[label['key']]; else tierNA">
                                                                                    <ng-container
                                                                                        *ngFor="let tierDetails of certId[label['key']]">
                                                                                        <div class="featureDetail">
                                                                                            <ng-container
                                                                                                *ngFor="let tierTuple of tierDetails | keyvalue">
                                                                                                <div>
                                                                                                    <span
                                                                                                        style="font-weight: 500; padding-left: 0.3rem;">{{tierTuple.key | titlecase  }}:</span>
                                                                                                    <span
                                                                                                        style="padding-left: 0.1rem;">{{tierTuple.value }}</span>
                                                                                                    <br>
                                                                                                </div>
                                                                                            </ng-container>
                                                                                        </div>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                                <ng-template #tierNA>
                                                                                    N/A
                                                                                </ng-template>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </tbody>
                                                            </table>
                                                        </clr-accordion-content>
                                                    </clr-accordion-panel>
                                                </clr-accordion>
                                            </ng-container>
                                        </div>
                                    </clr-accordion-content>
                                </clr-accordion-panel>
                            </clr-accordion>
                        </div>
                    </ng-container>
                </div>
                    <pagination-controls class="product-pagination" [id]="i"
                        (pageChange)="p[i] = $event; getPage($event, i, auditedProducts['dateRange'].fromDate, auditedProducts['dateRange'].toDate)">
                    </pagination-controls>
                </clr-accordion-content>
            </clr-accordion-panel>
        </clr-accordion>
    </div>
</ng-container>

<app-release-comp [openRevComparisonModal]="openRevComparisonModal" [releaseCompLabels]="releaseCompLabels"
    [productDetails]="productDetails" [releasesForComp]="releasesForComp"
    (closeRevModal)="clsRevModal($event)"></app-release-comp>
<subscribe [productDetails]="productDetails" [openSubscriptionModal]="openSubscriptionModal" [openSubscribedModal]="openSubscribedModal" [openAlreadySubscribedModal]="openAlreadySubscribedModal" [openSubscribedErrorModal]="openSubscribedErrorModal" (closeSubscriptionModal)="closeSubModal($event)"></subscribe>

<ng-template #showNoResults>
    <div class="no-results">
        <strong>Product(s) revisions are not available for selected criteria.</strong>
    </div>
</ng-template>

<ng-template #noReleaseDetails>
    <div class="no-results">
        <strong>Baseline release(s) are not found for the product.</strong>
    </div>
</ng-template>

<ng-template #spinner>
    <div class="spinnerBtn">
        <span class="spinner spinner-md">
        </span>
    </div>
</ng-template>