import { Component, OnInit, OnDestroy, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { ResultsService } from '../../services/results.service';
var flatten = require('flat');
import * as is from 'is-js';
import { AppService } from 'src/app/services/app.service';
import { AuthenticationService } from 'src/app/services/authentication.service'
import { Subscription } from 'rxjs/internal/Subscription';
import { FilterPayload } from 'src/app/data-models/filter-payload';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { VsanioHddResultsComponent } from '../vsanio-hdd-results/vsanio-hdd-results.component';
import { VsanrnResultsComponent } from '../vsanrn-results/vsanrn-results.component';
import { User } from 'src/app/data-models/user';
const STATUS_SEPARATOR = '||';

@Component({
  selector: 'app-filter-results',
  templateUrl: './filter-results.component.html',
  styleUrls: ['./filter-results.component.scss'],
})
export class FilterResultsComponent implements OnInit,OnChanges, OnDestroy {
  showResults: boolean;
  groupedAuditedProducts: any[];
  supportedProductsPeriodMap: Map<number, any>;
  supportedProductsPeriodObj: any;
  noResultsToShow: boolean;
  programSelected: string;
  rawAuditData: any;
  subscriptions: Subscription[] = [];
  payload: FilterPayload;
  loadingResults: boolean;

  @ViewChild(VsanrnResultsComponent) vsanRNComp : VsanrnResultsComponent;
  @ViewChild(VsanioHddResultsComponent) vsanHddComp : VsanioHddResultsComponent;
  @ViewChild('resultContainerRef') resultContainer: ElementRef;

  constructor(private resultsService: ResultsService, private appService: AppService, private errorHandler: ErrorHandlerService) { 
    
  }

  ngOnChanges(){
    this.loadingResults = true;
  }
  ngOnInit() {
    this.subscriptions.push(
      this.resultsService.getRemoveResultsComponent().subscribe(remove => {
        if(remove){
          this.groupedAuditedProducts = undefined;
        }
      })
    );
    this.subscriptions.push(this.appService.getProgramSelected().subscribe(program => {
      this.groupedAuditedProducts = undefined;
      this.programSelected = program;
    }));
    this.showResults = true;
    this.fetchResults();
  }
  fetchResults(){
    this.subscriptions.push(this.resultsService.getResultsSubject().subscribe(payload => {
      this.payload = payload;
      this.groupedAuditedProducts = [];
      this.supportedProductsPeriodMap = undefined;
      this.showResults = false;
      this.noResultsToShow = false;
      this.getFilterResults(payload);
    })
    );
  }
  getFilterResults(payload: FilterPayload){
    this.subscriptions.push(this.resultsService.getReultsWithPostAction(payload, this.appService.vsanType.getValue() == 'vsanEsa' ? true:false).subscribe(auditData => {
      this.groupedAuditedProducts = [];
      if(this.vsanRNComp){
        this.vsanRNComp.expanded = [false, false, false, false, false, false, false, false, false, false, false, false];
      }
      if(this.vsanHddComp){
        this.vsanHddComp.expanded = [false, false, false, false, false, false, false, false, false, false, false, false];
      }
      if (auditData && auditData.length) {
        for(let i = 0; i < auditData.length; i++){
          if(auditData[i].page.totalElements > 0){
            this.noResultsToShow = false;
            break;
          } else{
            this.noResultsToShow = true;
          }
        }
      } else {
        this.noResultsToShow = true;
      }
      auditData.sort((pre, next) => pre.order > next.order ? 1 : -1);
      if (this.programSelected != 'vsanrn') {
        auditData.forEach(product => {
          this.groupedAuditedProducts.push(this.addDiffAndGroupProducts(product.page.content, product.dateRange, product.page.totalElements, product.page.totalPages));
        });
        this.designObjectForSupportedReleases(this.groupedAuditedProducts);
      } else {
        this.rawAuditData = auditData;
      }
      this.showResults = true;
      this.resultContainer?.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, (error) => {
      if(error.message){
        error.message = "Failed to fetch Search Results";
      }
      this.errorHandler.sendMessage(error);
    }));
  }
  addDiffAndGroupProducts(productPage: any, dateRange, totalElements, totalPages) {
    let groupedAuditedProduct: any;
    this.productsWithDiffChanges(productPage);
    const groupByProduct = groupBy('productId');
    groupedAuditedProduct =
    {
      dateRange: dateRange,
      auditRecord: groupByProduct(productPage),
      totalElements: totalElements,
      totalPages: totalPages
    }

    return groupedAuditedProduct;
  }
  getPage(page) {
    let pagePayload: FilterPayload = new FilterPayload();
    pagePayload.number = page.page - 1;
    pagePayload.fromDate = page.fromDate;
    pagePayload.toDate = page.toDate;
    pagePayload.partner = this.payload.partner;
    pagePayload.productId = this.payload.productId;
    pagePayload.size = this.payload.size;
    pagePayload.model = this.payload.model;
    pagePayload.revisionCount = "";
    let dateRange = {
      fromDate: page.fromDate,
      toDate: page.toDate,
    }
    this.loadingResults = false;
    this.resultsService.getResultsPerPage(pagePayload).subscribe(pageRecords => {
      let recordtoInsert = this.addDiffAndGroupProducts(pageRecords.content, dateRange, pageRecords.totalElements, pageRecords.totalPages);
      this.groupedAuditedProducts.splice(page.index, 1, recordtoInsert);
      this.designObjectForSupportedReleases(this.groupedAuditedProducts);
      this.loadingResults = true;
    }, (error) => {
      if(error.message){
        error.message = `Failed to fetch Results for page ${page.page}`;
      }
      this.errorHandler.sendMessage(error);
    })
  }

  productsWithDiffChanges(product: any) {
    product.forEach(prod => {
      if (prod.diffPath && prod.diffPath != null) {
        prod['prodChanges'] = prod.diffPath.product.diffPaths.length > 0 ? "Changes Found" : "No Changes";
        prod['certChanges'] = Object.keys(prod.diffPath.certificate).length > 0 ? "Changes Found" : "No Changes";
        this.productLevelDiffUpdatesToProduct(prod, prod.diffPath.product);
        this.certLevelDiffUpdatesToProduct(prod, prod.diffPath.certificate);
      } else if (prod.status == 'A') {
        prod['prodChanges'] = "Changes Found";
        if (prod.data.supportedReleases && prod.data.supportedReleases.length > 0) {
          prod.data.supportedReleases.forEach(cert => {
            cert['status'] = 'A';
            prod['certChanges'] = "Changes Found";
            // cert['releaseVersion'] = cert['releaseVersion'] + STATUS_SEPARATOR + "A";
          })
        }
      } else if (prod.status == 'D') {
        prod['prodChanges'] = "Changes Found";
        if (prod.data.supportedReleases && prod.data.supportedReleases.length > 0) {
          prod.data.supportedReleases.forEach((cert, index) => {
            cert['status'] = 'D';
            prod['certChanges'] = "Changes Found";
            // cert['releaseVersion'] = cert['releaseVersion'] + STATUS_SEPARATOR + "D";
          })
        }
      } else if (prod.status == 'C') {
        if (prod.data.supportedReleases && prod.data.supportedReleases.length > 0) {
          prod.data.supportedReleases.forEach((cert, index) => {
            cert['status'] = 'C';
            // cert['releaseVersion'] = cert['releaseVersion'] + STATUS_SEPARATOR + "C";
          })
        }
      }
    });
  }

  arrayLevelUpdates(product, cert, certificateKey, certLabel, label, key?) {
    if (certificateKey[certLabel] != null) {
      if (certificateKey[certLabel] == 'M') {
        cert[certLabel] = 'M';
      } else if (certificateKey[certLabel] == 'A') {
        cert[certLabel] = 'A';
      } else {
        if (product.deletedNode.modifiedSupportedReleases[key][label] && product.deletedNode.modifiedSupportedReleases[key][label].length > 0) {
          cert[certLabel] = 'D';
          cert[label] = product.deletedNode.modifiedSupportedReleases[key][label];
        }
      }
    }
  }

  certLevelDiffUpdatesToProduct(product: any, certificate: any) {
    let flattenedCerts;
    if (certificate && certificate != null) {
      for (let key in certificate) {
        if (certificate[key]['status'] == 'M') {
          for (let index = 0; index < product.data.supportedReleases.length; index++) {
            const cert = product.data.supportedReleases[index];
            if (Number(key) === cert.certId) {
              product.data.supportedReleases[index]['status'] = 'M';
              // product.data.supportedReleases[index]['releaseVersion'] = product.data.supportedReleases[index]['releaseVersion'] + STATUS_SEPARATOR + 'M';
              this.arrayLevelUpdates(product, product.data.supportedReleases[index], certificate[key], 'kbInfoStatus', 'kbInfo', key);
              this.arrayLevelUpdates(product, product.data.supportedReleases[index], certificate[key], 'footnotesStatus', 'footnotes', key);
              this.arrayLevelUpdates(product, product.data.supportedReleases[index], certificate[key], 'featureDetailsStatus', 'featureDetails', key);
              if (this.programSelected == 'ssd') {
                this.arrayLevelUpdates(product, product.data.supportedReleases[index], certificate[key], 'tierDetailsStatus', 'tierDetails', key);
              }
              flattenedCerts = this.prependSlashtoKeys(flatten(product.data.supportedReleases[index], { delimiter: '/' }), '/');
              for (let path in flattenedCerts) {
                let givenPath = typeof path === 'string' ? path.split('/') : path;
                if (givenPath && givenPath.length <= 2) {
                  for (let jindex = 0; jindex < certificate[key]['diffPaths'].length; jindex++) {
                    let diff = certificate[key]['diffPaths'][jindex];
                    if (path == diff['path'] && path != '/footnotes' && path != '/featureDetails' && path != '/kbInfo' && path != '/tierDetails') {
                      if (diff['status'] === 'M') {
                        this.valueLevelChanges(product.data.supportedReleases[index], givenPath[1], diff['value']);
                      } else {
                        /* Status A works fine, Need to check for Status D, if we get that status at Product level */
                        product.data[givenPath[1]] = product.data[givenPath[1]] + STATUS_SEPARATOR + diff['status'];
                      }
                    }
                  }
                } else {
                  /* driver details changes : driverDetails.driverDownloadLink||M/A/D */
                  if (givenPath[1] != 'footnotes' && givenPath[1] != 'featureDetails' && givenPath[1] != 'kbInfo' && givenPath[1] == 'driverDetails') {
                    delete givenPath[0];
                    let pathConst = givenPath.reduce((acc, ele, index) => acc + '.' + ele);
                    for (let jindex = 0; jindex < certificate[key]['diffPaths'].length; jindex++) {
                      let diff = certificate[key]['diffPaths'][jindex];
                      if (path == diff['path']) {
                        if (diff['status'] === 'M') {
                          // console.log(path);
                          product.data.supportedReleases[index]['driverDetailsStatus'] = 'M';
                          // jp.apply(product.data.supportedReleases[index], pathConst, (value) => {
                          //   // console.log( value + STATUS_SEPARATOR + certificate[key]['diffPaths'][jindex]['status'], "value + STATUS_SEPARATOR + certificate[key]['diffPaths'][jindex]['status']")
                          //   return value + STATUS_SEPARATOR + certificate[key]['diffPaths'][jindex]['status'];
                          // });
                        } else if (diff['status'] === 'A') {
                          product.data.supportedReleases[index]['driverDetailsStatus'] = 'A';
                          // jp.apply(product.data.supportedReleases[index], pathConst, (value) => {
                          //   // console.log( value + STATUS_SEPARATOR + certificate[key]['diffPaths'][jindex]['status'], "value + STATUS_SEPARATOR + certificate[key]['diffPaths'][jindex]['status']")
                          //   return value + STATUS_SEPARATOR + certificate[key]['diffPaths'][jindex]['status'];
                          // });
                        } else {
                          product.data.supportedReleases[index]['driverDetailsStatus'] = 'D';
                          // product.data.supportedReleases[index]['driverDetails']['driverName'] 
                          // product.data.supportedReleases[index]['driverDetails']['driverVersion']
                          // product.data.supportedReleases[index]['driverDetails']['driverDownloadLink']
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (certificate[key]['status'] == 'D') {
          /* when a complete cert is deleted */
          if (product.deletedNode.deletedSupportedReleases) {
            let cert: any;
            for (let i = 0; i < product.deletedNode.deletedSupportedReleases.length; i++) {
              if (Number(key) === product.deletedNode.deletedSupportedReleases[i].certId) {
                cert = product.deletedNode.deletedSupportedReleases[i];
                // this.addStatusRecursive(cert);
                cert['status'] = 'D';
                product.data.supportedReleases.push(cert);
              }
            }
          }
        } else if (certificate[key]['status'] == 'A') {
          let cert: any;
          for (let index = 0; index < product.data.supportedReleases.length; index++) {
            if (product.data.supportedReleases[index].certId == key) {
              cert = product.data.supportedReleases[index];
              cert['status'] = 'A';
              product.data.supportedReleases[index] = cert;
              // product.data.supportedReleases[index]['releaseVersion'] = product.data.supportedReleases[index]['releaseVersion'] + STATUS_SEPARATOR + 'A';
            }
          }
        }
      }
    }
  }

  valueLevelChanges(product, label, value) {
    /* valueArray[0] current value, valueArray[1] previous value */
    let valueArray = (value.split("|"));
    let currentValue = valueArray[0].trim();
    let previousValue = valueArray[1].trim();
    if (!this.checkForModifyExceptionCases(currentValue) && this.checkForModifyExceptionCases(previousValue)) {
      product[label] = currentValue + STATUS_SEPARATOR + 'A';
    } else if (this.checkForModifyExceptionCases(currentValue) && !this.checkForModifyExceptionCases(previousValue)) {
      product[label] = previousValue + STATUS_SEPARATOR + 'D';
    } else if (!this.checkForModifyExceptionCases(currentValue) && !this.checkForModifyExceptionCases(previousValue)) {
      product[label] = currentValue + STATUS_SEPARATOR + 'M';
    }
  }
  productLevelDiffUpdatesToProduct(product: any, productDiff: any) {
    if (productDiff && productDiff.status == 'M' && productDiff.diffPaths.length > 0) {
      if (productDiff.footnotesStatus && productDiff.footnotesStatus != null) {
        if (productDiff.footnotesStatus == 'D') {
          product.data['footnotesStatus'] = 'D';
          product.data['footnotes'] = product.deletedNode.footnotes;
        } else if (productDiff.footnotesStatus == 'A' || productDiff.footnotesStatus == 'M') {
          product.data['footnotesStatus'] = productDiff.footnotesStatus;
        }
      }
      let flattenedProduct: any;
      flattenedProduct = this.prependSlashtoKeys(flatten(product.data, { delimiter: '/' }), '/');
      for (let key in flattenedProduct) {
        let givenPath = typeof key === 'string' ? key.split('/') : key;
        if (givenPath && givenPath.length <= 2) {
          for (let index = 0; index < productDiff.diffPaths.length; index++) {
            let diff = productDiff.diffPaths[index];
            if (key === diff['path'] && key != '/footnotes') {
              if (diff['status'] === 'M') {
                this.valueLevelChanges(product.data, givenPath[1], diff['value']);
              } else {
                /* Status A works fine, Need to check for Status D, if we get that status at Product level */
                product.data[givenPath[1]] = product.data[givenPath[1]] + STATUS_SEPARATOR + diff['status'];
              }
            }
          }
        }
      }
    } else if (product.status == 'A') {
    }
  }

  /*checks for null, '', NA, 0 */
  checkForModifyExceptionCases(valueToCompare: any): boolean {
    let exceptions = [null, "", 'NA', '0', undefined, "null", "undefined", 'N/A'];
    if (typeof valueToCompare == 'string' && valueToCompare.length == 0) {
      return true;
    }
    return exceptions.includes(valueToCompare);
  }
  /*obj path construction from flattened path*/
  pathConstruction(givenPath: string[]) {
    let constructedPathString = '';
    for (let i = 0; i < givenPath.length; i++) {
      if (givenPath[i] != '' && givenPath[i] != null) {
        if (is.numeric(givenPath[i])) {
          let toNumber = Number(givenPath[i]);
          constructedPathString = constructedPathString + '[' + toNumber + '].';
        } else {
          constructedPathString = constructedPathString + givenPath[i];
        }
      }
    }
    return constructedPathString;
  }
  /* Fetach Map values based on provided key: not using as of now*/
  getMapValueWithKey(index, productId) {
    let listOfReleases = [];
    let listOfCertsInProduct = undefined;
    listOfCertsInProduct = this.supportedProductsPeriodMap.get(index).get(productId);
    listOfCertsInProduct.forEach((value: any, key: number) => {
      let obj = {};
      obj['certId'] = key;
      obj['releases'] = value;
      listOfReleases.push(obj);
    });
    return listOfReleases;
  }
  /* Using Map to  designObjectForSupportedReleases*/
  designObjectForSupportedReleases(groupedAuditedProducts: any[]) {
    this.supportedProductsPeriodMap = new Map<number, any>();
    groupedAuditedProducts.forEach((period, periodIndex) => {
      let supportedProductsMap: Map<number, any> = new Map<number, any>();
      for (let key in period.auditRecord) {
        let supportedReleasesMap: Map<number, any>;
        supportedReleasesMap = new Map<number, any>();
        for (let index = 0; index < period.auditRecord[key].length; index++) {
          let element = period.auditRecord[key][index]['data']['supportedReleases'];
          element.forEach(release => {
            let sanitiedCertId;
            // console.log(release, "release b4 sanitied certid")
            if (typeof release.certId == 'string') {
              sanitiedCertId = release.certId ? release.certId.split("||")[0] : '';
              // console.log(sanitiedCertId, "sanitiedCertId");
              release.certId = Number(sanitiedCertId);
            }
            // console.log(release, "release after certid");
            if (supportedReleasesMap.has(release.certId)) {
              release['createdDateTs'] = period.auditRecord[key][index]['createdDateTs'];
              supportedReleasesMap.get(release.certId).unshift(release);
            } else {
              let listOfReleases = [];
              release['createdDateTs'] = period.auditRecord[key][index]['createdDateTs'];
              listOfReleases.unshift(release);
              supportedReleasesMap.set(release.certId, listOfReleases);
            }
          });
        }
        supportedProductsMap.set(Number(key), supportedReleasesMap);
        
      }
      this.supportedProductsPeriodMap.set(periodIndex, supportedProductsMap);
    })
    //using for displaying releases
    this.supportedProductsPeriodObj = toObject(this.supportedProductsPeriodMap);
    this.sortCertsBasedOnLatestRelease(groupedAuditedProducts, this.supportedProductsPeriodObj);
  }

  sortCertsBasedOnLatestRelease(groupedAuditedProducts, supportedProductsPeriodObj) {
    groupedAuditedProducts.forEach((period, periodIndex) => {
      //productId: productObj
      for (let key in period.auditRecord) {
        //each [product]
        let element = period.auditRecord[key][0]['data']['supportedReleases'];
        let orderedCertsOfProduct: Array<any> = [];
        element.forEach(release => {
          orderedCertsOfProduct.push(release['certId']);
        })
        let sortedCertsObjOfproduct = {};
        if (supportedProductsPeriodObj[periodIndex] && supportedProductsPeriodObj[periodIndex][key]) {
          orderedCertsOfProduct.forEach(cert => {
            sortedCertsObjOfproduct[cert + " "] = supportedProductsPeriodObj[periodIndex][key][cert]
          })
          supportedProductsPeriodObj[periodIndex][key] = sortedCertsObjOfproduct;
        }
      }
    })
  }

  prependSlashtoKeys(auditData: any, stringToAppend) {
    if (Array.isArray(auditData)) {
      auditData.map(ele => {
        for (let prop in ele) {
          ele[stringToAppend + prop] = ele[prop];
          delete ele[prop];
        }
      });
    } else {
      for (const key in auditData) {
        if (auditData.hasOwnProperty(key)) {
          auditData[stringToAppend + key] = auditData[key];
          delete auditData[key]
        }
      }
      return auditData;
    }
  }

  addStatusRecursive(obj) {
    for (let k in obj) {
      if (typeof obj[k] == "object" && obj[k] !== null) {
        this.addStatusRecursive(obj[k]);
      }
      else {
        if (k === 'releaseVersion') {
          // obj[k] = obj[k] + STATUS_SEPARATOR + 'D';
        }
      }
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

//util to group array items based on key/value
export const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

//util to convert map to obj
export const toObject = (map = new Map) => {
  let lo = {};
  Array.from
    (map.entries()
      , ([k, v]) =>{
        v instanceof Map
          ? lo[k] = toObject(v)
          : lo[k] = v;
      }
    );
  return lo;
}