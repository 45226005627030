export class SSDConfig{
    static keyToLabels: any =[
        {
            keyPattern : '$.modelName',
            label: "MODEL"
        },
        {
            keyPattern : '$.deviceType',
            label: "Device Type"
        },{
            keyPattern: "$.endurance",
            label: "ENDURANCE"
        },{
            keyPattern: "$.enduranceClass",
            label: "ENDURANCE CLASS"
        },{
            keyPattern: "$.ssdPartNumber",
            label: "PART NUMBER"
        },{
            keyPattern: "$.capacity",
            label: "CAPACITY"
        },{
            keyPattern: "$.ssdSeries",
            label: "SERIES"
        },{
            keyPattern: "$.vendorId",
            label: "VENDOR ID"
        },{
            keyPattern: "$.grade",
            label: "PERFORMANCE CLASS"
        },{
            keyPattern: "$.interfaceSpeed",
            label: "INTERFACE SPEED"
        },{
            keyPattern: "$.formFactor",
            label: "FORM FACTOR"
        },{
            keyPattern: "$.SSDProductId",
            label: "PRODUCT ID"
        },{
            keyPattern: "$.footnotes",
            label: "NOTES(s)"
        }
    ];
    static keyToReleaseLabel: any = [
        {
            keyPattern: '$.supportedReleases[*].ReleaseVersion',
            label: 'Release Version'
        }
    ];
    static keyToReleaseLabels: any = [
        {
            keyPattern: "$.supportedReleases[*].firmwareVersion",
            label: "Firmware Version"
        },{
            keyPattern: "$.supportedReleases[*].deviceDriverType",
            label: "Driver Type"
        },{
            keyPattern: "$.supportedReleases[*].featureDetails[*].feature",
            label: "Features"
        }
        // ,{
        //     keyPattern: "$.supportedReleases[*].",
        //     label: "Device Driver(s)"
        // },{
        //     keyPattern: "$.supportedReleases[*].",
        //     label: "vSAN Type"
        // },
    ];
    static prouctLevelLabels: any = {
        "modelName": "Model",
        "deviceType": 'Device Type',
        "vendorId": "Vendor Id",
        "endurance": "Endurance (TBW)",
        "enduranceTBW": "Endurance Class",
        "grade": "Performance Class",
        "technology": "Flash Technology",
        "ssdPartNumber": "Part Number",
        "interfaceSpeed": "Interface Speed (Gbps)",
        "capacity": "Capacity (GB)",
        "formFactor": "Form Factor",
        "ssdSeries": "Series",
        "footnotes": 'Note(s)',
        "vid": "VID",
        "svid": "SVID",
        "did": "DID",
        "ssid": "SSID",
        "ssdProductId": "Product Id"
        // "vcgLink": "VCG Link"
    }
    static certLevelLabels: any = {
        "tierDetails": "Tier Details",
        "firmwareVersion": "Firmware Version",
        "featureDetails": "Feature Details",
        "footnotes": "Note(s)",
        "kbInfo": "KB Info",
        "driverDetails": "Driver Details",
        "deviceDriverType": "Driver Type",
        "releaseVersion": "Release Version"
    }
    static releaseCompLabels: any = {
        "driverDetails": "Driver Details",
        "tier": "Tier Details",
        "firmwareVersion": "Firmware Version",
        "driverType": "Driver Type",
        // "features": "Features"
    }
}