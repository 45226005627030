export class Programs{
    static programs: any = {
        "vsanrnOSA": "ReadyNode",
        "vsanrnESA": "ReadyNode",
        "vsanio": "IOController",
        "ssd": "SSD",
        "hdd": "HDD",
        "network": "Network"
    };

    static vSANTypes = {
        vsan: "vSAN OSA",
        vsanEsa: "vSAN ESA"
    }
}