<div class="results-container" #resultContainerRef *ngIf="groupedAuditedProducts">
    <div *ngIf='!noResultsToShow; else showNoResults'>
        <div class="clr-row" style="margin-bottom: 1rem;">
            <div class="clr-col-sm-12 clr-col-md-7 clr-col-lg-7">
                <clr-alert [clrAlertClosable]="false" *ngIf="!payload.revisionCount">
                    <clr-alert-item>
                        <span class="alert-text">
                            Showing results for {{payload.fromDate | date : 'd MMMM y'}} - {{payload.toDate | date : 'd MMMM y'}} period
                        </span>
                    </clr-alert-item>
                </clr-alert>
                <!-- <clr-alert [clrAlertClosable]="false" *ngIf="payload.revisionCount">
                    <clr-alert-item>
                        <span class="alert-text">
                            Showing results for {{groupedAuditedProducts[0]['dateRange']['fromDate'] | date : 'd MMMM y'}} - {{groupedAuditedProducts[0]['dateRange']['toDate'] | date : 'd MMMM y'}} period
                        </span>
                    </clr-alert-item>
                </clr-alert> -->
            </div>
            <div class="clr-col-sm-12 clr-col-md-5 clr-col-lg-5">
                <div class="audit-status">
                    <div class=""><span class="badge badge-light-blue"></span>Baseline</div>
                    <div class=""><span class="badge badge-success"></span>Newly Added</div>
                    <div class=""><span class="badge badge-warning"></span>Modified</div>
                    <div class=""><span class="badge badge-danger"></span>Removed</div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="programSelected != 'vsanrn'">
            <app-vsanio-hdd-results [groupedAuditedProducts]="groupedAuditedProducts"
                [supportedProductsPeriodObj]="supportedProductsPeriodObj" [programSelected]="programSelected"
                (page)=getPage($event) [payload]="payload" [loadingResults]="loadingResults"></app-vsanio-hdd-results>
        </ng-container>
        <ng-container *ngIf="programSelected == 'vsanrn'">
            <app-vsanrn-results [rawAuditData]="rawAuditData" [payload]="payload"></app-vsanrn-results>
        </ng-container>
    </div>
</div>

<ng-template #showNoResults>
    <div class="no-results">
        <!-- <strong>Product(s) revisions are not available for selected criteria.</strong> -->
        <strong>Product(s) revisions are not available from date {{payload.fromDate | date : 'd MMMM y'}} to date {{payload.toDate | date : 'd MMMM y'}} with selected criteria</strong>
    </div>
</ng-template>

<div class="timeline-wrapper" *ngIf="!showResults">
    <div class="timeline-item">
        <div class="animated-background" *ngFor="let dummy of ' '.repeat(1).split(''), let x = index">
            <div class="fakeField"></div>
            <div class="fakeField2"></div>
            <div class="fakeField3"></div>
            <div class="fakeField4"></div>
            <div class="fakeField2"></div>
            <div class="fakeField3"></div>
            <div class="fakeField4"></div>
            <div class="fakeField2"></div>
        </div>
    </div>
</div>