import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { SubscriptionPayload } from 'src/app/data-models/subscription-payload';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'src/app/services/app.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EnvironmentsService } from 'src/app/services/environment.service';

@Injectable({
    providedIn: 'root'
})


export class SubscriptionService {

    programSelected: string;
    private subscriptionSubject = new Subject<SubscriptionPayload>();
    
    constructor(private http: HttpClient, private appService: AppService, private authService: AuthenticationService, private envService: EnvironmentsService) {
        this.appService.getProgramSelected().subscribe(program => {
            this.programSelected = program;
        })
    }

    setSubscriptionSubject(value: SubscriptionPayload) {
        this.subscriptionSubject.next(value);
    }

    getSubscriptionSubject(): Observable<any> {
        return this.subscriptionSubject.asObservable();
    }

    sendSubscriptionOTP(email): Observable<any>{
        return this.http.get<any>(this.envService.config.otpEndpoint + `product/otp?userEmail=${email}`);
    }

    subscribeToProduct(payload: SubscriptionPayload): Observable<any> {
        return this.http.post<any>(this.envService.config.subscriptionEndpoint + `subscription`, {
            userEmail: payload.userEmail,
            clientType : "VCG",
            otpNumber: payload.otpNumber,
            subscriptionRequests : [{
                notificationType: this.programSelected,
                productIds: payload.productIds
            }]
        });
    }

    secureSubscribeToProduct(payload: SubscriptionPayload): Observable<any> {
        return this.http.post<any>(this.envService.config.subscriptionAuthv2Endpoint + `product/subscription`, [{
            userEmail: this.authService.userEmail(),
            clientType : "VCG",
            subscriptionRequests : [{
                notificationType: this.programSelected,
                productIds: payload.productIds
            }]
        }]);
    }
}