import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'handleLongStrings'
})
export class HandleLongStringsPipe implements PipeTransform {

  transform(str: string, fitLength: number): any {
    let subStr = ''
    subStr = str;
    let finalStr = "";
    while (subStr && (subStr.length > fitLength)) {
      finalStr += subStr.substring(0, fitLength) + "\n";
      subStr = subStr.substring(fitLength, subStr.length);
    }
    finalStr += subStr;
    return finalStr;
  }
}
