export enum AlertType {
	INFO = "info",
	WARNING = "warning",
	SUCCESS = "success",
	DANGER = "danger"
}

export interface AlertMessage {
	message: string,
	type?: AlertType
}