import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Programs } from 'src/app/programs/programs';
import { KeyValue } from '@angular/common';
import { User } from 'src/app/data-models/user';
import { Router } from '@angular/router';
import { EnvironmentsService } from 'src/app/services/environment.service';

@Component({
  selector: '[app-header]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @ViewChild('dropdown') dropdown: ElementRef;
  removeClassOpen: boolean;
  fileDownloadURL: string;
  currentUser: User;
  constructor(private renderer: Renderer2, private router: Router,public appService: AppService, public authenticationService: AuthenticationService,
    private envService: EnvironmentsService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
   }
  programs: string[];
  ngOnInit() {
    this.removeClassOpen = false;
    // this.appService.vsanType.subscribe((value) => {
    //   this.programs = value === 'vsan' ? Programs.programs : Programs.programsEsa;
    // })
    this.programs = Programs.programs;
    this.fileDownloadURL = this.envService.config.apiEndpoint;
  }

  programSelected(program: string){
    let selectedProgram = program;
    if(program.includes('vsanrn')) {
      selectedProgram = 'vsanrn';
    }
    this.setVsanType(program);
    this.appService.setProgramSelected(selectedProgram);
  }
  getFAQDownloadURL(){
   return `${this.envService.config.apiEndpoint}template/download/faq`;
  }

  setVsanType(program: string) {
    if(program === 'vsanrnESA') {
      this.appService.vsanType.next('vsanEsa');
    } else if(program.includes('vsanrnOSA')) {
      this.appService.vsanType.next('vsan');
    } else {
      this.appService.vsanType.next('');
    }
  }

    /* preserves order of obj props when applied keyvalue pipe */
    originalOrder = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => {
      const a = akv.value.index;
      const b = bkv.value.index;
      return a > b ? 1 : (b > a ? -1 : 0);
    }

    logout() {
      this.authenticationService.logout().subscribe(loggedout => {
        if(loggedout) {
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 200);
        }
      });
    }

    onHome() {
      this.appService.hidePrograms = true;
    }
}
