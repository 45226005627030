import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor() { }

  public error$: Subject<any> = new Subject<any>();
  handleError(error: any) {
    console.log("in handler");
    this.error$.next(error);
    // Make sure to rethrow the error so Angular can pick it up
    throw error;
  }

  getMessage(): Observable<any> {
    return this.error$.asObservable();
  }

  sendMessage(error: any) {
    this.error$.next({ errorText: error.message, error: error });
  }

}
