import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './helpers/auth.guard';
import { AuditComponent } from './modules/audit/audit.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'audit-detail', component: AuditComponent},
  { path: 'home', component: HomeComponent},
  { path: 'unsubscription', loadChildren: () => import('./modules/manage-subscriptions/manage-subscriptions.module').then(m => m.ManageSubscriptionsModule)},
  { path: 'faq', loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule)},
  { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard]},
  { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)},
  { path: '**', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
