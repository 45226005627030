import { Component, OnInit, ViewChild, Input, Output, ViewEncapsulation, AfterViewInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm, Validators } from '@angular/forms';
import { Observable, timer, NEVER, BehaviorSubject, fromEvent, of } from 'rxjs';
import { first, map, tap, takeWhile, share, startWith, switchMap, filter } from 'rxjs/operators';
import { AppService } from '../../services/app.service'
import { AlertType, AlertMessage } from '../../helpers/alert.type'

import { AuthenticationService } from '../../services/authentication.service';
import { SubscriptionService } from '../audit/services/subscription.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
		@ViewChild('loginForm') public loginForm: NgForm;
		useremail: string = '';
		otp: number = null;
		otpBtnTxt: string = 'Generate One Time Passcode';
		loginBtnTxt: string = 'Login';
		SubmitTxt = this.otpBtnTxt;
		loading = false;
		sentOTP = false;
		submitted = false;
		otpExpired = false;
		otpTimeDisplay = '';
		returnUrl: string;
		defaultTimeInMinutes: Number = 10;
		error = '';
		toggle$ = new BehaviorSubject(true);
		loginAlert: AlertMessage = {
			message: '',
			type: AlertType.INFO
		};

		constructor(
			// private formBuilder: FormBuilder,
			private route: ActivatedRoute,
			private router: Router,
			private authenticationService: AuthenticationService,
			private appService: AppService,
			private subscriptionService: SubscriptionService
		) { 
			// redirect to home if already logged in
			if (this.authenticationService.isActiveUser()) { 
					this.router.navigate(['/dashboard']);
			}
		}

		ngOnInit() {
			// this.loginForm = this.formBuilder.group({
			// 	email: ['', [Validators.required, Validators.email]],
			// 	password: ['', [Validators.required]],
			// 	otp: [null]
			// });
			this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
		}

		// convenience getter for easy access to form fields
		get f() { return this.loginForm.controls; }

		sendOTP() {
			if (this.loginForm.invalid) {
				return;
			}
			let email = this.loginForm.value.email;
			this.subscriptionService.sendSubscriptionOTP(email).subscribe(response => {
				if(response.status == 'success') {
					this.sentOTP = true;
					this.SubmitTxt = this.loginBtnTxt;
					this.loginAlert = {
						message: response.message,
						type: AlertType.SUCCESS
					};
					setTimeout(()=>{    
						this.countDown(this.defaultTimeInMinutes);
					}, 200);
				} else {
				}
			}, error => {
				this.loginAlert = {
					message: 'Something went wrong. Please try again',
					type: AlertType.DANGER
				}
			});
		}

		resendOTP() {
			this.loginAlert = {
				message: '',
				type: AlertType.INFO
			};
			this.otp = null;
			let email = this.useremail;
			this.subscriptionService.sendSubscriptionOTP(email).subscribe(response => {
				if(response.status == 'success') {
					this.sentOTP = true;
					this.SubmitTxt = this.loginBtnTxt;
					this.loginAlert = {
						message: response.message,
						type: AlertType.SUCCESS
					};
					setTimeout(()=>{    
						this.countDown(this.defaultTimeInMinutes);
					}, 200);
				} else {
				}
			}, error => {
				this.loginAlert = {
					message: 'Something went wrong. Please try again.',
					type: AlertType.DANGER
				}
			});
		}
		
		login() {
			// stop here if form is invalid
			console.log(this.loginForm.value)
			if (this.loginForm.invalid) {
				return;
			}
			this.submitted = true
			this.loading = true;
			this.authenticationService.login(this.useremail, this.otp)
				.pipe(first())
				.subscribe(
					data => {
						this.router.navigate([this.returnUrl]);
					},
					error => {
						if(error.errCode == 400) {
							this.loginAlert = {
								message: 'Entered One Time Passcode is not valid, please enter correct Passcode or Regenerate the Passcode',
								type: AlertType.DANGER
							}
						} else {
							this.loginAlert = {
								message: 'Something went wrong. Please try again',
								type: AlertType.DANGER
							}
						}
						this.loading = false;
					}
				);
		}

		onAdminSubmit() {
			// stop here if form is invalid
			if (this.loginForm.invalid) {
				return;
			}
		}

		countDown(minute) {
		
			if(this.toggle$.value) {
				this.toggle$.next(!this.toggle$.value);
			}
	
			this.toggle$ = new BehaviorSubject(true);
			const toggle$ = this.toggle$;
	
			const K = 1000;
			const INTERVAL = K;
			const TIME = minute * K * 60;
	
			let current: number;
			let time = TIME;
	
			const toMinutesDisplay = (ms: number) => Math.floor(ms / K / 60);
			const toSecondsDisplay = (ms: number) => Math.floor(ms / K) % 60;
	
			const toSecondsDisplayString = (ms: number) => {
				const seconds = toSecondsDisplay(ms);
				return seconds < 10 ? `0${seconds}` : seconds.toString();
			};
	
			const toMinutesDisplayString = (ms: number) => {
				const minutes = toMinutesDisplay(ms);
				return minutes < 10 ? `0${minutes}` : minutes.toString();
			};
	
			const currentSeconds = () => time / INTERVAL;
			const toMs = (t: number) => t * INTERVAL
			const toRemainingSeconds = (t: number) => currentSeconds() - t;
	
			const remainingSeconds$ = toggle$.pipe(
				switchMap((running: boolean) => (running ? timer(0, INTERVAL) : NEVER)),
				map(toRemainingSeconds),
				takeWhile(t => t >= 0),
			);
	
			const ms$ = remainingSeconds$.pipe(
				map(toMs),
				tap(t => current = t)
			);
	
			const minutes$ = ms$.pipe(
				map(toMinutesDisplayString),
				map(s => s.toString()),
				startWith(toMinutesDisplayString(time).toString())
			);
	
			const seconds$ = ms$.pipe(
				map(toSecondsDisplayString),
				startWith(toSecondsDisplayString(time).toString())
			);
	
			// update DOM
			const minutesElement = document.querySelector('#timer .minutes');
			const secondsElement = document.querySelector('#timer .seconds');
			const toggleElement = document.querySelector('#timer');
	
			this.updateDom(minutes$, minutesElement);
			this.updateDom(seconds$, secondsElement);
	
			remainingSeconds$.subscribe({
				complete: () => {
					this.loginAlert = {
						message: 'One Time Passcode has expired. Please try to login again.',
						type: AlertType.DANGER
					};
					this.otpExpired = true;
				}
			});
	
		}
	
		updateDom(source$: Observable<string>, element: Element) {
			source$.subscribe((value) => element.innerHTML = value);
		}

		refreshLogin() {
			location.reload();
		}
}
