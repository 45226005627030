import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '../data-models/user';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  programSelected: BehaviorSubject<string>;
  productsSelected: BehaviorSubject<string>;
  productsToDate: BehaviorSubject<string>;
  productsFromDate: BehaviorSubject<string>;
  productsRevisionCount: BehaviorSubject<string>;
  loggedInUser: BehaviorSubject<User>;
  hidePrograms: boolean = true;
  vsanType: BehaviorSubject<string>;
  // vsanType: BehaviorSubject<string> = new BehaviorSubject('vsanEsa');
  // defaultProgram: string;
  defaultArch: string = 'vsan';
  constructor() { 

    // this.vsanType?.subscribe((value) => {
    //   if(value === 'vsanEsa') {
    //     this.defaultProgram = 'vsanrn';
    //     this.setProgramSelected(this.defaultProgram);
    //   } else {
    //     this.defaultProgram = 'vsanio';
    //     this.setProgramSelected(this.defaultProgram);
    //   }
    // })
    let program,
    productId,
    fromDate,
    toDate,
    revisionCount,
    archType,
    programParam : string;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    archType = urlParams.get('vsanType');
    productId = urlParams.get('productId');
    programParam = urlParams.get('program');
    fromDate = urlParams.get('fromDate');
    toDate = urlParams.get('toDate');
    revisionCount = urlParams.get('revisionCount');
    if(programParam) {
      program  = programParam;
    }
    archType = archType ? archType : this.defaultArch;
    productId = productId ? productId:'';
    fromDate = fromDate ? fromDate: '';
    toDate = toDate ? toDate : '';
    revisionCount = revisionCount ? revisionCount: '';
    this.vsanType = new BehaviorSubject<string>(archType);
    this.programSelected = new BehaviorSubject<string>(program);
    this.productsSelected = new BehaviorSubject<string>(productId);
    this.productsFromDate = new BehaviorSubject<string>(fromDate);
    this.productsToDate = new BehaviorSubject<string>(toDate);
    this.productsRevisionCount = new BehaviorSubject<string>(revisionCount);
    // this.programSelected = new BehaviorSubject<string>(program);
    
  }

  getProgramSelected(): Observable<string> {
    return this.programSelected.asObservable();
  }
  setProgramSelected(program: string) {
    this.programSelected?.next(program);
  }

  getProductsSelected(): Observable<string> {
    return this.productsSelected.asObservable();
  }
  setProductsSelected(products: string) {
    this.productsSelected.next(products);
  }

  getProductsFromDate(): Observable<string> {
    return this.productsFromDate.asObservable();
  }
  setProductsFromDate(date: string) {
    this.productsFromDate.next(date);
  }

  getProductsToDate(): Observable<string> {
    return this.productsToDate.asObservable();
  }
  setProductsToDate(date: string) {
    this.productsToDate.next(date);
  }

  getProductsRevisionCount(): Observable<string> {
    return this.productsRevisionCount.asObservable();
  }
  setProductsRevisionCount(count: string) {
    this.productsRevisionCount.next(count);
  }

  getCurrentUser(): Observable<User> {
    return this.loggedInUser.asObservable();
  }

  setCurrentUser(user: User) {
    this.loggedInUser.next(user);
  }
}
