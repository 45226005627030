import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { AuditModule } from './modules/audit/audit.module';
import { DropdownToggleDirective } from './directives/dropdown-toggle';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { ManageSubscriptionsModule } from './modules/manage-subscriptions/manage-subscriptions.module';
// import { ReverseOrderPipe } from './pipes/reverse-order.pipe';
import { LoginModule } from './modules/login/login.module';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DropdownToggleDirective,
    HomeComponent,
    FooterComponent
    // ReverseOrderPipe,
    // HandleLongStringsPipe,
    // DateSortPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    BrowserAnimationsModule,
    AuditModule,
    HttpClientModule,
    ManageSubscriptionsModule,
    LoginModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: JwtInterceptor, 
      multi: true 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
