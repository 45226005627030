import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VSANRNConfig } from 'src/app/programs/vsanrn.config';
import { KeyValue, DatePipe } from '@angular/common';
import { FilterPayload } from 'src/app/data-models/filter-payload';
import { ResultsService } from '../../services/results.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { SubscriptionService } from '../../services/subscription.service';
import { SubscriptionPayload } from '../../../../data-models/subscription-payload'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { AppService } from 'src/app/services/app.service';

const STATUS_SEPARATOR = '||';
var flatten = require('flat');

@Component({
  selector: 'app-vsanrn-results',
  templateUrl: './vsanrn-results.component.html',
  styleUrls: ['./vsanrn-results.component.scss'],
  providers: [DatePipe]
})
export class VsanrnResultsComponent implements OnInit, OnChanges {


  @Input() rawAuditData: any[];
  @Input() payload: FilterPayload;
  groupedAuditedProducts: any[];
  supportedProductsPeriodMap: Map<number, any>;
  supportedProductsPeriodObj: any;
  productLabelsConfig: any;
  supportReleaseLabelsConfig: any;
  pageNumReq: number = 1;
  componentLabels: any;
  expanded: Array<boolean> = [false, false, false, false, false, false, false, false, false, false, false, false];
  productReleaseExpanded: Array<boolean> = [false, false, false, false, false, false, false, false, false, false];
  p: number[] = [];
  loadingResults: boolean;
  releasesForComp: any;
  productDetails: { productName: any; partnerName: any; vcgLink: any; productId: any};
  openRevComparisonModal: boolean;
  openSubscriptionModal: boolean;
  openSubscribedModal: boolean;
  openAlreadySubscribedModal: boolean;
  openSubscribedErrorModal: boolean;
  subscribing: boolean = false;
  releaseCompLabels: any;
  vsanType: string;
  constructor(private appService: AppService, private resultsService: ResultsService, private datePipe: DatePipe, private errorHandler: ErrorHandlerService, private subscriptionService: SubscriptionService, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.loadingResults = true;
    this.productLabelsConfig = VSANRNConfig.prouctLevelLabels;
    this.supportReleaseLabelsConfig = VSANRNConfig.certLevelLabels;
    this.componentLabels = VSANRNConfig.componentLabels; //if vsan
    this.vsanType = this.appService.vsanType.getValue();
    if(this.vsanType === 'vsanEsa') {
      this.componentLabels = VSANRNConfig.componentLabelsvSANeSA;
    }
    this.rawAuditData = [];
    this.groupedAuditedProducts = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadingResults = true;
    this.releaseCompLabels = VSANRNConfig.releaseCompLabels;
    if (this.rawAuditData) {
      this.groupedAuditedProducts = [];
      this.removeNullPropertiesFromRnComponentsOfReadyNode();
      this.rawAuditData.forEach(product => {
        this.groupedAuditedProducts.push(this.addDiffAndGroupProducts(product.page.content, product.dateRange, product.page.totalElements, product.page.totalPages));
      });
      if (this.groupedAuditedProducts && this.groupedAuditedProducts.length > 0) {
        this.groupedAuditedProducts.forEach(groupProducts => {
          for (let key in groupProducts.auditRecord) {
            if (groupProducts.auditRecord[key] && groupProducts.auditRecord[key].length > 0) {
              groupProducts.auditRecord[key].sort((a, b) => new Date(b.createdDateTs).getTime() - new Date(a.createdDateTs).getTime());
            }
          }
        })
      }
      this.designObjectForSupportedReleases(this.groupedAuditedProducts);
    }
  }

  removeNullPropertiesFromRnComponentsOfReadyNode() {
    this.rawAuditData.forEach((product, index) => {
      if(product.page.content.length){
        product.page.content.forEach((record, recordIndex) => {
          for (const key in record.data.rnComponents) {
            if (Object.prototype.hasOwnProperty.call(record.data.rnComponents, key)) {
              const element = record.data.rnComponents[key];
              if(!element) {
                delete record.data.rnComponents[key];
              }
            }
          }

        })
      }
    });
  }

  addDiffAndGroupProducts(productPage: any, dateRange, totalElements, totalPages) {
    let groupedAuditedProduct: any;
    this.productsWithDiffChanges(productPage);
    const groupByProduct = groupBy('productId');
    groupedAuditedProduct = 
      {
        dateRange: dateRange,
        auditRecord: groupByProduct(productPage),
        totalElements: totalElements,
        totalPages: totalPages
      }
    return groupedAuditedProduct;
  }
  getPage(page, index, fromDate, toDate){
    let pagePayload: FilterPayload = new FilterPayload();
    // this.payload.number = page.page - 1;
    // this.payload.fromDate = page.fromDate;
    // this.payload.toDate = page.toDate;
    pagePayload.number = page - 1;
    pagePayload.fromDate = this.datePipe.transform(fromDate, 'y-MM-dd');
    pagePayload.toDate = this.datePipe.transform(toDate, 'y-MM-dd');
    pagePayload.partner = this.payload.partner;
    pagePayload.productId = this.payload.productId;
    pagePayload.size = this.payload.size;
    pagePayload.model = this.payload.model;
    pagePayload.revisionCount = "";
    this.pageNumReq = page;
    let dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    }
    this.loadingResults = false;
      this.resultsService.getResultsPerPage(pagePayload).subscribe( pageRecords => {
        console.log(pageRecords, "Pagerecords");
        let recordtoInsert = this.addDiffAndGroupProducts(pageRecords.content, dateRange, pageRecords.totalElements, pageRecords.totalPages);
        this.groupedAuditedProducts.splice(index, 1, recordtoInsert);
        this.designObjectForSupportedReleases(this.groupedAuditedProducts);
        this.loadingResults = true;
        this.productReleaseExpanded = [false, false, false, false, false, false, false, false, false, false];
      }, (error) => {
        if(error.message){
          error.message = `Failed to fetch Results for page ${page.page}`;
        }
        this.errorHandler.sendMessage(error);
      })
  }
  productsWithDiffChanges(product: any) {
    product.forEach(prod => {
      if (prod.diffPath && prod.diffPath != null) {
        prod['prodChanges'] = prod.diffPath.product.diffPaths.length > 0 ? "Changes Found" : "No Changes";
        prod['certChanges'] = Object.keys(prod.diffPath.certificate).length > 0 ? "Changes Found" : "No Changes";
        this.productLevelDiffUpdatesToProduct(prod, prod.diffPath.product);
        this.certLevelDiffUpdatesToProduct(prod, prod.diffPath.certificate);
      } else if (prod.status == 'A') {
        prod['prodChanges'] = "Changes Found";
        if (prod.data.supportedReleases && prod.data.supportedReleases.length > 0) {
          prod.data.supportedReleases.forEach(cert => {
            cert['status'] = 'A';
            prod['certChanges'] = "Changes Found";
            // cert['releaseVersion'] = cert['releaseVersion'] + STATUS_SEPARATOR + "A";
          })
        }
      } else if (prod.status == 'D') {
        prod['prodChanges'] = "Changes Found";
        if (prod.data.supportedReleases && prod.data.supportedReleases.length > 0) {
          prod.data.supportedReleases.forEach((cert, index) => {
            cert['status'] = 'D';
            prod['certChanges'] = "Changes Found";
            // cert['releaseVersion'] = cert['releaseVersion'] + STATUS_SEPARATOR + "D";
          })
        }
      } else if (prod.status == 'C') {
        if (prod.data.supportedReleases && prod.data.supportedReleases.length > 0) {
          prod.data.supportedReleases.forEach((cert, index) => {
            cert['status'] = 'C';
            // cert['releaseVersion'] = cert['releaseVersion'] + STATUS_SEPARATOR + "C";
          })
        }
      }
    });
  }
  arrayLevelUpdates(product, cert, certificateKey, certLabel, label, key?) {
    if (certificateKey[certLabel] != null) {
      if (certificateKey[certLabel] == 'M') {
        cert[certLabel] = 'M';
      } else if (certificateKey[certLabel] == 'A') {
        cert[certLabel] = 'A';
      } else {
        if (product.deletedNode.modifiedSupportedReleases[key][label] && product.deletedNode.modifiedSupportedReleases[key][label].length > 0) {
          cert[certLabel] = 'D';
          cert[label] = product.deletedNode.modifiedSupportedReleases[key][label];
        }
      }
    }
  }
  certLevelDiffUpdatesToProduct(product: any, certificate: any) {
    let flattenedCerts;
    if (certificate && certificate != null) {
      for (let key in certificate) {
        if (certificate[key]['status'] == 'M') {
          for (let index = 0; index < product.data.supportedReleases.length; index++) {
            const cert = product.data.supportedReleases[index];
            if (Number(key) === cert.certDetailId) {
              product.data.supportedReleases[index]['status'] = 'M';
              // product.data.supportedReleases[index]['releaseVersion'] = product.data.supportedReleases[index]['releaseVersion'] + STATUS_SEPARATOR + 'M';
              this.arrayLevelUpdates(product, product.data.supportedReleases[index], certificate[key], 'kbInfoStatus', 'kbInfo', key);
              this.arrayLevelUpdates(product, product.data.supportedReleases[index], certificate[key], 'footnotesStatus', 'footnotes', key);
              this.arrayLevelUpdates(product, product.data.supportedReleases[index], certificate[key], 'featureDetailsStatus', 'featureDetails', key);
              flattenedCerts = this.prependSlashtoKeys(flatten(product.data.supportedReleases[index], { delimiter: '/' }), '/');
              for (let path in flattenedCerts) {
                let givenPath = typeof path === 'string' ? path.split('/') : path;
                if (givenPath && givenPath.length <= 2) {
                  for (let jindex = 0; jindex < certificate[key]['diffPaths'].length; jindex++) {
                    let diff = certificate[key]['diffPaths'][jindex];
                    if (path == diff['path'] && path != '/footnotes' && path != '/featureDetails' && path != '/kbInfo') {
                      if (diff['status'] === 'M') {
                        this.valueLevelChanges(product.data.supportedReleases[index], givenPath[1], diff['value']);
                      } else {
                        /* Status A works fine, Need to check for Status D, if we get that status at Product level */
                        product.data[givenPath[1]] = product.data[givenPath[1]] + STATUS_SEPARATOR + diff['status'];
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (certificate[key]['status'] == 'D') {
          /* when a complete cert is deleted */
          console.log(certificate[key]['status'], "certificate[key]['status']");
          if (product.deletedNode.deletedSupportedReleases) {
            let cert: any;
            for (let i = 0; i < product.deletedNode.deletedSupportedReleases.length; i++) {
              if (Number(key) === product.deletedNode.deletedSupportedReleases[i].certDetailId) {
                cert = product.deletedNode.deletedSupportedReleases[i];
                // this.addStatusRecursive(cert);
                cert['status'] = 'D';
                // cert['releaseVersion'] = cert['releaseVersion'] + STATUS_SEPARATOR + 'D';
                product.data.supportedReleases.push(cert);
              }
            }
          }
        } else if (certificate[key]['status'] == 'A') {
          let cert: any;
          for (let index = 0; index < product.data.supportedReleases.length; index++) {
            if (product.data.supportedReleases[index].certDetailId == key) {
              cert = product.data.supportedReleases[index];
              cert['status'] = 'A';
              product.data.supportedReleases[index] = cert;
              // product.data.supportedReleases[index]['releaseVersion'] = product.data.supportedReleases[index]['releaseVersion'] + STATUS_SEPARATOR + 'A';
            }
          }
        }
      }
    }
  }

  productLevelArrayModifications(product, productDiffStatus, labelStatus, label){
    if (productDiffStatus == 'D') {
      product.data[labelStatus] = 'D';
      product.data[label] = product.deletedNode[label];
    } else if (productDiffStatus == 'A' || productDiffStatus == 'M') {
      product.data[labelStatus] = productDiffStatus;
    }
  }
  productLevelDiffUpdatesToProduct(product: any, productDiff: any) {
    if (productDiff && productDiff.status == 'M' && productDiff.diffPaths.length > 0) {
      if ((productDiff.footnotesStatus && productDiff.footnotesStatus != null) || (productDiff.rnGenerationStatus && productDiff.rnGenerationStatus != null)) {
        this.productLevelArrayModifications(product, productDiff.footnotesStatus, 'footnotesStatus','footnotes');
        this.productLevelArrayModifications(product, productDiff.rnGenerationStatus, 'rnGenerationStatus','rnGenerations');
      }
      let flattenedProduct: any;
      flattenedProduct = this.prependSlashtoKeys(flatten(product.data, { delimiter: '/' }), '/');
      for (let key in flattenedProduct) {
        let givenPath = typeof key === 'string' ? key.split('/') : key;
        if (givenPath && givenPath.length <= 2) {
          for (let index = 0; index < productDiff.diffPaths.length; index++) {
            let diff = productDiff.diffPaths[index];
            if (key === diff['path'] && key != '/footnotes') {
              if (diff['status'] === 'M') {
                this.valueLevelChanges(product.data, givenPath[1], diff['value']);
              } else {
                product.data[givenPath[1]] = product.data[givenPath[1]] + STATUS_SEPARATOR + diff['status'];
              }
            }
          }
        } else if(givenPath && givenPath.length > 2 && givenPath[1] == 'rnComponents') {
          for (let index = 0; index < productDiff.diffPaths.length; index++) {
            let diff = productDiff.diffPaths[index];
            if (key === diff['path']) {
            // if (diff['status'] === 'M') {
              if(product.data['rnComponents'][givenPath[2]] && typeof product.data['rnComponents'][givenPath[2]] != 'object'){
                let valueArray = (product.data['rnComponents'][givenPath[2]].split("|"));
                let currentValue = valueArray[0]? valueArray[0].trim(): '';
                let previousValue = valueArray[1]? valueArray[1].trim(): '';
                if (!this.checkForModifyExceptionCases(currentValue) && this.checkForModifyExceptionCases(previousValue)) {
                  product.data['rnComponents'][givenPath[2]] = product.data['rnComponents'][givenPath[2]] + STATUS_SEPARATOR + 'A';
                } else if (this.checkForModifyExceptionCases(currentValue) && !this.checkForModifyExceptionCases(previousValue)) {
                  product.data['rnComponents'][givenPath[2]] = product.data['rnComponents'][givenPath[2]] + STATUS_SEPARATOR + 'D';
                }else if (!this.checkForModifyExceptionCases(currentValue) && !this.checkForModifyExceptionCases(previousValue)) {
                  product.data['rnComponents'][givenPath[2]] = product.data['rnComponents'][givenPath[2]] + STATUS_SEPARATOR + 'M';
                }
              } else if(product.data['rnComponents'][givenPath[2]] && givenPath[2] === 'nics') {
                product.data['rnComponents'][givenPath[2]]['status'] = productDiff.nicStatus;
              }else {
                  product.data['rnComponents'][givenPath[2]]['status'] = diff['status'];
                }
                /*handle null and zero for Add and Delete */
              // }
            }
          }
        }
      }
    } else {
      product['prodChanges'] = "No Changes";
    }
  }

  groupNICData(nicsData) {
    let last_group_id;
    let grouped_nics: Array<any> = [];
    for (let key in nicsData) {
      if(key != 'status') {
        last_group_id = nicsData[key]['groupId'];
        if(grouped_nics[last_group_id] === undefined) {
          grouped_nics[last_group_id] = [];
        }
        grouped_nics[last_group_id].push(nicsData[key]);
      }
    }
    return grouped_nics;
  }

  valueLevelChanges(product, label, value) {
    /* valueArray[0] current value, valueArray[1] previous value */
    let valueArray = (value.split("|"));
    let currentValue = valueArray[0].trim();
    let previousValue = valueArray[1].trim();
    if (!this.checkForModifyExceptionCases(currentValue) && this.checkForModifyExceptionCases(previousValue)) {
      product[label] = currentValue + STATUS_SEPARATOR + 'A';
    } else if (this.checkForModifyExceptionCases(currentValue) && !this.checkForModifyExceptionCases(previousValue)) {
      product[label] = previousValue + STATUS_SEPARATOR + 'D';
    } else if (!this.checkForModifyExceptionCases(currentValue) && !this.checkForModifyExceptionCases(previousValue)) {
      product[label] = currentValue + STATUS_SEPARATOR + 'M';
    }
  }
  /*checks for null, '', NA, 0 */
  checkForModifyExceptionCases(valueToCompare: any): boolean {
    let exceptions = [null, "", 'NA', 'N/A', '0', undefined, "null", "undefined"];
    if (typeof valueToCompare == 'string' && valueToCompare.length == 0) {
      return true;
    }
    return exceptions.includes(valueToCompare);
  }
  prependSlashtoKeys(auditData: any, stringToAppend) {
    if (Array.isArray(auditData)) {
      auditData.map(ele => {
        for (let prop in ele) {
          ele[stringToAppend + prop] = ele[prop];
          delete ele[prop];
        }
      });
    } else {
      for (const key in auditData) {
        if (auditData.hasOwnProperty(key)) {
          auditData[stringToAppend + key] = auditData[key];
          delete auditData[key]
        }
      }
      return auditData;
    }
  }
  /* Using Map to  designObjectForSupportedReleases*/
  designObjectForSupportedReleases(groupedAuditedProducts: any[]) {
    this.supportedProductsPeriodMap = new Map<number, any>();
    groupedAuditedProducts.forEach((period, periodIndex) => {
      let supportedProductsMap: Map<number, any> = new Map<number, any>();
      for (let key in period.auditRecord) {
        let supportedReleasesMap: Map<number, any>;
        supportedReleasesMap = new Map<number, any>();
        for (let index = 0; index < period.auditRecord[key].length; index++) {
          let element = period.auditRecord[key][index]['data']['supportedReleases'];
          element.forEach(release => {
            let sanitiedCertId;
            if (typeof release.certDetailId == 'string') {
              sanitiedCertId = release.certDetailId ? release.certDetailId.split("||")[0] : '';
              // console.log(sanitiedCertId, "sanitiedCertId");
              release.certDetailId = Number(sanitiedCertId);
            }
            // console.log(release, "release after certid");
            if (supportedReleasesMap.has(release.certDetailId)) {
              release['createdDateTs'] = period.auditRecord[key][index]['createdDateTs'];
              supportedReleasesMap.get(release.certDetailId).push(release);
            } else {
              let listOfReleases = [];
              release['createdDateTs'] = period.auditRecord[key][index]['createdDateTs'];
              listOfReleases.push(release);
              supportedReleasesMap.set(release.certDetailId, listOfReleases);
            }
          });
        }
        supportedProductsMap.set(Number(key), supportedReleasesMap);
      }
      this.supportedProductsPeriodMap.set(periodIndex, supportedProductsMap);
    })
    //using for displaying releases
    this.supportedProductsPeriodObj = toObject(this.supportedProductsPeriodMap);
    this.sortCertsBasedOnLatestRelease(groupedAuditedProducts, this.supportedProductsPeriodObj);
  }

  getReleasesSupported(product: any) {
    let supportedReleases: string = '';
    product.data.supportedReleases.forEach((release) => {
      if(supportedReleases && release.releaseVersion) {
        supportedReleases = supportedReleases + ', '+ release.releaseVersion;
      } else {
        supportedReleases = release.releaseVersion;
      }
    });
    return supportedReleases;
  }
  sortCertsBasedOnLatestRelease(groupedAuditedProducts, supportedProductsPeriodObj) {
    groupedAuditedProducts.forEach((period, periodIndex) => {
      //productId: productObj
      for (let key in period.auditRecord) {
        //each [product]
        let element = period.auditRecord[key][0]['data']['supportedReleases'];
        let orderedCertsOfProduct: Array<any> = [];
        element.forEach(release => {
          orderedCertsOfProduct.push(release['certDetailId']);
        })
        let sortedCertsObjOfproduct = {};
        if (supportedProductsPeriodObj[periodIndex] && supportedProductsPeriodObj[periodIndex][key]) {
          orderedCertsOfProduct.forEach(cert => {
            sortedCertsObjOfproduct[cert + " "] = supportedProductsPeriodObj[periodIndex][key][cert]
          })
          supportedProductsPeriodObj[periodIndex][key] = sortedCertsObjOfproduct;
        }
      }
    })
  }
  /* preserves order of obj props when applied keyvalue pipe */
  originalOrder = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => {
    const a = akv.value.index;
    const b = bkv.value.index;
    return a > b ? 1 : (b > a ? -1 : 0);
  }

  checkForDataEmptynies(value) {
    if (this.checkForModifyExceptionCases(value)) {
      return 'N/A'
    } else {
      return value
    }
  }
  numOfCertsChanged(index, certKey) {
    // console.log(this.supportedProductsPeriodObj[index][certKey]);
    for (let key in this.supportedProductsPeriodObj[index][certKey]) {
      // console.log(this.supportedProductsPeriodObj[index][certKey][key]['status'], "status if any")
    }
    return `5 certs modified. 2 certs created`
  }
  productChanged(auditedProducts, pageNumReq = 1) {
    let productsChanged: number = 0;
    if (auditedProducts.auditRecord && Object.keys(auditedProducts.auditRecord).length > 0) {
      for (let key in auditedProducts.auditRecord) {
        if (auditedProducts.auditRecord[key][0]['status'] && auditedProducts.auditRecord[key][0]['status'] != 'C') {
          productsChanged += 1;
        }
      }
      // return `${productsChanged} Product(s) changed`
      return `${productsChanged} Product(s) changed out of ${(pageNumReq * this.payload.size) - this.payload.size + 1} - ${(pageNumReq * this.payload.size)} product(s) in Page ${pageNumReq}`
    } else {
      return 'Product(s) revisions are not available for the period'
    }
  }
  certsChanged(cert){
    if(cert && Object.keys(cert).length > 0){
      for(let key in cert){
        if(cert[key] && cert[key][0] && cert[key][0]['status'] && cert[key][0]['status'] != 'C'){
          return 'M';
        }
      } 
    }
  }
  openRevCompModal(productId, productName, partnerName, vcgLink, frmDate, toDate, productType) {
    let productDetails = {
      productName: productName,
      partnerName: partnerName,
      vcgLink: vcgLink,
      productId: productId
    };
    // console.log(productType, 'productType')
    let fromDate = this.datePipe.transform(frmDate, 'y-MM-dd');
    let tDate = this.datePipe.transform(toDate, 'y-MM-dd')
    this.resultsService.getReleasesForComp(fromDate, tDate, productId).subscribe(releasesData => {
      this.productDetails = productDetails;
      this.releasesForComp = releasesData;
      if(this.releasesForComp['releases'] && this.releasesForComp['releases'] .length > 0){
        this.releasesForComp['releases'][this.releasesForComp['releases'] .length -1]["base"] = "Baseline for comparison";
        this.releasesForComp['releases'] [this.releasesForComp['releases'] .length -1]['baseStatus'] = "C";
      }
      this.openRevComparisonModal = true;
    }, (error) => {
      if (error.message) {
        error.message = `Failed to fetch Release Comparison Data`;
      }
      this.errorHandler.sendMessage(error);
    })
  }
  clsRevModal(event){
    this.openRevComparisonModal = event;
  }

  subscribeToProduct(productId, productName, partnerName) {
    if(this.authenticationService.isActiveUser()) {
      this.subscribeToProductWithId(productId);
    } else {
      this.openSubModal(productId, productName, partnerName);
    }
  }

  subscribeToProductWithId(productId) {
    this.subscribing = true;
    this.openAlreadySubscribedModal = false;
    this.openSubscribedModal = false;
    this.openSubscribedModal = false;
    let subscriptionData = new SubscriptionPayload();
    subscriptionData.productIds = [productId];
    this.subscriptionService.secureSubscribeToProduct(subscriptionData).subscribe(response => {
      if(response[0].status == "success") {
        if(response[0].message.includes('already')) {
					this.openAlreadySubscribedModal = true;
				} else {
					this.openSubscribedModal = true;
				} 
      } else {
        this.openSubscribedErrorModal = true;
      }
      this.subscribing = false;
    }, error => {
      this.openSubscribedErrorModal = true;
      this.subscribing = false;
    });
  }

  openSubModal(productId, productName, partnerName) {
    let productDetails = {
      productName: productName,
      partnerName: partnerName,
      vcgLink: null,
      productId: productId
    }
    this.productDetails = productDetails;
    this.openSubscriptionModal = true;
  }

  closeSubModal(event) {
    this.openSubscriptionModal = false;
  }

  getCompTypeBasedOnCompId(rnComp, rnCompId, productType) {
    if(productType && productType== 'All Flash' && rnComp == 'capacityTier'){
      return "cachingTier";
    }
    return rnComp;
  }

  getNICLinkText(compType, compId) {
    if(compId.toLowerCase() === 'n/a' || compId.toLowerCase() === 'na'  || compId === null) {
      return "";
    }
    return `VCG_BASE_LINK/detail.php?deviceCategory=${compType}&productid=${compId}`;
  }

  checkIfObj(rnCompValue){
    if(typeof rnCompValue !== 'string'){
      return true;
    } else {
      return false;
    }
  }

  checkIfMultiObj(rnCompValue){
    if(typeof rnCompValue !== 'string'){
      if(rnCompValue[0] !== undefined) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }
}

//util to group array items based on key/value
export const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

//util to convert map to obj
export const toObject = (map = new Map) => {
  let lo = {};
  Array.from
    (map.entries()
      , ([k, v]) =>
        v instanceof Map
          ? lo[k] = toObject(v)
          : lo[k] = v
    );
  return lo
}