import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, Subject } from 'rxjs';
import { EnvironmentsService } from 'src/app/services/environment.service';
import { UniquePrograms } from 'src/app/enums/unique-programs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor(private http: HttpClient, private envService: EnvironmentsService) { }

  getPartners(programSelected: string): Observable<string[]> {
    return this.http.get<string[]>(this.envService.config.apiEndpoint + `product/program/${programSelected}/partnernames`);
  }
  getRNModels(programSelected: string): Observable<string[]> {
    return this.http.get<string[]>(this.envService.config.apiEndpoint + `product/program/${programSelected}/models`);
  }
  getPartnersAndModels(programSelected: string): Observable<any[]> {
    return forkJoin([this.getPartners(programSelected), this.getRNModels(programSelected)]);
  }
  getPartnersAndModelsMap(programSelected: string, isVSAN2?: boolean): Observable<any> {
    let url: string = `${this.envService.config.apiEndpoint}product/program/${programSelected}/allmodelsforpartners`;
    if(isVSAN2 && Object.values(UniquePrograms).includes(programSelected.toLowerCase())) {
      url = `${this.envService.config.apiEndpoint}product/program/${programSelected}/allmodelsforpartners?isVSAN2=${isVSAN2}`;
    }
    return this.http.get(url);
  }
}
